<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item">
        <!-- <a v-if="this.Admin === true || this.Coordinacion === true" class="nav-link" href="/">
              <i class="ti-shield menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </a> -->
        <a class="nav-link" href="/">
          <!-- <i class="ti-shield menu-icon"></i> -->
          <!-- <i class="ti-home menu-icon"></i> -->
          <i class="fa-solid fa-house menu-icon"></i>
          <!-- <i class="fa-solid fa-house"></i> -->
          <span class="menu-title notranslate">Home</span>
        </a>
      </li>
      <li class="nav-item">
        <!-- <a v-if="this.Admin === true || this.Coordinacion === true" class="nav-link" href="/dashboard">
              <i class="ti-shield menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </a> -->
        <a class="nav-link" href="/dashboard">
          <i class="fa-solid fa-chart-line menu-icon"></i>
          <span class="menu-title notranslate">Dashboard &nbsp;</span>
        </a>
      </li>
      <li v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true" class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic-2" aria-expanded="false"
          aria-controls="ui-basic-2">
          <!-- <i class="ti-agenda menu-icon"></i> -->
          <i class="fa-solid fa-address-book menu-icon"></i>
          <span class="menu-title">Beneficiarios</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="ui-basic-2">
          <ul class="nav flex-column sub-menu">
            <li v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true" class="nav-item">
              <router-link class="nav-link" to="/solicitante/nuevo">Crear Beneficiarios</router-link>
            </li>
            <li v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true" class="nav-item">
              <router-link class="nav-link" to="/mostrar-solicitantes">Beneficiarios</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li
        v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true || this.coordinadorSucursal === true || this.Finanzas === true" 
        class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
          <!-- <i class="ti-marker-alt menu-icon"></i> -->
          <i class="fa-solid fa-pencil menu-icon"></i>
          <!-- <i class="fa-solid fa-pen-clip menu-icon"></i> -->
          <span class="menu-title">Solicitudes</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="ui-basic">
          <ul class="nav flex-column sub-menu">
            <li
              v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true || this.coordinadorSucursal === true"
              class="nav-item"> <router-link class="nav-link" to="/solicitud">Nueva Solicitud</router-link></li>
            <!-- <li
              v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true || this.coordinadorSucursal === true"
              class="nav-item"> <router-link class="nav-link" to="/grupos-solidarios">Grupos Solidarios</router-link>
            </li> -->
            <li
              v-if="this.Admin === true || this.Coordinacion === true || this.Asesor === true || this.coordinadorSucursal === true"
              class="nav-item"> <router-link class="nav-link" to="/mostrar-solicitudes">Solicitudes</router-link></li>
            <li v-if="this.Admin === true || this.Finanzas === true" class="nav-item"> <router-link class="nav-link"
                to="/mostrar-solicitudes-aceptadas">Solicitudes Aceptadas</router-link></li>
            <!-- <li class="nav-item"> <router-link class="nav-link" to="/mostrar-solicitantes">Beficiarios</router-link></li> -->
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/cartera">
          <!-- <i class="ti-wallet menu-icon"></i> -->
          <i class="fa-solid fa-wallet menu-icon"></i>
          <span class="menu-title">Cartera</span>
        </router-link>
      </li>
      <li v-if="this.Admin || this.Finanzas || this.Consultor" class="nav-item">
        <router-link class="nav-link" to="pagos">
          <!-- <i class="ti-credit-card menu-icon"></i> -->
          <i class="fa-regular fa-credit-card menu-icon"></i>
          <span class="menu-title">Pagos</span>
        </router-link>
      </li>
      <li v-if="this.Admin === true" class="nav-item">
        <router-link class="nav-link" to="/ahdecoAdminSettings">
          <!-- <i class="ti-user menu-icon"></i> -->
          <i class="fa-solid fa-users-gear menu-icon"></i>
          <span class="menu-title">Gestión de Usuarios</span>
        </router-link>
      </li>
      
      <li v-if="this.Admin === true" class="nav-item">
        <router-link class="nav-link" to="registro-actividades">
          <!-- <i class="ti-pie-chart menu-icon"></i> -->
          <i class="fa-solid fa-gauge-high menu-icon"></i>
          <span class="menu-title">Registro de Actividades</span>
        </router-link>
      </li>
      <!-- 
          <li class="nav-item">
            <a class="nav-link" href="pages/icons/themify.html">
              <i class="ti-settings menu-icon"></i>
              <span class="menu-title">Icons</span>
            </a>
          </li> -->
    </ul>
  </nav>
</template>

<script>
import Security from './security';



export default {
  name: 'Navbar-Lateral',
  data() {
    return {
      roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Coordinador Sucursal', 'Consultor'],
      Finanzas: false,
      coordinadorSucursal: false,
      Admin: false,
      Coordinacion: false,
      Asesor: false,
      Consultor: false,
      userRoles: []
    }
  },
  mounted() {
    this.userRoles = Security.getUserRoles();
    this.roles.forEach(element => {
      if (this.userRoles[0]?.some(role => role.includes(element)) === true) {
        this[element] = true;
      } else if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
        this.coordinadorSucursal = true;
      }
    });
  },
}

</script>

<style>
.fa-solid {
  /* color: #8c8c8c !important; */
}
</style>