import {store} from './store.js'
import router from './../router/index.js'
import Swal from 'sweetalert2';


let Security = {



    //make sure user is athenticated
    requireToken: function(){
        if (store.token === '') {
            router.push("/");
            return false
        }
    },

    //create request options and send them back
    requestOptions: function(payload){
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + store.token);

        return {
            method: "POST",
            body: JSON.stringify(payload),
            headers: headers,
        }
    },

    // requestOptionsImagen: function(formData){
    //     const headers = new Headers();
    //     headers.append("Content-Type", "multipart/form-data");
    //     headers.append("Authorization", "Bearer " + store.token);

    //     return {
    //         method: "POST",
    //         body: formData,
    //         headers: headers,
    //     }
    // },
    
    requestOptionsImagen: function(formData){
        const headers = new Headers();
        // Generar un valor único para el parámetro boundary
        headers.append("Authorization", "Bearer " + store.token);
    
        return {
            method: "POST",
            body: formData,
            headers: headers,
        }
    },

    

    //check token
    checkToken: function(){
        if(store.token !== ""){
            const payload = {
                token: store.token,
            }

            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            let requestOptions = {
                method: "POST",
                body: JSON.stringify(payload),
                headers: headers,
            }

            fetch(process.env.VUE_APP_API_URL+"/validate-token", requestOptions)
            .then((response) => response.json())
            .then((data)=>{
                if(data.error){
                    console.log(data.error);
                }else{
                    if(!data.data){
                        store.token = "";
                        store.user = {},
                        // document.cookie = '_site_data=; Path=/; '
                        //     + 'SameSite=strict; Secure; '
                        //     + 'Expires=Thu, 01 Jan 1970 00:00:01 GMT'

                        document.cookie = "_site_data" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                        // Redirigir a la dirección raíz (página de inicio)
                        Swal.fire({
                            // position: 'top-end',
                            icon: "error",
                            title: 'La Sesión ha expirado',
                            showConfirmButton: false,
                            timer: 1500,
                          });
                                                

                        
                          setTimeout(() => {
                            // Lógica que se ejecutará después de 3 segundos
                            location.reload();
                            // alert('La Sesión ha expirado');
                            window.location.href = "/";
                        }, 1500);
                        
                        

                    }
                }
            })
        }
    },

    checkTokenValid: function(){
        if(store.token !== ""){
            const payload = {
                token: store.token,
            }

            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            let requestOptions = {
                method: "POST",
                body: JSON.stringify(payload),
                headers: headers,
            }

            fetch(process.env.VUE_APP_API_URL+"/validate-token", requestOptions)
            .then((response) => response.json())
            .then((data)=>{
                if(data.error){
                    console.log(data.error);
                }else{
                    if(!data.data){
                        store.token = "";
                        store.user = {},
                        document.cookie = '_site_data=; Path=/; '
                            + 'SameSite=strict; Secure; '
                            + 'Expires=Thu, 01 Jan 1970 00:00:01 GMT'
                            document.cookie = 'roles=; Path=/; '
                            + 'SameSite=strict; Secure; '
                            + 'Expires=Thu, 01 Jan 1970 00:00:01 GMT'
                        return false;
                    }else return true;
                }
            })

            return false;
        }
        return false;
    },

    secureReload: function(){
        window.onbeforeunload = function() {
            return "Data will be lost if you leave the page, are you sure?";
          };
    },
    getUser: function(){
        const user = document.cookie
            .split(';')
            .map(cookie => cookie.trim())
            .filter(cookie => cookie.startsWith('_site_data='))
            .map(cookie => cookie.substring('_site_data='.length))
            .map(cookie => decodeURIComponent(cookie))
            .map(cookie => JSON.parse(cookie));
        return user;
    },
    getUserSucursal: function(){
        const sucursal = document.cookie
            .split(';')
            .map(cookie => cookie.trim())
            .filter(cookie => cookie.startsWith('sucursal='))
            .map(cookie => cookie.substring('sucursal='.length))
            .map(cookie => decodeURIComponent(cookie))
            .map(cookie => JSON.parse(cookie));
        return sucursal;
    },
    getUserRoles: function(){
        const roles = document.cookie
            .split(';')
            .map(cookie => cookie.trim())
            .filter(cookie => cookie.startsWith('roles='))
            .map(cookie => cookie.substring('roles='.length))
            .map(cookie => decodeURIComponent(cookie))
            .map(cookie => JSON.parse(cookie));
        return roles;
    }
    
    
}

export default Security;