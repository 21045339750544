<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <form-tag-beneficiarios @ClienteEditEvent="submitHandler" name="cliente" event="clienteEditEvent">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-3">
                    <button type="button" class="btn btn-dark" @click="goBack">Ir a la página anterior</button>
                  </div>
                  <div class="col-6 text-center">
                    <h4 style="font-size: 20px" class="ms-2 mt-3 mb-4">
                      Nuevo Solicitante de Crédito
                    </h4>
                  </div>

                  <div v-if="this.idCliente > 0" class="col-3 d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary">
                      Actualizar
                    </button>
                  </div>
                  <div v-else class="col-3 d-flex justify-content-end">
                    <input v-if="solicitudCompleta.producto != `MIPYME` && !this.entraNavbar" type="submit"
                      class="btn btn-dark" value="Agregar a Grupo solidario" />
                    <input v-else-if="!this.entraNavbar" type="submit" class="btn btn-dark" value="Agregar a Solicitud" />

                  </div>

                </div>

                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne">
                        <div class="col-6">Datos Personales del Solicitante</div>
                        <div id="validar-1" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Nombres</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <input v-model="cliente.nombres"  type="text" required="true" class="form-control" value="cliente.nombres"
                                  name="nombres" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Apellidos</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.apellidos" type="text" id="apellido" :required="true"
                                  :value="cliente.apellidos" name="apellidos"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">DNI</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <input :required="true" id="dni" v-model="dni" @input="formatInput" minlength="15"
                                  maxlength="15" placeholder="Ejemplo: 0801-1998-01234" class="form-control mt-2 mb-2"
                                  name="dni" />
                              </div>
                              <div v-if="this.mensajeDni" class="row d-flex justify-content-center align-items-center">
                                <div class="col-4"></div>
                                <div class="col-8 alert alert-danger text-center" role="alert">
                                  {{ this.mensajeDni }} 😲
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">RTN</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <!-- <input id="rtn" v-model="cliente.rtn"  minlength="16"
                                  maxlength="16" placeholder="Ejemplo: 08011998012346" class="form-control mt-2 mb-2"
                                  name="rtn" /> -->

                                  <text-input v-model="cliente.rtn" required="false" type="text"
                                  :value="cliente.rtn" name="rtn"
                                  id="rtn"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Lugar de Nacimiento</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.lugarNacimiento" required="false" type="text"
                                  :value="cliente.lugarNacimiento" name="lugar-nacimiento"
                                  id="lugarNacimiento"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Sexo</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select v-model="cliente.sexo" name="sexo" id="sexo" class="form-select"
                                  aria-label="Default select example">
                                  <option value="masculino">Masculino</option>
                                  <option value="femenino">Femenino</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div v-if="this.cliente.edad" class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Edad: {{ this.cliente.edad }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Estado Civil</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select id="estadoCivil" name="estado-civil" v-model="cliente.estadoCivil" 
                                  class="form-select" aria-label="Default select example">
                                  <option value="soltero">Soltero(a)</option>
                                  <option value="casado">Casado(a)</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Fecha de Nacimiento</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <input v-model="cliente.fechaNacimiento" type="date" class="form-control"
                                  placeholder="dd/mm/yyyy" name="fecha-nacimiento" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Correo Electronico</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.email" id="correo" type="text" required="false"
                                  :value="cliente.email" name="email"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Celular</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.celular" id="celular" type="text" required="false"
                                  :value="cliente.celular" name="celular"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Nombre del Banco</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select class="form-select" v-model="cliente.nombreBanco" id="nombre-banco" type="text"
                                  :value="cliente.nombreBanco" name="nombre-banco">
                                  <option value="Banco Atlantica">Banco Atlantida</option>
                                  <option value="BAC">BAC</option>
                                  <option value="Banco Ficohsa">Banco Ficohsa</option>
                                  <option value="Banco de Occidente">Banco de Occidente</option>
                                  <option value="Banco Davivienda">Banco Davivienda</option>
                                  <option value="Banco Promerica">Banco Promerica</option>
                                  <option value="Banco Lafise">Banco Lafise</option>
                                  <option value="Banco de los Trabajadores">Banco de los Trabajadores</option>
                                  <option value="Banco Azteca">Banco Azteca</option>
                                  <option value="Banco BANHCAFE">Banco BANHCAFE</option>
                                  <option value="Banco FICENSA">Banco FICENSA</option>
                                  <option value="Banco de Desarrollo Rural Honduras">Banco de Desarrollo Rural Honduras
                                  </option>
                                  <option value="Banco Popular"> Banco Popular</option>

                                  <option value="Otro">Otro</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Número de Cuenta del Banco</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.noCuentaBanco" id="no-cuenta-cuenta" type="text"
                                  required="false" :value="cliente.noCuentaBanco" name="no-cuenta-banco"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo">
                        <div class="col-6">Datos Domiciliares del Solicitante</div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Departamento</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <!--  <text-input v-model="cliente.departamento" type="text" :value="cliente.departamento"
                                  name="departamento"></text-input> -->

                                <select v-model="cliente.departamento" id="departamento" name="departamento"
                                  class="form-select">
                                  <option v-for="departamentos in departamentos" :key="departamentos.idDepartamento"
                                    :value="departamentos?.nombre">
                                    {{ departamentos?.nombre }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Municipio</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select v-model="cliente.municipio" id="municipio" name="municipio" class="form-select"
                                  >
                                  <option v-for="municipios in municipios" :key="municipios.id"
                                    :value="municipios?.nombre">
                                    {{ municipios?.nombre }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Colonia/Barrio/Aldea</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.colonia" type="text" :value="cliente.colonia" name="colonia"
                                  required="false"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Calle</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.calle" required="false" type="text" :value="cliente.calle"
                                  name="calle"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Avenida</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.avenida" type="text" required="false" :value="cliente.avenida"
                                  name="avenida"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Bloque</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.bloque" type="text" required="false" :value="cliente.bloque"
                                  name="bloque"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Casa No.</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.noCasa" type="text" required="false" :value="cliente.noCasa"
                                  name="no-casa"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Punto de referencia</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input id="punto-referencia" v-model="cliente.puntoReferencia" type="text"
                                  required="false" :value="cliente.puntoReferencia" name="punto-referencia"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Tipo de vivienda</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select v-model="cliente.tipoVivienda" name="tipoVivienda" class="form-select"
                                   aria-label="Default select example">
                                  <option value="propia">Propia</option>
                                  <option value="alquilado">Alquilado</option>
                                  <option value="familiar">Familiar</option>
                                  <option value="pagandola">Pagándola</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label" for="referencia-geografica">Referencia
                                Geografica</label>
                              <div class="col-sm-9 d-flex align-items-center ref-content">
                                <div>
                                  <!-- data-bs-toggle="modal" data-bs-target="#refGeograficaModal" -->

                                  <input class="refStatus form-control" id="refStatus" required="false" type="text" name="refStatus" v-model="refStatus">
                                  <!-- {{ this.geoMaps.coordenates ?  "Referenciación Guardada": "No hay referenciación aún" }} -->



                                  <button type="button" @onClick="() => verMasRefGeo()" id="ver-mas-ref-geo"
                                    class="ver_mas_ref_geo btn btn-primary" value="Ver mas"> Ver mas </button>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div v-if="this.cliente.tipoVivienda == `alquilado`" class="row">
                          <div v-if="this.cliente.tipoVivienda == `alquilado` ||
                            this.cliente.tipoVivienda == `pagandola`
                            " class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Cuota de Vivienda</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input require="true" v-model="cliente.cuotaVivienda" type="text" required="false"
                                  :value="cliente.cuotaVivienda" name="cuota-vivienda"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Si alquila: Nombre y teléfono del
                                propietario</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.propietarioAlquila" type="text" required="false"
                                  :value="cliente.propietarioAlquila" name="propietario-alquila"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" required="false"
                        aria-controls="panelsStayOpen-collapseThree">
                        <div class="col-6">Datos Personales del Negocio del Solicitante</div>
                        <div id="validar-3" class="d-flex justify-content-end me-3" style="width: 100%"></div>

                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Destino</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select v-model="cliente.destino" name="destino" class="form-select" 
                                  aria-label="Default select example">
                                  <option value="produccion">Producción</option>
                                  <option value="comercio">Comercio</option>
                                  <option value="servicios">Servicios</option>
                                  <option value="turismo">Turismo</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Tipo Cliente</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select v-model="cliente.tipoCliente" name="tipo-cliente" class="form-select"
                                   aria-label="Default select example">
                                  <option value="emprendedor">
                                    Emprendedor
                                  </option>
                                  <option value="empresario">Empresario</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Actividad Económica</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.actividadEconomica" type="text" required="false"
                                  :value="cliente.actividadEconomica" name="actividad-economica"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Tiempo de tener negocio</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.tiempoNegocio" type="text" required="false"
                                  :value="cliente.tiempoNegocio" name="tiempo-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Tipo de local</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <select v-model="cliente.tipoLocal" name="tipoLocal" class="form-select" 
                                  aria-label="Default select example">
                                  <option value="propio">Propio</option>
                                  <option value="alquilado">Alquilado</option>
                                  <option value="otro">Otro</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div v-if="this.cliente.tipoLocal == `alquilado`" class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Cuota Alquiler Económica</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.cuotaAlquilerNegocio" type="number" required="false"
                                  :value="cliente.cuotaAlquilerNegocio" name="cuotaAlquiler"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Ingreso Bruto Negocio</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.ingresoBrutoNegocio" type="text" required="false"
                                  :value="cliente.ingresoBrutoNegocio" name="ingresoBrutoNegocio"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Teléfono</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.telefono" type="text" required="false"
                                  :value="cliente.telefono" name="telefono"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Colonia/Barrio/Aldea</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.coloniaNegocio" required="false" type="text"
                                  :value="cliente.coloniaNegocio" name="colonia-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Calle</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.calleNegocio" required="false" type="text"
                                  :value="cliente.calleNegocio" name="calle-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Avenida</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.avenidaNegocio" required="false" type="text"
                                  :value="cliente.avenidaNegocio" name="avenida-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Bloque</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.bloqueNegocio" required="false" type="text"
                                  :value="cliente.bloqueNegocio" name="bloque-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Casa No.</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.noCasaNegocio" required="false" type="text"
                                  :value="cliente.noCasaNegocio" name="no-casa-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Punto de referencia</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.puntoReferenciaNegocio" type="text"
                                  :value="cliente.puntoReferenciaNegocio" required="false"
                                  name="punto-referencia-negocio"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="this.cliente.estadoCivil == `casado`" class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingfour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFour">
                        Datos Conyugue
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingfour">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Nombres</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.nombresConyugue" type="text" required="false"
                                  :value="cliente.nombresConyugue" name="nombres-conyugue"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Apellidos</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.apellidosConyugue" required="false" type="text"
                                  :value="cliente.apellidosConyugue" name="apellidos-conyugue"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">DNI</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input :required="false" v-model="cliente.dniConyugue" type="text"
                                  :value="cliente.dniConyugue" name="dni-conyugue"></text-input>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Email</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.emailConyugue" type="text" required="false"
                                  :value="cliente.emailConyugue" name="email-conyugue"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Celular</label>
                              <div class="col-sm-9 d-flex align-items-center">
                                <text-input v-model="cliente.celularConyugue" required="false" type="text"
                                  :value="cliente.celularConyugue" name="celular-conyugue"></text-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal fade" id="refGeograficaModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Referencia</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                          <div>

                            <GeolocalizacionComponent />
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                          <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="submit" v-if="this.entraNavbar" class="btn btn-primary mt-3" value="Enviar" />
                </div>
              </form-tag-beneficiarios>
            </div>

            <div class="ownModal">

              <div class="ownModalContent">
                <GeolocalizacionComponent />
              </div>
              <div class="ownModalBack"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- content-wrapper ends -->
    <!-- partial:../../partials/_footer.html -->
    <footer class="footer">
      <div class="d-sm-flex justify-content-center justify-content-sm-between">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright ©
          AHDECO 2023</span>

      </div>
    </footer>
    <!-- partial -->
  </div>
</template>

<script>
import Security from "../security";
import TextInput from "@/components/forms/Text-Input.vue";
import { solicitudCompleta } from '../solicitud';
import router from '@/router';
import FormTagBeneficiarios from '../forms/FormTagBeneficiarios.vue'
import direccionData from './direccionData.json';
import GeolocalizacionComponent from './GeolocalizacionComponent.vue';
import { geoMaps } from "../geoMaps";


export default {
  name: "NuevoSolicitante",
  data() {
    return {
      refStatus: '',
      geoMaps,
      departamento: {},
      direccionData: [],
      municipiosData: [],
      selectedDepartamento: '',
      solicitudCompleta,
      entraNavbar: false,
      fechaNacimiento: "",
      idCliente: 0,
      noReferencias: 1,
      dni: "",
      rtn: "",
      mensajeDni: "",
      cliente: {
        id: 0,
        nombres: "",
        apellidos: "",
        lugarNacimiento: "",
        sexo: "",
        edad: "",
        estadoCivil: "",
        email: "",
        celular: "",
        nombreBanco: "",
        noCuentaBanco: "",
        fechaNacimiento: "",

        departamento: "",
        municipio: "",
        colonia: "",
        calle: "",
        avenida: "",
        bloque: "",
        noCasa: "",
        puntoReferencia: "",
        tipoVivienda: "",
        cuotaVivienda: "",
        propietarioAlquila: "",
        coordenadas: "",

        destino: "",
        tipoCliente: "",
        actividadEconomica: "",
        tiempoNegocio: "",
        tipoLocal: "",
        cuotaAlquilerNegocio: "",
        ingresoBrutoNegocio: "",
        telefono: "",
        coloniaNegocio: "",
        calleNegocio: "",
        avenidaNegocio: "",
        bloqueNegocio: "",
        noCasaNegocio: "",
        puntoReferenciaNegocio: "",

        nombresConyugue: "",
        apellidosConyugue: "",
        dniConyugue: "",
        emailConyugue: "",
        celularConyugue: "",

        estado: "",
      },
      departamentos: [],
      municipios: [],
      departamentoSeleccionado: null
    };
  },
  mounted() {
    document.querySelector(".ownModalBack").addEventListener("click", () => {
      const modal = document.querySelector(".ownModal");
      modal.classList.remove("ownModalActive");
    }),
      document.getElementById("ver-mas-ref-geo").addEventListener("click", () => {
        const modal = document.querySelector(".ownModal");
        modal.classList.add("ownModalActive");
      })
      document.getElementById("refStatus").disabled = true;

  },
  beforeMount() {
    Security.requireToken();
    this.departamentos = direccionData[0].departamentos
    this.idCliente = this.$route.params.solicitanteId
    // Obtener Cliente por edit is id > 0
    if (this.$route.params.solicitanteId > 0) {
      //editing a solicitante
      fetch(
        process.env.VUE_APP_API_URL +
        "/admin/clientes/" +
        this.$route.params.solicitanteId,
        Security.requestOptions("")
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit("error", data.message);
          } else {
            this.geoMaps.coordenates = data.data.referenciaGeografica
            this.cliente = data.data;
            this.dni = data.data.dni;
            this.cliente.estado = data.data.estado;
          }
        });
    }
    if (this.$route.params.solicitanteId === 'nuevo') {
      this.entraNavbar = true
    }
  },
  components: {
    "text-input": TextInput,
    FormTagBeneficiarios,
    GeolocalizacionComponent,
  },

  watch: {
    'geoMaps.coordenates': function () {
      const refStatus = document.getElementById("refStatus");
      if (this.geoMaps.coordenates) {
        refStatus.classList.add("refStatusActive");
        this.refStatus = "Referenciación Guardada"
        "No hay referenciación aún"
      } else {
        refStatus.classList.remove("refStatusActive");
        this.refStatus = "No hay referenciación aún"
      }
    },
    'cliente.fechaNacimiento': function (newVal) {
      // JSON.stringify(newVal);
      // Convierte la fecha de nacimiento en un objeto Date
      var fechaNacimiento = new Date(newVal);

      // Obtiene la fecha actual
      var fechaActual = new Date();

      // Calcula la diferencia en milisegundos entre las fechas
      var diferencia = fechaActual - fechaNacimiento;

      // Convierte la diferencia en milisegundos a años
      var edad = Math.floor(diferencia / (365.25 * 24 * 60 * 60 * 1000));

      this.cliente.edad = edad;
    },

    dni(dni) {
      // console.log(dni);
      
      if (
        Array.from(dni).length == 15 &&
        (this.$route.params.solicitanteId <= 0 || this.$route.params.solicitanteId == "nuevo")
      ) {
        let payload = dni?.replace(/-/g, "");

        // this.mensajeDni = dni
        fetch(
          `${process.env.VUE_APP_API_URL}/admin/clientes/existe`,
          Security.requestOptions(payload)
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              this.$emit("error", data.message);
            } else {
              this.$emit("success", "Solicitante Creado");
              // alert('Solicitante Creado');
              // router.push("/");
              dni = document.getElementById("dni");
              dni.classList.remove("is-invalid");
              if (data.message == "Existe") {
                // dni.classList.remove('was-validated')
                dni.classList.add("is-invalid");
                this.mensajeDni = "DNI Ya existe";
              } else {
                this.mensajeDni = "";
                dni = document.getElementById("dni");
                dni.classList.remove("is-invalid");
                dni.classList.add("was-validated");
              }
            }
          })
          .catch((error) => {
            this.$emit("error", error);
          });
      } else {
        this.mensajeDni = "";
        dni = document.getElementById("dni");
        dni.classList.remove("is-invalid");
        dni.classList.add("was-validated");
      }
    },
    'cliente.departamento': function (direccion) {
      const municipios = (this.departamentos.filter(departamento => departamento.nombre === direccion))[0]?.municipios
      this.municipios = JSON.parse(JSON.stringify(municipios ? municipios : ''))
    }
  },
  methods: {
    verMasRefGeo(e) {
      e.preventDefault();
      const modal = document.querySelector(".ownModal");
      modal.classList.add("ownModalActive");
    },
    coordenatesVerify() {
      const refStatus = document.getElementById("refStatus");
      if (this.geoMaps.coordenates) {
        refStatus.classList.add("refStatusActive");
        this.refStatus = "Referenciación Guardada"
        "No hay referenciación aún"
      } else {
        refStatus.classList.remove("refStatusActive");
        this.refStatus = "No hay referenciación aún"
      }
    },
    submitHandler() {
      this.coordenatesVerify()
      const refStatus = document.getElementById("refStatus");
      if (this.geoMaps.coordenates) {
        refStatus.classList.add("refStatusActive");
        this.refStatus = "Referenciación Guardada"
        "No hay referenciación aún"
      } else if (this.geoMaps.coordenates == "") {
        refStatus.classList.remove("refStatusActive");
        this.refStatus = "No hay referenciación aún"
        return
      }
      const payload = {
        id: this.cliente.id,
        nombres: this.cliente.nombres,
        apellidos: this.cliente.apellidos,
        dni: (this.dni).replace(/-/g, ""),
        rtn: this.cliente.rtn,
        lugarNacimiento: this.cliente.lugarNacimiento,
        sexo: this.cliente.sexo,
        edad: parseInt(this.cliente.edad, 10),
        // edad: this.cliente.edad,
        fechaNacimiento: this.cliente.fechaNacimiento,
        estadoCivil: this.cliente.estadoCivil,
        email: this.cliente.email,
        celular: this.cliente.celular,
        nombreBanco: this.cliente.nombreBanco,
        noCuentaBanco: this.cliente.noCuentaBanco,

        departamento: this.cliente.departamento,
        municipio: this.cliente.municipio,
        colonia: this.cliente.colonia,
        calle: this.cliente.calle,
        avenida: this.cliente.avenida,
        bloque: this.cliente.bloque,
        noCasa: this.cliente.noCasa,
        puntoReferencia: this.cliente.puntoReferencia,
        tipoVivienda: this.cliente.tipoVivienda,
        propietarioAlquila: this.cliente.propietarioAlquila,
        // cuotaVivienda: this.cliente.cuotaVivienda,
        cuotaVivienda: parseInt(this.cliente.cuotaVivienda, 10),
        referenciaGeografica: this.geoMaps.coordenates,
        
        destino: this.cliente.destino,
        tipoCliente: this.cliente.tipoCliente,
        actividadEconomica: this.cliente.actividadEconomica,
        tiempoNegocio: this.cliente.tiempoNegocio,
        tipoLocal: this.cliente.tipoLocal,
        // cuotaAlquilerNegocio: this.cliente.cuotaAlquilerNegocio,
        cuotaAlquilerNegocio: parseInt(this.cliente.cuotaAlquilerNegocio, 10),
        // ingresoBrutoNegocio: this.cliente.ingresoBrutoNegocio,
        ingresoBrutoNegocio: parseInt(this.cliente.ingresoBrutoNegocio, 10),
        telefono: this.cliente.telefono,
        coloniaNegocio: this.cliente.coloniaNegocio,
        calleNegocio: this.cliente.calleNegocio,
        avenidaNegocio: this.cliente.avenidaNegocio,
        bloqueNegocio: this.cliente.bloqueNegocio,
        noCasaNegocio: this.cliente.noCasaNegocio,
        puntoReferenciaNegocio: this.cliente.puntoReferenciaNegocio,

        nombresConyugue: this.cliente.nombresConyugue,
        apellidosConyugue: this.cliente.apellidosConyugue,
        dniConyugue: this.cliente.dniConyugue,
        emailConyugue: this.cliente.emailConyugue,
        celularConyugue: this.cliente.celularConyugue,

        idUsuario: Security.getUser()[0].user.id,
        estado: this.cliente.estado,
      };
      fetch(
        `${process.env.VUE_APP_API_URL}/admin/clientes/save`,
        Security.requestOptions(payload)
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit("error", data.message);
          } else {
            if (data.message == "Existe") {
              let identidad = document.getElementById("dni");

              identidad.classList.add("is-invalid");
              this.mensajeDni = "DNI Existente";
              alert("El solicitante ya existe");
            } else {
              payload.id = data.data.id
              this.solicitudCompleta.integrantes.push(payload);
              this.solicitudCompleta.dniIntegrantes.push(payload.id);

              if (solicitudCompleta.producto != `MIPYME`) {
                router.push("/solicitud/grupo-solidario/0")
              } else router.push("/solicitud/integrante/0")

            }
          }
        })
        .catch((error) => {
          this.$emit("error", error);
        });
      location.reload()
    },

    formatInput() {
      // Elimina todos los guiones existentes en el valor actual
      const cleanedValue = this.dni.replace(/-/g, "");

      // Aplica el formato con guiones
      let formattedValue = "";
      for (let i = 0; i < cleanedValue.length; i++) {
        if (i === 4 || i === 8) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      // Actualiza el valor del input
      this.dni = formattedValue;
    },
    goBack() {
      this.$router.go(-1); // Navegar a la página anterior
    },

  },

};
</script>

<style scoped>
.refStatus.refStatusActive {
  border: 2px solid #248D5D;
  color: #000;
  border-radius: 10px;
  margin: 0;
}
.refStatus.is-invalidRef{
  border: 2px solid #ff0000;
  color: #000;
  border-radius: 10px;
  margin: 0;
}
.ref-content div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  >input[name="referencia-geografica"] {
    width: 100%;
    padding: 0.875rem 1.375rem;
    font-size: 0.875rem;
    border-radius: 10px;
    border: 1px solid #c9ccd7;
    position: relative;
    padding-right: 110px;
  }
}

.refStatus {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 45px;
  padding: 0.875rem 1.375rem;
  font-size: 0.8rem;
  border-radius: 10px;
  border: 1px solid #c9ccd7;
  position: relative;
  padding-right: 110px;
}

.ver_mas_ref_geo {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4285f4;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 100px;
  height: 46px;
  z-index: 10;
}

.card {
  border-radius: 12px;
  /* border-left-color: #4285f4; */
  border-top: 5px solid #ff910088;
  border-left: 5px solid #4286f467;
}

.form-control {
  border-radius: 10px;
  height: 70%;
}

.form-control:focus {
  /* border-color: #ff9100; */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* Sombra */
}

.col-form-label {
  margin: 0px !important;
}

.form-group {
  margin-bottom: 0px;
}

.form-group {
  margin-block: 8px;
}

.col-form-label {
  padding-left: 10px;
  padding-right: 5px;
}

.card-description {
  margin-block: 15px;
}

.form-control {
  color: #4a4a4a !important;
}

.btnn {
  /* background-color: aqua; */
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.btnn p {
  margin-bottom: 0px;
  color: white;
}
</style>

<style>
.form-control {
  border-radius: 10px;
  height: 70%;
}

.carta {
  border-radius: 12px;
  /* border-left-color: #4285f4; */
  /* border-top: 5px solid #00ff0d88; */
  /* border-left: 5px solid #ffea0667; */
}

.fm {
  border-radius: 10px;
  height: 70%;
}

.fm:focus {
  /* border-color: #ff9100; */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* Sombra */
}

.pad {
  /* padding-left: 5px; */
  padding-inline: 5px !important;
}


.ownModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 10000;
  transform: translate(500%, 500%);

  >.ownModalContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(500%, 500%);
    background-color: white;
    width: 80%;
    height: 95%;
    border-radius: 10px;
    z-index: 10002;
    padding: 10px;

    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
    /* Sombra */
    >h1 {
      text-align: center;
    }
  }

  >.ownModalBack {
    transform: translate(500%, 500%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10001;
  }
}

.ownModal.ownModalActive {

  transform: translate(0%, 0%) scale(1);

  >.ownModalContent {
    transform: translate(-50%, -50%) scale(1);
  }

  >.ownModalBack {
    transform: translate(0%, 0%);
  }
}</style>
