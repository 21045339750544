<template>
    <div class="container p-5">
        <h3 class="mb-3">Pagos por rango de fecha </h3>
        <VueDatePicker v-model="date" :range="true" rangeSeparator=" to "></VueDatePicker>
        <!-- {{ this.date[0] }} -->


        <TablaPagos :key="`${date[0]}-${date[1]}`" :startDate="this.date[0]" :endDate="this.date[1]" />


        </div>

</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import TablaPagos from './Tabla-Pagos.vue';

export default {
    components: {
        VueDatePicker,
        TablaPagos
    },
    data() {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establece las horas, minutos, segundos y milisegundos en 0
        return {
            date: [today, today], // Inicializa la fecha de hoy como la primera fecha del rango
            ready: false,
            pagos: [],
        };

    },
    
}
</script>
