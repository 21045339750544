import { createRouter, createWebHistory} from 'vue-router'
import Home from '../components/Home-App.vue'
import Dashboard from '../components/Dashboard-App.vue'
import NuevoSolicitante from '../components/solicitud/Edit-Cliente.vue'
import EditSolicitud from '../components/solicitud/Edit-Solicitud.vue'
import EditSolicitudAceptada from '../components/solicitud/Edit-Solicitud-Aceptada.vue'
import NuevaSolicitud from '../components/solicitud/Crear-Solicitud.vue'
import Solicitantes from '../components/solicitud/Solicitantes-App.vue'
import GruposSolidario from '../components/solicitud/Creacion-Grupo-Solidario.vue'
import GruposSolidarios from '../components/solicitud/Mostrar-Grupos-Solidarios.vue'
import Solicitudes from '../components/solicitud/Mostrar-Solicitudes.vue'
import SolicitudesAceptadas from '../components/solicitud/Mostrar-Solicitudes-Aceptadas.vue'
import Cartera from '../components/cartera/Select-Cartera.vue'
import Pagos from '../components/pagos/Pagos-App.vue'
import ConfiguracionComponent from '../components/Configuracion/ConfiguracionComponent.vue'
import UserEdit from '../components/Configuracion/User-Edit.vue'
import RegistroActividades from '../components/Configuracion/RegistroActividades.vue'
import Security from '../components/security'
import SoliCredito from '../components/superUsuarioComponents/Soli-Credito.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/ahdecoAdminSettings',
        name: 'settings',
        component: ConfiguracionComponent,
        meta: {
            requiresAuth: true,
            roles: ['Admin'],
          },
    },
    {
        path: '/registro-actividades',
        name: 'RegistroActividades',
        component: RegistroActividades,
        meta: {
            requiresAuth: true,
            roles: ['Admin'],
          },
    },
    {
        path: '/soli-credito/:id',
        name: 'SoliCredito',
        component: SoliCredito,
        meta: {
            requiresAuth: true,
            roles: ['Modo Thanos'],
          },
    },
    {
        path: '/ahdecoAdminSettings/:userId',
        name: 'userEdit',
        component: UserEdit,
        meta: {
            requiresAuth: true,
            roles: ['Admin'],
          },
    },
    {
        path: '/solicitante/:solicitanteId',
        name: 'NuevoSolicitante',
        component: NuevoSolicitante,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal'],
          },
    },
    {
        path: '/solicitud',
        name: 'CrearSolicitud',
        component: NuevaSolicitud,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal'],
          },
    },
    {
        path: '/mostrar-solicitantes',
        name: 'Solicitantes',
        component: Solicitantes,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal'],
          },
    },
    {
        
        path: '/solicitud/grupo-solidario/:grupoId',
        name: 'GrupoSolidario',
        component: GruposSolidario,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Coordinador Sucursal'],
          },
    },
    {
        
        path: '/solicitud/integrante/:solicitudId',
        name: 'NuevaSolicitud',
        component: EditSolicitud,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal','Consultor'],
          },
    },
    {
        
        path: '/solicitud-aceptada/:solicitudId',
        name: 'NuevaSolicitudAceptada',
        component: EditSolicitudAceptada,
        meta: {
            requiresAuth: true,
            roles: ['Admin', 'Finanzas'],
          },
    },
    {      
        path: '/grupos-solidarios',
        name: 'GruposSolidarios-App',
        component: GruposSolidarios,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal'],
          },
    },
    { 
        path: '/mostrar-solicitudes',
        name: 'MostrarSolicitudes',
        component: Solicitudes,
        meta: {
            requiresAuth: true,
            roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal'],
          },
        
    },
    { 
        path: '/mostrar-solicitudes-aceptadas',
        name: 'MostrarSolicitudesAceptadas',
        component: SolicitudesAceptadas,
        meta: {
            requiresAuth: true,
            roles: ['Admin', 'Finanzas'],
          },
    },
    { 
        path: '/cartera',
        name: 'CarteraApp',
        component: Cartera,
        meta: {
          requiresAuth: true,
          roles: ['Coordinacion', 'Admin', 'Asesor', 'Coordinador Sucursal', 'Finanzas', 'Consultor'],
        },
    },
    { 
        path: '/pagos',
        name: 'PagosApp',
        component: Pagos,
        meta: {
          requiresAuth: true,
          roles: ['Admin', 'Finanzas','Consultor'],
        },
    },
]

const router = createRouter({history: createWebHistory(), routes})
router.beforeEach(()=>{
    Security.checkToken();
})


router.beforeEach((to, from, next) => {
    // Verificar si la ruta requiere autenticación
    if (to.meta.requiresAuth) {
      // Verificar si el usuario está autenticado
      Security.checkToken();
  
      // Verificar si el usuario tiene los roles necesarios
      const userRoles = Security.getUserRoles(); // Obtén los roles del usuario desde donde los almacenes
      
     const requiredRoles = to.meta.roles;

      if (!userRoles[0].some(role => requiredRoles.includes(role))) {
        // El usuario no tiene los roles necesarios, redirigir a una página de acceso denegado o a la página principal
        next('/');
        return;
      }
    }
  
    // Continuar con la navegación si todo está bien
    next();
  });

export default router