<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-3">
                  <router-link v-if="idGrupoSolidario > 0" class="btn btn-dark text-center ti-angle-left" to="/grupos-solidarios">Volver</router-link>
                  <router-link v-else class="btn btn-dark text-center ti-angle-left" to="/solicitud">Volver</router-link>
                </div>
                <div class="col-6">

                </div>
                <div class="col-3 d-flex justify-content-end">
                  <!-- <button v-if="idGrupoSolidario > 0" @click="update()" class="btn btn-primary text-center ti-angle-right" >Actualizar</button> -->
                  <button v-if="idGrupoSolidario > 0"  class="btn btn-primary text-center ti-angle-right" >Actualizar</button>
                  <button v-else @click="nextPage()" class="btn btn-dark text-center ti-angle-right" >Siguiente Página</button>
                </div>


              </div>


              <!-- Seccion Grupo solidario -->
              <!-- <div v-if="this.producto != `MIPYME` && this.producto != ``" class="row"> -->
              <div class="row d-flex text-center flex-column align-items-center justify-content-center">
                
                <h3 v-if="idGrupoSolidario > 0" class="mt-5 mb-4">Grupo Solidario {{ idGrupoSolidario }}</h3>
                <h3 v-else class="mt-5 mb-4">Creación de Grupo Solidario</h3>
                <div class="row">
                  <div class="form-group d-flex flex-column align-items-center justify-content-center">
                    <label class="col-sm-6 col-form-label">Nombre del Grupo Solidario:</label>
                    <div class="col-sm-6 d-flex align-items-center">
                      <input type="text" v-model="solicitudCompleta.nombreGrupoSolidario" class="form-control" />
                    </div>
                  </div>


                </div>

                <div  class="row mb-3 mt-3 d-flex align-items-center justify-content-center">
                  <div v-if="idGrupoSolidario <= 0" class="col-6  col-lg-6">
                    <p class="text-center">Agregar al grupo solidario por DNI</p>
                    <input v-model="dni" @input="formatInput" placeholder="Ejemplo: 0801-1998-02195"
                      class="form-control mt-2 mb-2" style="height: 20px" @keyup.enter="buscarDNI()" required />
                    <div class="row text-center d-flex justify-content-center align-items-center">
                      <button @click="buscarDNI()" class="btn btn-primary col-6">Agregar Solicitante</button>
                      <router-link class="nav-link w-100" to="/solicitante/0">o Crear Nuevo Solicitante</router-link>
                    </div>
                  </div>

                  <h4 v-if="solicitudCompleta.integrantes != 0" class="mb-3 mt-4">Integrantes</h4>

                  <div v-if="this.alert" class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Holy guacamole!</strong> No se admiten más integrantes. ¡Lo siento! 😔
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>

                  <div class="integra" v-for="(integrante, indice) in solicitudCompleta.integrantes" :key="indice">

                    <div class="integrante p-4 p-sm-2 row mt-3 d-flex align-items-center">
                      <div class="col-3 col-sm-1">
                        <h2>{{ indice + 1 }}</h2>
                      </div>
                      <div class="col-9 col-sm-4">

                        <p> <strong>Nombre:</strong> {{ integrante.nombres }} </p>
                        <p> <strong>Apellidos:</strong> {{ integrante.apellidos }}</p>
                      </div>
                      <div class="col-12 col-sm-2">
                        <p v-if="integrante.coordinador"> <strong style="color: rgb(84, 169, 255);">Coordinador</strong></p>

                      </div> 
                      <div class="col-12 col-sm-2">
                        <p class=""> <strong>dni:</strong> {{ integrante.dni }}</p>
                      </div>
                      <div v-if="idGrupoSolidario <= 0" class="col-12 col-sm-2 d-flex">
                        <button @click="elegirCoordinador(integrante.id)" class="btn btn-primary">Elegir Coordinador</button>
                        <button @click="deleteIntegrante(integrante.id)" class="btn btn-danger ti-trash"></button>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Security from '../security';
import { solicitudCompleta } from '../solicitud';
import Swal from "sweetalert2";

// import TextInputForms from './forms/Text-Input-Forms.vue';
import router from '../../router/index.js'

export default {
  name: "GrupoSolidario",
  data() {
    return {
      idGrupoSolidario: 0,
      noSolicitantes: 0,
      producto: "",
      dni: "",
      cliente: {},
      clientes: [],
      solicitudCompleta,
      integrantesExiste: false,
      alert: false,
    };
  },
  beforeMount() {
    this.idGrupoSolidario = this.$route.params.grupoId 
    // Obtener GrupoSolidario por edit is id > 0
    if (this.idGrupoSolidario > 0) {
      //editing a solicitante
      fetch(
        process.env.VUE_APP_API_URL +
          "/admin/grupo-solidario/" +
          this.$route.params.grupoId,
        Security.requestOptions("")
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit("error", data.message);
          } else {
            // this.Grupo = data.data;
            console.log('Datos Grupo Solidario:', data.data);
            solicitudCompleta.nombreGrupoSolidario = data.data.nombreGrupoSolidario
            solicitudCompleta.integrantes = data.data.clientes
            // this.dni = data.data.dni;
            // this.cliente.estado = data.data.estado;
          }
        });
    }else if (solicitudCompleta.producto == "") {
      router.push("/solicitud");
      location.reload();
      window.location.href = "/solicitud";
    }

    
  },
  unmounted(){
    if (this.idGrupoSolidario > 0) {
      solicitudCompleta.nombreGrupoSolidario = ""
      solicitudCompleta.integrantes = []
    }
  },
  components: {
    // TextInputForms,
  },
  methods: {
    buscarDNI() {

      if (Array.from(this.dni).length == 15) {
        const dni = this.dni.replace(/-/g, "");
        if (this.noSolicitantes < 5) {
          fetch(
            `${process.env.VUE_APP_API_URL}/admin/clientes/dni/${dni}`,
            Security.requestOptions("")
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                alert('No se ha encontrado Con ese Dni')
              } else {
                this.cliente = data.data;

                if (!solicitudCompleta.dniIntegrantes.includes(this.cliente.id)) {

                  solicitudCompleta.integrantes.push(this.cliente);
                  solicitudCompleta.dniIntegrantes.push(this.cliente.id);
                  this.dni = ''
                  //Aumenta el número de solictiantes
                  this.noSolicitantes++;
                } else alert("El Cliente ya ha sido agregado")
              }
            })
            .catch((error) => {
              this.$emit("error", error);
            });
        } else {
          alert('No se admite otro dni')
          this.alert = true
        }
      } else {
        alert('No es un DNI')
      }
    },



    formatInput() {
      // Elimina todos los guiones existentes en el valor actual
      const cleanedValue = this.dni.replace(/-/g, "");

      // Aplica el formato con guiones
      let formattedValue = "";
      for (let i = 0; i < cleanedValue.length; i++) {
        if (i === 4 || i === 8) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      // Actualiza el valor del input
      this.dni = formattedValue;
    },
    elegirCoordinador(id){
      solicitudCompleta.integrantes.forEach(integrante => {
        if (integrante.id == id) {
          integrante.coordinador = true
        }else{
          integrante.coordinador = false
        }
      });
    },

    deleteIntegrante(idAEliminar) {

      solicitudCompleta.integrantes = solicitudCompleta.integrantes.filter(objeto => objeto.id !== idAEliminar);
      solicitudCompleta.dniIntegrantes = solicitudCompleta.dniIntegrantes.filter(id => id !== idAEliminar);
      this.noSolicitantes--;
    },
    nextPage() {
      //Comprobar 
      if (solicitudCompleta.integrantes.length > 1) {
        if (this.solicitudCompleta.idGrupoSolidario > 0) {
          //Se manda integrante que se desea 
          router.push("/solicitud/integrante/0");
        }else{
          
          //Se mandara a crear el grupo solidario a la base de datos
        const payloadGrupo = {
          id: 0,
          producto: this.solicitudCompleta.producto,
          nombreGrupoSolidario: this.solicitudCompleta.nombreGrupoSolidario,
          idUsuario: Security.getUser()[0].user.id,
        };
        //Agregar Grupo solidario
        fetch(`${process.env.VUE_APP_API_URL}/admin/grupo-solidario/save`, Security.requestOptions(payloadGrupo))
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              Swal.fire({
                // position: 'top-end',
                icon: "error",
                title: data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              //Info Correcta
              //Obtener ID de Grupos Solidario
              this.solicitudCompleta.idGrupoSolidario = data.data.id
              Swal.fire({
                // position: 'top-end',
                icon: "success",
                title: "Se ha Guardado Correctamente el Grupo solidario",
                showConfirmButton: false,
                timer: 1500,
              });
              //Se manda integrante que se desea 
              router.push("/solicitud/integrante/0");
            }
          })
          .catch((error) => {
            this.$emit('error', error);
          })



        }
        


      } else {
        Swal.fire({
                // position: 'top-end',
                icon: "error",
                title: "Se Necesitan Mínimo Dos Integrantes",
                showConfirmButton: false,
                timer: 1500,
              });
      }




    }
  }
};
</script>
  
  
<style scoped>
.btn {
  color: white;
}

.card {
  padding: 20px;
  border-radius: 12px;
  /* border-left-color: #4285f4; */
  border-top: 5px solid #ff910088;
  border-left: 5px solid #4286f467;
}

.form-control {
  border-radius: 10px;
  height: 70%;
}

.form-control:focus {
  border-color: #ff9100;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* Sombra */
}

.col-form-label {
  margin: 0px !important;
}

.form-group {
  margin-bottom: 0px;
}

.form-group {
  margin-block: 8px;
}

.col-form-label {
  padding-left: 10px;
  padding-right: 5px;
}

.card-description {
  margin-block: 15px;
}

.form-control {
  color: #4a4a4a !important;
}

.btnn {
  /* background-color: aqua; */
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.btnn p {
  margin-bottom: 0px;
  color: white;
}

.plan-inversión {
  /* width: 50%; */
  border-collapse: collapse;
  margin-bottom: 20px;
}

.plan-inversión,
th,
td {
  border: 1px solid black;
  text-align: center;
}

th,
td {
  padding: 10px;
  vertical-align: top;
  /* Alinea el contenido en la parte superior de las celdas */
}

.input-plan {
  width: 100%;
  box-sizing: border-box;
  border: none;
  /* Elimina los bordes de los inputs */
  outline: none;
  /* Elimina el contorno al hacer clic en los inputs */
}

.table-container {
  width: 70%;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  vertical-align: top;
}

.custom-input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.integrante {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  padding: 10px;

  -webkit-box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);

  border-radius: 32px 124px 32px 124px;
  -moz-border-radius: 32px 124px 32px 124px;
  -webkit-border-radius: 32px 124px 32px 124px;
  border: 0px solid #000000;
}
</style>
  
<style>
.integrante {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  padding: 10px;

  -webkit-box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);

  border-radius: 32px 124px 32px 124px;
  -moz-border-radius: 32px 124px 32px 124px;
  -webkit-border-radius: 32px 124px 32px 124px;
  border: 0px solid #000000;
}
</style>
  