<template>
    <div class="container mt-5 w-100 horizontal-scrollable">
        <div class="row">
            <h2>Beneficiarios</h2>
        </div>
        <div class="row text-center mt-3">
            <table class="table table-striped table-hover" id="myTable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombres</th>
                        <th>DNI</th>
                        <th>Show</th>
                    </tr>

                </thead>
                <tbody v-if="this.ready">
                    <tr v-for="(solicitante) in this.solicitantes" :key="solicitante.id">
                        <td>{{ solicitante.id }}</td>
                        <td>{{ solicitante.nombres }} {{ solicitante.apellidos }}</td>
                        <td>{{ solicitante.dni }}</td>
                        <td><a  :href="`/solicitante/${solicitante.id}`"
                                class="btn btn-am btn-inverse-primary" v-if="this.Coordinacion || this.Admin || this.Asesor">Ver
                                más</a>
                                <a class="btn btn-am btn-inverse-primary" v-else>Mostrar Info &nbsp; <i class="fa-solid fa-person-digging"></i></a>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="10" class="text-center">
                            <div class="spinner-border text-warning text-center" role="status">
                                <span class="visually-hidden text-center" colspan="10" col>Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Security from '../security';
// import { DataTable } from "simple-datatables"
import DataTables from "datatables.net";



export default {
    name: 'Solicitantes-App',
    data() {
        return {
            solicitantes: [],
            ready: false,

            roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Coordinador Sucursal'],
            Finanzas: false,
            coordinadorSucursal: false,
            Admin: false,
            Coordinacion: false,
            Asesor: false,
            userRoles: []
        }
    },
    updated() {
        this.initializeDataTable()
    },
    beforeMount() {

        // const myTable = document.querySelector("#myTable");
        // const dataTable = new DataTable(myTable);
        // dataTable
        this.fetchData()
        // this.initializeDataTable
    },
    mounted(){
        this.userRoles = Security.getUserRoles();
        this.roles.forEach(element => {
        if(this.userRoles[0]?.some(role => role.includes(element)) === true){
            this[element] = true;
        }else if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
            this.coordinadorSucursal = true;
        }
        });
    },
    methods: {
        fetchData() {
            // Realiza la solicitud Fetch para obtener los datos

            const payload = {}
            fetch(process.env.VUE_APP_API_URL + "/admin/solicitantes/all", Security.requestOptions(payload))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        this.$emit('error', response.message);
                        alert(response.message)
                    } else {
                        // this.books = response.data.books;
                        console.log('Response', response.data.solicitantes)
                        this.solicitantes = response.data.solicitantes
                        this.ready = true;
                        // Llama a la función initializeDataTable() después de un retraso de 2 segundos (2000 milisegundos)
                        // setTimeout(this.initializeDataTable, 20000);                    
                    }
                })
                .catch(error => {
                    this.$emit('error', error)
                })
        },
        initializeDataTable() {
            console.log('Despues Iniciar');
            const myTable = document.querySelector("#myTable");
            if (myTable) {
                new DataTables(myTable);
            }
        },
        mostrarSolicitante(id) {
            console.log(id);

        },
    }
}
</script>

<style scoped>
/* The heart of the matter */

.horizontal-scrollable>.row {
    overflow-x: auto;
    white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
    display: inline-block;
    float: none;
}

/* Decorations */

.col-xs-4 {
    color: white;
    font-size: 24px;
    padding-bottom: 20px;
    padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
    background: green;
}

.col-xs-4:nth-child(2n+2) {
    background: black;
}

.btn-am {
    height: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0px;
}
</style>