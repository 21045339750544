<template>
  <div class="col-md-6">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{ label }}</label>
      <div class="col-sm-9 d-flex align-items-center">
        <input
          :type="type"
          :name="name"
          :placeholder="placeholder"
          :required="this.required"
          :min="min"
          :max="max"
          :id="id"
          :value="modelValue"
          :autocomplete="name + '-new'"
          @input="$emit('update:modelValue', $event.target.value)"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import TextInput from '@/components/forms/Text-Input.vue'

export default {
  name: "Text-Input-Form",
  components: {
    // 'text-input': TextInput,
  },
  props: {
    label: String,
    type: String,
    placeholder: String,
    required: String,
    value: String,
    name: String,
    min: String,
    max: String,
    id: String,
    modelValue: String,
  },
};
</script>

<style></style>
