import jsPDF from "jspdf";
import "jspdf-autotable";



export const exportacionContratos = (solicitud, cuotaSolicitud, comisionDesembolso, comisionAsistenciaTecnica) => {
  const garantia = solicitud.garantia;
  if (garantia === "") {
    alert("Seleccione una Garantía");
    return;
  }

  if (
    solicitud.producto.toString().trim().toLowerCase() === "credi trabajo 2"
  ) {
    console.log("TRUE");

    let duracionPago, periodo, noCuotas, diasPeriodo;
    let fechaApertura = new Date();
    switch (solicitud.frecuenciaPago) {
      case "mensual":
        duracionPago = 30 * 24 * 60 * 60 * 1000;
        fechaApertura = new Date(
          fechaApertura.getTime() + 30 * 24 * 60 * 60 * 1000
        );
        noCuotas = solicitud.plazoPago;
        periodo = 12;
        diasPeriodo = 30.0;
        break;
      case "MENSUAL":
        duracionPago = 30 * 24 * 60 * 60 * 1000;
        fechaApertura = new Date(
          fechaApertura.getTime() + 30 * 24 * 60 * 60 * 1000
        );
        noCuotas = solicitud.plazoPago;
        periodo = 12;
        diasPeriodo = 30.0;
        break;

      case "quincenal":
        duracionPago = 15 * 24 * 60 * 60 * 1000;
        fechaApertura = new Date(
          fechaApertura.getTime() + 15 * 24 * 60 * 60 * 1000
        );
        noCuotas = solicitud.plazoPago * 2;
        periodo = 24;
        diasPeriodo = 15.0;
        break;
      case "QUINCENAL":
        duracionPago = 15 * 24 * 60 * 60 * 1000;
        fechaApertura = new Date(
          fechaApertura.getTime() + 15 * 24 * 60 * 60 * 1000
        );
        noCuotas = solicitud.plazoPago * 2;
        periodo = 24;
        diasPeriodo = 15.0;
        break;

      case "semanal":
        duracionPago = 7 * 24 * 60 * 60 * 1000;
        fechaApertura = new Date(
          fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
        );
        noCuotas = Math.round(solicitud.plazoPago * (4.3333333333));
        periodo = 52.1;
        diasPeriodo = 7.0;
        break;
      case "SEMANAL":
        duracionPago = 7 * 24 * 60 * 60 * 1000;
        fechaApertura = new Date(
          fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
        );
        noCuotas = Math.round(solicitud.plazoPago * (4.3333333333));
        periodo = 52.1;
        diasPeriodo = 7.0;
        break;

      default:
        return;
    }

    console.log(duracionPago, fechaApertura, periodo, diasPeriodo);
    // const data = JSON.stringify(solicitud)
    const doc = new jsPDF("p", "");

    const img = new Image();
    doc.addImage(
      (img.src = require("@/assets/header.png")),
      "png",
      0,
      0,
      doc.internal.pageSize.width,
      30
    );
    // doc.addImage(
    //   img.src = require('@/assets/logo-mini.jpg'),
    //   "png",
    //   30,
    //   65,
    //   150,
    //   150
    // );
    const footer = `Col. Rubén Darío, Casa 0592 Tegucigalpa, Honduras
  RTN:08269016867779
  Email: info@ahdecohn.com
  Telefono: 2242-5780
  `;
    doc.setFont("helvetica", "bold");

    // Titulos
    doc.setFontSize(12);

    const title = "CONTRATO DE PRÉSTAMO";
    const titleWidth = doc.getTextWidth(title); // Obtener el ancho del texto del título
    const centerX = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular la posición X para centrar
    const yTitle = 30; // Posición Y del título

    doc.text(title, centerX, yTitle); // Dibujar el título

    // Calcular la longitud del subrayado
    const subrayadoLongitud = titleWidth; // Usar el ancho del título para la longitud del subrayado
    doc.line(centerX, yTitle + 2, centerX + subrayadoLongitud, yTitle + 2); // Dibujar el subrayado
    doc.setFontSize(11);
    const title2 = `POR LA CANTIDAD DE L. ${formatoNumero(
      solicitud.monto
    )}`;
    const titleWidth2 = doc.getTextWidth(title2); // Obtener el ancho del texto del segundo título
    const centerX2 = (doc.internal.pageSize.width - titleWidth2) / 2; // Calcular la posición X para centrar
    const yTitle2 = 38; // Posición Y del segundo título

    doc.text(title2, centerX2, yTitle2); // Dibujar el segundo título

    //  **************************
    // Configuración de fuente y tamaño para el primer párrafo
    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.setLineHeightFactor(1.7);

    let altura;
    const margenIzquierdo = 30; // Margen izquierdo personalizado
    const anchoParrafo = 150; // Ancho máximo del párrafo

    // Primer párrafo
    const parr1 = `Préstamo No.AHDE-${solicitud.id}  por  ${numeroALetras(solicitud.monto)}  EXACTOS (L ${formatoNumero(
      solicitud.monto
    )})  vigente a partir de esta fecha.`;

    const yParr1 = 50; // Posición Y del primer párrafo

    doc.text(parr1, margenIzquierdo, yParr1, {
      maxWidth: anchoParrafo,
      align: "justify",
    });

    // Obtener las dimensiones del texto
    let dim = doc.getTextDimensions(parr1, {
      maxWidth: anchoParrafo,
    });

    altura = dim.h + yParr1 + 5;

    // Segundo párrafo
    const parr2 = `Nosotros de una parte el señor (a) ${
      solicitud.cliente.nombres
    } ${
      solicitud.cliente.apellidos != undefined
        ? solicitud.cliente.apellidos
        : ""
    } con Documento Nacional de Identificación (DNI) No. ${
      solicitud.cliente.dni
    }, Registro Tributario Nacional No. ${
      solicitud.cliente.rtn
    } hondureño(a), COMERCIANTE, y de este domicilio, actuando en su condición de Prestatario, que adelante en este contrato se denominará como “El Prestatario”; y por la otra parte CARLOS JOSÉ RODRÍGUEZ AGUILERA, mayor de edad, soltero, ingeniero agrónomo, hondureño, con tarjeta de identidad número 0601-1976-00178 y Registro Tributario Nacional (R.T.N.) número 06011976001789, y de este domicilio, actuando en mi condición de Representante legal de la ASOCIACIÓN HONDUREÑA PARA EL DESARROLLO INTEGRAL COMUNITARIO (AHDECO), con Registro Tributario Nacional (RTN) No. 08269016867779, que adelante en este Contrato se identificará como AHDECO; hemos convenido en celebrar y al efecto celebramos un Contrato de Préstamo que se regirá por las estipulaciones que a continuación se consignan y que conjuntamente expresan:`;

    const yParr2 = altura; // Posición Y del segundo párrafo

    doc.text(parr2, margenIzquierdo, yParr2, {
      maxWidth: anchoParrafo,
      align: "justify",
    });
    // const widthParaph = 180;
    altura = espacioParrafos(doc, anchoParrafo, parr2, yParr2, 5);
    doc.setFontSize(12);

    const parr3 = `PRIMERO: MONTO Y FINALIDAD DEL CONTRATO DE PRÉSTAMO.`;

    doc.setFont("helvetica", "bold");
    doc.text(parr3, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    doc.setFontSize(11);

    altura = espacioParrafos(doc, anchoParrafo, parr3, altura, 5);

    doc.setFont("helvetica", "normal");
    const parr4 = `AHDECO otorga el presente Préstamo por la suma de: ${numeroALetras(solicitud.monto)} (L.${formatoNumero(
      solicitud.monto
    )}) declara “El Prestatario” que el monto acordado, una vez realizadas las deducciones correspondientes por concepto de comisiones y gastos asociados, se utilizará de forma íntegra según el plan de inversión y de negocios presentado, comprometiéndose a destinarlo para Capital de trabajo o Capital de Inversión necesario para las operaciones y buen funcionamiento del emprendimiento.`;
    doc.setLineHeightFactor(1.7);
    doc.text(parr4, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.0,
    });
    altura = espacioParrafos(doc, anchoParrafo, parr4, altura, 5);

    const parr5 = `SEGUNDO: DEL DESEMBOLSO.`;
    doc.setFont("helvetica", "bold");
    doc.text(parr5, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);

    altura = espacioParrafos(doc, anchoParrafo, parr5, altura, 5);

    const parr6 = `AHDECO desembolsará dicho financiamiento en moneda nacional, la cantidad
de ${numeroALetras(solicitud.monto)} (L.${formatoNumero(
      solicitud.monto
    )}), y se efectuará de una sola vez al firmar dicho contrato. El desembolso se puede dar de manera extraordinaria mediante la emisión de un cheque emitido a nombre de “El Prestatario” o en su defecto de la persona que este designe, contra la emisión`;

    doc.text(parr6, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr6, altura, 5);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(footer, 108, 270, {
      maxWidth: anchoParrafo,
      align: "center",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    doc.setFontSize(11);
    // Sigueinte Página
    altura = 0;
    doc.addPage();
    doc.addImage(
      (img.src = require("@/assets/header.png")),
      "png",
      0,
      0,
      doc.internal.pageSize.width,
      30
    );

    // Parrafo 7
    const parr7 = `de un Pagaré por igual valor. Dicho Pagaré se cargará a la cuenta del presente
contrato y los pagos parciales en abono al principal o el pago total del principal
serán anotados a opción de AHDECO en el Pagaré a la orden correspondiente,
además de reflejarse dichos pagos en los recibos otorgados por el Banco donde
realice el pago. La emisión del cheque a favor de “El Prestatario”, y el cobro del
mismo, serán suficiente evidencia del desembolso respectivo. En los casos que
corresponda, si “El Prestatario” no retira los fondos dentro de los quince días
siguientes a las fechas establecidas en esta cláusula, o la fecha que AHDECO
comunique a “El Prestatario” que están disponibles, AHDECO, podrá desobligar
la cantidad que debió desembolsar. `;

    doc.text(parr7, margenIzquierdo, 30, {
      maxWidth: anchoParrafo,
      align: "justify",
      // lineHeight: 2.5,
      // fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr7, 30, 5);

    // Parrafo 8

    const parr8 = `TERCERA: INTERESES, MORA Y COMISIONES.`;
    doc.setFont("helvetica", "bold");
    doc.text(parr8, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFont("helvetica", "normal");

    altura = espacioParrafos(doc, anchoParrafo, parr8, altura, 5);

    // Parrafo 9

    const parr9 = `“El Prestatario” reconocerá una tasa de interés moratorio que será del uno
por ciento mensual (1%), el mismo se aplicará sobre el saldo de capital e intereses
vencidos del crédito según el plan de pago acordado. Para los efectos de esta
disposición se entenderá que El Prestatario incurrirá en mora al siguiente día
después de haberse vencido la cuota correspondiente. El Prestatario acepta el
pago en concepto de comisiones y gastos por los siguientes cargos: 1.- En
concepto de Comisión por Desembolso reconocerá ${numeroALetrasPorcentaje(comisionDesembolso)}
(${comisionDesembolso}%) sobre el monto aprobado respectivo. 2.- Comisión por brindar asistencia
técnica y formación de competencias para la puesta en marcha o fortalecer un
emprendimiento o negocio que será de ${numeroALetrasPorcentaje(comisionAsistenciaTecnica)}
(${comisionAsistenciaTecnica}%) del monto del crédito desembolsado, comisión que será deducida al
momento del desembolso del crédito. 3.- Cobro de interés será de ${numeroALetrasPorcentaje(solicitud.interes / 12)} (${
      solicitud.interes / 12
    }%), comisión que será deducida al momento del desembolso del crédito. 4.- En los casos que corresponda, los gastos generados por el uso de los canales y medios de pago habilitados para la recuperación de los créditos.`;

    doc.text(parr9, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr9, altura, 5);

    //Parrafo 10
    const par10 = `CUARTA: LUGAR, PLAZO, IMPUTACIÓN Y FORMA DE PAGO.`;
    doc.setFont("helvetica", "bold");
    doc.text(par10, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFont("helvetica", "normal");

    altura = espacioParrafos(doc, anchoParrafo, par10, altura, 5);

    // Parrafo 11
    const parr11 = `Los pagos referentes al presente Contrato de Préstamo serán realizados por
El Prestatario para con el crédito adscrito en el contrato supra mencionado a favor
de AHDECO, a través de las ventanillas de la Institución Financiera Intermediaria
(IFI), autorizada o los canales y medios de pago habilitados para tal efecto, ya
sea  en la ciudad de suscripción del presente contrato, o en las filiales, sucursales
o agencias que esta posea en el territorio nacional, sea por motivo del pago de
la cuantía principal, intereses, comisiones y / o demás accesorios de este préstamo.`;

    doc.text(parr11, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    // altura = espacioParrafos(doc,anchoParrafo,parr11,altura, 5)

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(footer, 108, 270, {
      maxWidth: anchoParrafo,
      align: "center",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFontSize(11);

    // Siguiente Página
    doc.addPage();
    altura = 0;
    doc.addImage(
      (img.src = require("@/assets/header.png")),
      "png",
      0,
      0,
      doc.internal.pageSize.width,
      30
    );
    // doc.addImage(
    //   img.src = require('@/assets/logo-mini.jpg'),
    //   "png",
    //   30,
    //   65,
    //   150,
    //   150
    // );
    const parr11_1 = `La cuota establecida para este crédito es de L.${formatoNumero(
      cuotaSolicitud
    )} en ${noCuotas} cuotas en pagos semanales por la cantidad de ${numeroALetras(
      cuotaSolicitud
    )}  según el plan de pago acordado y anexo al presente contrato. El Prestatario se obliga a pagar el monto acordado del presente préstamo en un plazo total de ${
      solicitud.plazoPago
    } MESES, que comenzarán a contar a partir de la fecha en que se efectúe el desembolso de los fondos. Todo pago efectuado por El Prestatario a AHDECO como consecuencia de este Contrato, se imputará con prelación o preferencia, en primer lugar, gastos por cobranza extrajudicial y/o judicial, seguros, gastos generados por el uso de los canales y medios de pago, intereses moratorios, intereses corrientes, y el principal vencido. Queda entendido que las cuotas pactadas son calculadas por el sistema de cuotas niveladas. El Prestatario no incurrirá en penalidad alguna, si efectuare anticipadamente pagos parciales al crédito otorgado en el presente documento, o bien, el pago anticipado del total de este.`;

    doc.text(parr11_1, margenIzquierdo, 30, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr11_1, 30, 5);

    // Parrafo 12
    const par12 = `QUINTO: OBLIGACIONES Y FACULTADES DEL PRESTATARIO`;
    doc.setFont("helvetica", "bold");
    doc.text(par12, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFont("helvetica", "normal");

    altura = espacioParrafos(doc, anchoParrafo, par12, altura, 5);

    // Parrafo 13
    const parr13 = `1) El Prestatario aceptará y cooperará con la información que AHDECO
considere necesaria para permitir la asistencia técnica y su desarrollo empresarial.
2) El Prestatario mantendrá normas administrativas aceptables a AHDECO y
además de común acuerdo aplicará cambios para mejorar su administración.
3) El Prestatario no destinará los fondos del préstamo para otros propósitos que
no sean exclusivamente para los cuales fue aprobado y concedido.
4) El Prestatario no destinará su capital a propósitos que puedan afectar o
disminuir las garantías dadas a AHDECO, tampoco disminuirá su capital sin el consentimiento dado por escrito de AHDECO.                                                       
El Prestatario acepta los cambios que AHDECO apruebe en relación al presente contrato o la fluctuación de la tasa de interés del mismo.                                 
5) Si al vencimiento del préstamo El Prestatario aún no ha cancelado la totalidad
de la deuda, este se compromete a responder con todos los bienes de su
propiedad hasta cancelar totalmente el saldo adeudado más los intereses devengados a la fecha.                                         
6) El Prestatario y sus Avales cubrirán los gastos y honorarios en que se incurra para constituir las garantías que AHDECO exija.                                   
7) Participar de los procesos de asistencia técnica y capacitaciones diseñados e impartidos por AHDECO para el desarrollo de su emprendimiento.                         
  `;
    doc.text(parr13, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(footer, 108, 270, {
      maxWidth: anchoParrafo,
      align: "center",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFontSize(11);

    // Pagina Nueva
    doc.addPage();
    doc.addImage(
      (img.src = require("@/assets/header.png")),
      "png",
      0,
      0,
      doc.internal.pageSize.width,
      30
    );

    const parr14 = `SEXTO: OBLIGACIONES Y FACULTADES DE AHDECO`;
    doc.setFont("helvetica", "bold");
    doc.text(parr14, margenIzquierdo, 30, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr14, 30, 5);

    doc.setFont("helvetica", "normal");
    const parr15 = `1) AHDECO se compromete a dar la asistencia técnica pertinente a El Prestatario.
2) AHDECO deberá notificar cualquier cambio a El Prestatario.              
3) AHDECO otorgará el finiquito al Prestatario al cancelar la totalidad del saldo de su crédito, el cual podrá reclamar en las oficinas del mismo.                  
4) AHDECO podrá efectuar supervisiones periódicas para determinar si los fondos entregados en préstamo han sido utilizados a fines distintos a los aquí pactados, pudiendo exigir el pago inmediato si se incumplen las condiciones aquí pactadas.`;
    doc.text(parr15, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr15, altura, 5);

    const parr16 = `SEPTIMO: GARANTÍAS Y AUTORIZACIÓN`;
    doc.setFont("helvetica", "bold");
    doc.text(parr16, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr16, altura, 5);

    doc.setFont("helvetica", "normal");
    const parr17 = `El Prestatario presentará como garantía del pago del principal, intereses, comisiones, gastos de cobro extrajudicial y/o judicial, honorarios judiciales y /o extrajudiciales y de todas las obligaciones que deriven del presente Contrato una Garantía Fiduciaria.   
El Prestatario constituye a favor de AHDECO; GARANTÍA FIDUCIARIA.`;
    doc.text(parr17, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    altura = espacioParrafos(doc, anchoParrafo, parr17, altura, 5);

    doc.setFont("helvetica", "normal");
    let parr17a = ``;
    let nombreIntegrante;
    let dniIntegrante;
    let rtnIntegrante;
    let noIntegrantes = 0;

    for (let i = 0; i < 5; i++) {
      switch (i) {
        case 0:
          nombreIntegrante = solicitud.integrantesGruposSolidario.nombre1;
          dniIntegrante = solicitud.integrantesGruposSolidario.int1;
          rtnIntegrante = solicitud.integrantesGruposSolidario.rtn1;

          break;
        case 1:
          nombreIntegrante = solicitud.integrantesGruposSolidario.nombre2;
          dniIntegrante = solicitud.integrantesGruposSolidario.int2;
          rtnIntegrante = solicitud.integrantesGruposSolidario.rtn2;
          break;
        case 2:
          nombreIntegrante = solicitud.integrantesGruposSolidario.nombre3;
          dniIntegrante = solicitud.integrantesGruposSolidario.int3;
          rtnIntegrante = solicitud.integrantesGruposSolidario.rtn3;
          break;
        case 3:
          nombreIntegrante = solicitud.integrantesGruposSolidario.nombre4;
          dniIntegrante = solicitud.integrantesGruposSolidario.int4;
          rtnIntegrante = solicitud.integrantesGruposSolidario.rtn4;
          break;
        case 4:
          nombreIntegrante = solicitud.integrantesGruposSolidario.nombre5;
          dniIntegrante = solicitud.integrantesGruposSolidario.int5;
          rtnIntegrante = solicitud.integrantesGruposSolidario.rtn5;
          break;
      }
      if (nombreIntegrante != undefined && nombreIntegrante != "") {
        if (dniIntegrante != solicitud.cliente.dni) {
          parr17a = parrafo(noIntegrantes, nombreIntegrante, dniIntegrante, rtnIntegrante);
          doc.text(parr17a, margenIzquierdo, altura, {
            maxWidth: anchoParrafo,
            align: "justify",
            lineHeight: 2.5,
            fontWeight: "bold",
          });
          altura = espacioParrafos(doc, anchoParrafo, parr17a, altura, 5);
          noIntegrantes++;
        }
      }

      // parr17a = parrafo(i, nombreIntegrante, dniIntegrante, rtnIntegrante);
      //   doc.text(parr17a, margenIzquierdo, altura, {
      //     maxWidth: anchoParrafo,
      //     align: "justify",
      //     lineHeight: 2.5,
      //     fontWeight: "bold",
      //   });
      //   altura = espacioParrafos(doc, anchoParrafo, parr17a, altura, 5);
    }

    doc.setFont("helvetica", "normal");
    const parr17b = `El Prestatario acepta presentar cualquier otra garantía en caso de que AHDECO lo solicite para garantizar el pago del crédito y demás obligaciones que se deriven del contrato supra mencionado. El Prestatario y su aval o avales, en caso de tenerlos, cubrirá los gastos y honorarios en que incurra para constituir las garantías que AHDECO exija, de igual forma este y sus avales firmarán el o los Pagarés o cualquier otro documento que AHDECO exija.`;
    doc.text(parr17b, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    if (noIntegrantes >= 3) {
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text(footer, 108, 270, {
        maxWidth: anchoParrafo,
        align: "center",
        lineHeight: 2.5,
        fontWeight: "bold",
      });

      doc.setFontSize(11);
      doc.addPage();
      altura = 30;
      doc.addImage(
        (img.src = require("@/assets/header.png")),
        "png",
        0,
        0,
        doc.internal.pageSize.width,
        30
      );
      altura = 30;
    } else {
      altura = espacioParrafos(doc, anchoParrafo, parr17b, altura, 5);
    }
    const parr17c = `El Prestatario autoriza a AHDECO a realizar averiguación y a solicitar información crediticia, bancaria, registral de bienes muebles o inmuebles, en cualquier institución del sistema financiero, la Comisión Nacional de Banca y Seguros (CNBS), y cualquier otra Institución, con el fin de identificar otras garantías que sean necesarias a discreción de AHDECO.`;
    doc.text(parr17c, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr17c, altura, 5);

    if (noIntegrantes < 3) {
      // Sigueinte Página
      doc.addPage();
      altura = 0;
      doc.addImage(
        (img.src = require("@/assets/header.png")),
        "png",
        0,
        0,
        doc.internal.pageSize.width,
        30
      );
      altura = 30;
    }

    const parr18 = `OCTAVO: NOTIFICACIONES`;
    doc.setFont("helvetica", "bold");
    doc.text(parr18, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr18, altura, 5);

    doc.setFont("helvetica", "normal");
    const parr19 = `Para efectos de modificaciones, notificaciones y requerimientos relacionados con el presente contrato, “El prestatario” establece el siguiente domicilio ${solicitud.cliente.colonia}  Teléfono ${(solicitud.cliente.celular)?solicitud.cliente.celular:((solicitud.cliente.telefono)?solicitud.cliente.telefono:"") } y AHDECO establece el siguiente domicilio, Col. Rubén Darío, Casa No. 0592, en la ciudad de Tegucigalpa.`;
    doc.text(parr19, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr19, altura, 5);

    const parr20 = `NOVENA: INCUMPLIMIENTO Y RENUNCIA`;
    doc.setFont("helvetica", "bold");
    doc.text(parr20, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr20, altura, 5);

    doc.setFont("helvetica", "normal");
    const parr21 = `El incumplimiento de cualquiera de las condiciones establecidas por el presente Contrato por parte de El Prestatario dará derecho a AHDECO a declarar vencido el préstamo y exigir el pago de las cantidades desembolsadas con sus intereses respectivos. En caso de exigirse judicialmente la obligación El Prestatario renuncia a su domicilio y se somete al de AHDECO o al que este elija, así mismo se obliga al pago de honorarios profesionales de abogados y demás costas, ya sea que el cobro se haga de forma judicial o extrajudicial. El Prestatario acepta traspasar eventualmente sus derechos a favor de AHDECO respondiendo de la existencia y legitimidad de estos.`;
    doc.text(parr21, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr21, altura, 5);

    const parr22 = `DÉCIMA: ACEPTACIÓN`;
    doc.setFont("helvetica", "bold");
    doc.text(parr22, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr22, altura, 5);

    doc.setFont("helvetica", "normal");
    const parr23 = `Ambas partes se comprometen al fiel cumplimiento de las obligaciones aquí contraídas y acuerdan que, en lo no previsto en este Contrato se regirá por lo dispuesto en la normativa jurídica que corresponda. Para constancia firman este Contrato por duplicado, conservando cada parte su ejemplar en la ciudad de Distrito Central a los ____ días del mes de __________del año 2024.`;
    doc.text(parr23, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    
    if (noIntegrantes >= 3) {
      altura = espacioParrafos(doc, anchoParrafo, parr23, altura, 15);
    }else{
      altura = espacioParrafos(doc, anchoParrafo, parr23, altura, 25);
    }
    const parr24 = `CARLOS JOSE RODRIGUEZ AGUILERA`;
    doc.setFont("helvetica", "bold");
    doc.text(parr24, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    altura = espacioParrafos(doc, anchoParrafo, parr24, altura, 3);
    const parr25 = `REPRESENTANTE LEGAL DE AHDECO`;
    doc.setFont("helvetica", "normal");
    doc.text(parr25, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    if (noIntegrantes >= 3) {
      altura = espacioParrafos(doc, anchoParrafo, parr25, altura, 10);
    }else{
      altura = espacioParrafos(doc, anchoParrafo, parr25, altura, 15);
    }

    const parr26 = `PRESTATARIO`;
    doc.setFont("helvetica", "bold");
    doc.text(parr26, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    // altura = espacioParrafos(doc, anchoParrafo, parr26, altura, 15);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(footer, 108, 270, {
      maxWidth: anchoParrafo,
      align: "center",
      lineHeight: 2.5,
      fontWeight: "bold",
    });

    doc.save(`CONTRATO_${solicitud.producto}_${solicitud.cliente.nombres}.pdf`);
  } else {
    alert("Contrato No Disponible");
  }
};

function formatoNumero(numero) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numero);
}

function espacioParrafos(doc, anchoParrafo, parrafo, yparr, espacio) {
  // Obtener las dimensiones del texto
  let dim = doc.getTextDimensions(parrafo, {
    maxWidth: anchoParrafo,
  });

  let altura = dim.h + yparr + espacio;

  return altura;
}

function parrafo(index, nombreIntegrante, dniIntegrante, rtnIntegrante) {
  let parrafo = `${
    index + 1
  }.- Garante Fiduciario El/ La Señor (a) ${nombreIntegrante} con Documento Nacional de Identificación (DNI) No. ${dniIntegrante} y Registro Tributario Nacional (RTN) No. ${
    rtnIntegrante != undefined ? rtnIntegrante : ""
  } (rtn)`;

  return parrafo;
}


const numeroALetras = (n) =>{
  //***** CONFIGURACIÓN ****
  // UDS - Pesos MX - Pesos colombianos etc. determina la etiqueta de la moneda
  const moneda = "LEMPIRAS"; 
  // puedes usar "con" o "y" Chiquipesos - centavos etc.
  const cents  = ["con", "Centavos"]; 
  // 1 = Todo minúsculas, 2 = Todo Mayúsculas, 3 = Title case moneda Mayúscula, 
  // 4 = Todo minúculas y moneda Mayúscula, 0 = Camel Case 
  const format = 2
  
  // Variables
  const temp = [moneda , 'Mil', 'Millones', 'Mil'];		
  const tem = Number.parseFloat(n).toFixed(2).split('.');
  const x = Number.parseInt(tem[0]);
  const y = Number.parseInt(tem[1]);
  let salidaEntero = [];
  let salidaCentavos = ''
  
  const centenas = (num) =>{
      const centena = Math.floor(num / 100);
      const decena = num - (Math.floor(centena * 100));				
      // console.log("centena:",centena,"decena", decena);
      switch(centena){
          case 0: return '' + decenas(decena);
          case 1: 
              if (decena === 0){
                  return 'Cien';					
              } else{
                  return 'Ciento ' + decenas(decena);
              }
          case 2: return 'Doscientos ' + decenas(decena);				
          case 3: return 'Trecientos ' + decenas(decena);				
          case 4: return 'Cuatrocientos ' + decenas(decena);					
          case 5: return 'Quinientos ' + decenas(decena);					
          case 6: return 'Seiscientos ' + decenas(decena);					
          case 7: return 'Setecientos ' + decenas(decena);					
          case 8: return 'Ochocientos ' + decenas(decena);					
          case 9: return 'Novecientos ' + decenas(decena);					
      }
  }

  const decenas = (num) =>{
      let decena = Math.floor(num /10);
      let unidad = num - (decena * 10);
      // console.log("decenas:",decena,"unidades:", unidad);
      switch(decena) {
          case 0: return '' + unidades(unidad);
          case 1: 
              switch(unidad){
                  case 0: return 'Diez' 
                  case 1: return 'Once' 
                  case 2: return 'Doce' 
                  case 3: return 'Trece' 
                  case 4: return 'Catorce' 
                  case 5: return 'Quince'
                  default: return "Dieci" + unidades(unidad);
              }
          case 2: 
              switch(unidad){
                  case 0: return 'Veinte';
                  default: return 'Veinti' + unidades(unidad);
              }
          case 3: return (unidad>0) ? `Treinta y ${unidades(unidad)}`:'Treinta';
          case 4: return (unidad>0) ? `Cuarenta y ${unidades(unidad)}`:'Cuarenta';
          case 5: return (unidad>0) ? `Cincuenta y ${unidades(unidad)}`:'Cincuenta';
          case 6: return (unidad>0) ? `Sesenta y ${unidades(unidad)}`:'Sesenta';
          case 7: return (unidad>0) ? `Setenta y ${unidades(unidad)}`:'Setenta';
          case 8: return (unidad>0) ? `Ochenta y ${unidades(unidad)}`:'Ochenta';
          case 9: return (unidad>0) ? `Noventa y ${unidades(unidad)}`:'Noventa';
      }

  }

  const unidades = (num) =>{
      // console.log("unidades:",num);
      switch(num) {
          case 0: return '';
          case 1: return 'Un';
          case 2: return 'Dos';
          case 3: return 'Tres';
          case 4: return 'Cuatro';
          case 5: return 'Cinco';
          case 6: return 'Seis';
          case 7: return 'Siete';
          case 8: return 'Ocho';
          case 9: return 'Nueve';
      }

  }

  const separadorMiles = (num) =>{
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = num.toString().split('.');
      arr[0] = arr[0].replace(exp,rep);
      // return arr[1] ? arr.join('.'): arr[0];			
      return arr[0];			
  }
  
  const procesadorEnteros = (num) =>{
      let separado = separadorMiles(num).split(',')				
      // console.log(separadorMiles(num));
      separado.reverse().forEach((el, index) =>{
          // console.log(el);
          // console.log(centenas(Number.parseInt(el)));
          // console.log(temp[index])
          if (el == 1 && temp[index] === "Millones"){
              salidaEntero.push("Millón")
          } else if (el == '000' && temp[index] === "Mil"){
              // no hace nada
          } else {
              salidaEntero.push(temp[index])
          } 				
          salidaEntero.push(centenas(Number.parseInt(el)))
      })
      // console.log(salidaEntero);
  }

  const procesadorDecimales = (num) =>{	
      const centavos = centenas(Number.parseInt(num));
      if (centavos.length > 0 ){
          // console.log("centavos:",centavos);
          salidaCentavos = ` ${cents[0]} ${centavos} ${cents[1]}`;
      }				
  }

  const salida = () =>{
      const stringSalida = (salidaEntero.reverse().join(' ') + salidaCentavos).replace(/\s+/g, ' ');
      switch(format){					
          case 1: return stringSalida.toLowerCase();
          case 2: return stringSalida.toUpperCase();
          case 3: return stringSalida.charAt(0).toUpperCase() + stringSalida.substring(1).toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          case 4: return stringSalida.toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          // case 4: return stringSalida.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
          default: return stringSalida;
      }
  }
  
  procesadorEnteros(x)
  procesadorDecimales(y)
  return salida()
}
const numeroALetrasPorcentaje = (n) =>{
  //***** CONFIGURACIÓN ****
  // UDS - Pesos MX - Pesos colombianos etc. determina la etiqueta de la moneda
  const moneda = ""; 
  // puedes usar "con" o "y" Chiquipesos - centavos etc.
  const cents  = ["punto", "por ciento"]; 
  // 1 = Todo minúsculas, 2 = Todo Mayúsculas, 3 = Title case moneda Mayúscula, 
  // 4 = Todo minúculas y moneda Mayúscula, 0 = Camel Case 
  const format = 1
  
  // Variables
  const temp = [moneda , 'Mil', 'Millones', 'Mil'];		
  const tem = Number.parseFloat(n).toFixed(2).split('.');
  const x = Number.parseInt(tem[0]);
  const y = Number.parseInt(tem[1]);
  let salidaEntero = [];
  let salidaCentavos = ''
  
  const centenas = (num) =>{
      const centena = Math.floor(num / 100);
      const decena = num - (Math.floor(centena * 100));				
      // console.log("centena:",centena,"decena", decena);
      switch(centena){
          case 0: return '' + decenas(decena);
          case 1: 
              if (decena === 0){
                  return 'Cien';					
              } else{
                  return 'Ciento ' + decenas(decena);
              }
          case 2: return 'Doscientos ' + decenas(decena);				
          case 3: return 'Trecientos ' + decenas(decena);				
          case 4: return 'Cuatrocientos ' + decenas(decena);					
          case 5: return 'Quinientos ' + decenas(decena);					
          case 6: return 'Seiscientos ' + decenas(decena);					
          case 7: return 'Setecientos ' + decenas(decena);					
          case 8: return 'Ochocientos ' + decenas(decena);					
          case 9: return 'Novecientos ' + decenas(decena);					
      }
  }

  const decenas = (num) =>{
      let decena = Math.floor(num /10);
      let unidad = num - (decena * 10);
      // console.log("decenas:",decena,"unidades:", unidad);
      switch(decena) {
          case 0: return '' + unidades(unidad);
          case 1: 
              switch(unidad){
                  case 0: return 'Diez' 
                  case 1: return 'Once' 
                  case 2: return 'Doce' 
                  case 3: return 'Trece' 
                  case 4: return 'Catorce' 
                  case 5: return 'Quince'
                  default: return "Dieci" + unidades(unidad);
              }
          case 2: 
              switch(unidad){
                  case 0: return 'Veinte';
                  default: return 'Veinti' + unidades(unidad);
              }
          case 3: return (unidad>0) ? `Treinta y ${unidades(unidad)}`:'Treinta';
          case 4: return (unidad>0) ? `Cuarenta y ${unidades(unidad)}`:'Cuarenta';
          case 5: return (unidad>0) ? `Cincuenta y ${unidades(unidad)}`:'Cincuenta';
          case 6: return (unidad>0) ? `Sesenta y ${unidades(unidad)}`:'Sesenta';
          case 7: return (unidad>0) ? `Setenta y ${unidades(unidad)}`:'Setenta';
          case 8: return (unidad>0) ? `Ochenta y ${unidades(unidad)}`:'Ochenta';
          case 9: return (unidad>0) ? `Noventa y ${unidades(unidad)}`:'Noventa';
      }

  }

  const unidades = (num) =>{
      // console.log("unidades:",num);
      switch(num) {
          case 0: return '';
          case 1: return 'Uno';
          case 2: return 'Dos';
          case 3: return 'Tres';
          case 4: return 'Cuatro';
          case 5: return 'Cinco';
          case 6: return 'Seis';
          case 7: return 'Siete';
          case 8: return 'Ocho';
          case 9: return 'Nueve';
      }

  }

  const separadorMiles = (num) =>{
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = num.toString().split('.');
      arr[0] = arr[0].replace(exp,rep);
      // return arr[1] ? arr.join('.'): arr[0];			
      return arr[0];			
  }
  
  const procesadorEnteros = (num) =>{
      let separado = separadorMiles(num).split(',')				
      // console.log(separadorMiles(num));
      separado.reverse().forEach((el, index) =>{
          // console.log(el);
          // console.log(centenas(Number.parseInt(el)));
          // console.log(temp[index])
          if (el == 1 && temp[index] === "Millones"){
              salidaEntero.push("Millón")
          } else if (el == '000' && temp[index] === "Mil"){
              // no hace nada
          } else {
              salidaEntero.push(temp[index])
          } 				
          salidaEntero.push(centenas(Number.parseInt(el)))
      })
      // console.log(salidaEntero);
  }

  const procesadorDecimales = (num) =>{	
      const centavos = centenas(Number.parseInt(num));
      if (centavos.length > 0 ){
          // console.log("centavos:",centavos);
          salidaCentavos = ` ${cents[0]} ${centavos} ${cents[1]}`;
      }				
  }

  const salida = () =>{
      const stringSalida = (salidaEntero.reverse().join(' ') + salidaCentavos).replace(/\s+/g, ' ');
      switch(format){					
          case 1: return stringSalida.toLowerCase();
          case 2: return stringSalida.toUpperCase();
          case 3: return stringSalida.charAt(0).toUpperCase() + stringSalida.substring(1).toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          case 4: return stringSalida.toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          // case 4: return stringSalida.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
          default: return stringSalida;
      }
  }
  
  procesadorEnteros(x)
  procesadorDecimales(y)
  return salida()
}