<!-- <template>
    <div class="container d-flex justify-content-center">
        <div v-if="ready" class="grafico m-5">
            <Bar  id="my-chart-id" :options="chartOptions" :data="chartData" />

            
            
        </div>
        {{ ready }}
        {{ this.productoResultado }}
            {{ this.productosNombres }} 
    </div>


</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    props: { 
        productosNombres: [],
        productoResultado: []
     },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [{
                    label: 'Cantidad de créditos por producto',
                    backgroundColor: '#FFC477',
                    // data: [999, 600, 170, 620]
                    data: []
                    
                }]
            },
            chartOptions: {
                responsive: true
            },
            ready: false

        }
    },
    mounted(){
console.log( "Tama;o: " ,this.productosNombres.lenght); 

        if(this.productosNombres.lenght > 0 ){
            this.ready = true
        }
    },
    watch: {
        productosNombres(prod) {
            // this.ready = true
            console.log("Cambio", prod);
        },
    }

}
</script>

<style>
.grafico {
    width: 90%;
}
</style> -->

<template>
    <Bar :data="chartData" :options="chartOptions" />

    <!-- {{ this.productoResultado[1] }} -->
</template>

<script>
// DataPage.vue
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        chartOptions: {
            type: Object,
            default: () => { }
        }
    },
    beforeMount() {
        console.log(this.productosNombres);

        // this.chartData.datasets.data = this.productoResultado
    },
    computed: {
        // chartData() { return {
        //         labels: this.productosNombres,
        //         datasets: [{
        //             label: 'Cantidad de créditos por producto',
        //             backgroundColor: '#FFC477',
        //             // data: [999, 600, 170, 620]
        //             data: this.productoResultado

        //         }]
        //     } },
        // chartOptions() {
        //     return {
        //         responsive: true
        //     }
        // }
    }
}
</script>