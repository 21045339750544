<template>
  
  <login-app />

</template>

<script>
import LoginApp from './components/Login-App.vue'
import Security from './components/security.js';




export default {
  name: 'App',
  components: {
    LoginApp,
  },
  data(){
    return{
      componentKey: 0,

    }
  },
  
  methods:{
    forceUpdate() {
      this.componentKey += 1;
    },
  },
  computed: {
    verficarLogin(){
      if (this.logged && Security.checkTokenValid) {
        return true
      }else return false
    }
  }

}

</script>

<style>

</style>
