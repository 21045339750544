import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportacionFianza = (solicitud, fechaVencimiento) => {
  let nombreIntegrante;
  let dniIntegrante;
  let rtnIntegrante;
  let infoBeneficiario;
  let segundoBeneficiario;
  let noIntegrantes = 0;

  for (let i = 0; i < 5; i++) {
    switch (i) {
      case 0:
        nombreIntegrante = solicitud.integrantesGruposSolidario.nombre1;
        dniIntegrante = solicitud.integrantesGruposSolidario.int1;
        rtnIntegrante = solicitud.integrantesGruposSolidario.rtn1;
        infoBeneficiario =
          solicitud.integrantesGruposSolidario.infoBeneficiario1;

        break;
      case 1:
        nombreIntegrante = solicitud.integrantesGruposSolidario.nombre2;
        dniIntegrante = solicitud.integrantesGruposSolidario.int2;
        rtnIntegrante = solicitud.integrantesGruposSolidario.rtn2;
        infoBeneficiario =
          solicitud.integrantesGruposSolidario.infoBeneficiario2;

        break;
      case 2:
        nombreIntegrante = solicitud.integrantesGruposSolidario.nombre3;
        dniIntegrante = solicitud.integrantesGruposSolidario.int3;
        rtnIntegrante = solicitud.integrantesGruposSolidario.rtn3;
        infoBeneficiario =
          solicitud.integrantesGruposSolidario.infoBeneficiario3;

        break;
      case 3:
        nombreIntegrante = solicitud.integrantesGruposSolidario.nombre4;
        dniIntegrante = solicitud.integrantesGruposSolidario.int4;
        rtnIntegrante = solicitud.integrantesGruposSolidario.rtn4;
        infoBeneficiario =
          solicitud.integrantesGruposSolidario.infoBeneficiario4;

        break;
      case 4:
        nombreIntegrante = solicitud.integrantesGruposSolidario.nombre5;
        dniIntegrante = solicitud.integrantesGruposSolidario.int5;
        rtnIntegrante = solicitud.integrantesGruposSolidario.rtn5;
        infoBeneficiario =
          solicitud.integrantesGruposSolidario.infoBeneficiario5;

        break;
    }
    if (nombreIntegrante != undefined && nombreIntegrante != "") {
      if (dniIntegrante != solicitud.cliente.dni) {
        noIntegrantes++;
        segundoBeneficiario = infoBeneficiario;
      }
    }
  }

  let altura = 35;
  const margenIzquierdo = 30; // Margen izquierdo personalizado
  const anchoParrafo = 150; // Ancho máximo del párrafo

  const diaVencimiento = fechaVencimiento.toString().slice(8, 10);
  const añoVencimiento = fechaVencimiento.toString().slice(0, 2);
  const mesVencimiento = fechaVencimiento.toString().slice(5, 7);
  console.log(
    diaVencimiento,
    añoVencimiento,
    mesVencimiento,
    " PROBANDO LA FECHA"
  );

  if (
    solicitud.producto.toString().trim().toLowerCase() === "credi trabajo 2"
  ) {
    console.log("TRUE");

    // let interes = (solicitud.monto) * ((solicitud.interes/100)/12) * solicitud.plazoPago
    // const data = JSON.stringify(solicitud)
    const doc = new jsPDF("p", "");

    doc.lineHeightProportion = 2;

    const img = new Image();
    doc.addImage(
      (img.src = require("@/assets/headerAvales.png")),
      "png",
      0,
      0,
      doc.internal.pageSize.width,
      30
    );
    const footer = `Col. Rubén Darío, Casa 0592 Tegucigalpa, Honduras
  RTN:08269016867779
  Email: info@ahdecohn.org
  Telefono:2242-5780
  `;

    // Titulo Pagare
    // let year = new Date().getFullYear().toString().slice(-2)
    const widthParaph = 180;

    doc.setFont("helvetica", "bold");
    // Titulos
    doc.setFontSize(14);

    doc.setFontSize(11);

    //  **************************
    // Configuración de fuente y tamaño para el primer párrafo
    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.setLineHeightFactor(1.7);
    let parr2;
    if (noIntegrantes <= 2) {
      parr2 = `Yo ${solicitud.cliente.nombres} ${
        solicitud.cliente.apellidos != undefined
          ? solicitud.cliente.apellidos
          : ""
      }, Mayor de edad, Profesión u oficio Comerciante de nacionalidad hondureña con Documento Nacional De Identificación (DNI) No.${
        solicitud.cliente.dni
      }, con Registro Tributario Nacional (RTN) No.${
        solicitud.cliente.dni
      }. con domicilio en Colonia ${
        solicitud.cliente.colonia
      } en la ciudad de ________ con teléfono ${
        solicitud.cliente.celular
          ? solicitud.cliente.celular
          : solicitud.cliente.telefono
          ? solicitud.cliente.telefono
          : ""
      }, constituyo EXPRESAMENTE, en mi condición de AVAL SOLIDARIO, de el / la señor(a) ${
        segundoBeneficiario.nombres
      } ${
        segundoBeneficiario.apellidos != undefined
          ? segundoBeneficiario.apellidos
          : ""
      }, y como tal garantizo incondicionalmente el pago inmediato de la obligación principal, más los intereses convenidos a favor de la ASOCIACIÓN HONDUREÑA PARA EL DESARROLLO INTEGRAL COMUNITARIO (AHDECO); en el entendido, convenido y aceptado que renuncio terminantemente a mi domicilio y me someto a la Jurisdicción y Competencia del Juzgado que la ASOCIACIÓN HONDUREÑA PARA EL DESARROLLO INTEGRAL COMUNITARIO (AHDECO) designe; así mismo, renuncio a las diligencias de Protesto o Avisos de cualquier clase, como a cualquier requerimiento a que el prestatario tenga derecho. `;
    } else {
      parr2 = `Nosotros Los abajo firmantes mayores de edad nacionalidad hondureña, constituimos EXPRESAMENTE, en nuestra condición de AVALES SOLIDARIOS de el/la: ${
        solicitud.cliente.nombres
      } ${
        solicitud.cliente.apellidos != undefined
          ? solicitud.cliente.apellidos
          : ""
      }, y como tal garantizamos incondicionalmente el pago inmediato de la obligación principal, más los intereses convenido aceptado que renunciamos terminantemente a nuestro domicilio y nos sometemos a la Jurisdicción y Competencia del Juzgado que la ASOCIACION HONDUREÑA PARA EL DESARROLLO INTEGRAL COMUNITARIO ( AHDECO) designe; así mismo renunciamos a las diligencias de protesto o avisos de cualquier clase, como a cualquier requerimiento a que el prestatario tenga derecho. En fe de lo anterior se suscribe este documento en la ciudad de ___________, municipio______________ departamento de __________________ a los días ___ de ____________ del año _________                                                          
F_____________________`;
    }

    doc.text(parr2, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
    });
    // const widthParaph = 180;
    altura = espacioParrafos(doc, anchoParrafo, parr2, altura, 10);
    let parr17a = ``;
    let contador = 0;

    if (noIntegrantes <= 2) {
      parr17a = parrafo(
        solicitud.cliente.nombres+" "+(solicitud.cliente.apellidos != undefined
          ? solicitud.cliente.apellidos
          : ""
      ),
        solicitud.cliente.dni,
        solicitud.cliente.rtn,
        solicitud.cliente,
        noIntegrantes
      );
      doc.text(parr17a, margenIzquierdo, altura, {
        maxWidth: anchoParrafo,
        lineHeight: 2.5,
        fontWeight: "bold",
      });
    } else {
      for (let i = 0; i < 5; i++) {
        switch (i) {
          case 0:
            nombreIntegrante = solicitud.integrantesGruposSolidario.nombre1;
            dniIntegrante = solicitud.integrantesGruposSolidario.int1;
            rtnIntegrante = solicitud.integrantesGruposSolidario.rtn1;
            infoBeneficiario =
              solicitud.integrantesGruposSolidario.infoBeneficiario1;

            break;
          case 1:
            nombreIntegrante = solicitud.integrantesGruposSolidario.nombre2;
            dniIntegrante = solicitud.integrantesGruposSolidario.int2;
            rtnIntegrante = solicitud.integrantesGruposSolidario.rtn2;
            infoBeneficiario =
              solicitud.integrantesGruposSolidario.infoBeneficiario2;

            break;
          case 2:
            nombreIntegrante = solicitud.integrantesGruposSolidario.nombre3;
            dniIntegrante = solicitud.integrantesGruposSolidario.int3;
            rtnIntegrante = solicitud.integrantesGruposSolidario.rtn3;
            infoBeneficiario =
              solicitud.integrantesGruposSolidario.infoBeneficiario3;

            break;
          case 3:
            nombreIntegrante = solicitud.integrantesGruposSolidario.nombre4;
            dniIntegrante = solicitud.integrantesGruposSolidario.int4;
            rtnIntegrante = solicitud.integrantesGruposSolidario.rtn4;
            infoBeneficiario =
              solicitud.integrantesGruposSolidario.infoBeneficiario4;

            break;
          case 4:
            nombreIntegrante = solicitud.integrantesGruposSolidario.nombre5;
            dniIntegrante = solicitud.integrantesGruposSolidario.int5;
            rtnIntegrante = solicitud.integrantesGruposSolidario.rtn5;
            infoBeneficiario =
              solicitud.integrantesGruposSolidario.infoBeneficiario5;

            break;
        }
        if (nombreIntegrante != undefined && nombreIntegrante != "") {
          if (dniIntegrante != solicitud.cliente.dni) {
            if (contador == 2) {
              doc.setFont("helvetica", "normal");
              doc.setFontSize(8);
              doc.text(footer, 108, 279, {
                maxWidth: widthParaph,
                align: "center",
                lineHeight: 2.5,
                fontWeight: "bold",
              });
              doc.setFontSize(12);
            }
            if (i == 3) {
              doc.addPage();
              doc.addImage(
                (img.src = require("@/assets/headerAvales.png")),
                "png",
                0,
                0,
                doc.internal.pageSize.width,
                30
              );
              altura = 35;
              contador++;
            }

            parr17a = parrafo(
              nombreIntegrante,
              dniIntegrante,
              rtnIntegrante,
              infoBeneficiario,
              noIntegrantes
            );
            doc.text(parr17a, margenIzquierdo, altura, {
              maxWidth: anchoParrafo,
              lineHeight: 2.5,
              fontWeight: "bold",
            });

            contador++;
            altura = espacioParrafos(doc, anchoParrafo, parr17a, altura, 5);
          }
        }
      }
    }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(footer, 108, 279, {
      maxWidth: widthParaph,
      align: "center",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFontSize(12);

    doc.save(`AVALES_SOLIDARIOS_${solicitud.cliente.nombres}.pdf`);
  } else {
    alert("Contrato No Disponible");
  }
};

function espacioParrafos(doc, anchoParrafo, parrafo, yparr, espacio) {
  // Obtener las dimensiones del texto
  let dim = doc.getTextDimensions(parrafo, {
    maxWidth: anchoParrafo,
  });

  let altura = dim.h + yparr + espacio;

  return altura;
}

// function formatoNumero(numero) {
//   return new Intl.NumberFormat("en-US", {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   }).format(numero);
// }

function parrafo(
  nombreIntegrante,
  dniIntegrante,
  rtnIntegrante,
  infoBeneficiario
) {
  let parrafo = `NOMBRE:  ${nombreIntegrante}
INDENTIDAD:  ${dniIntegrante}
(RTN): ${rtnIntegrante != undefined ? rtnIntegrante : ""}
PROFECION U OFICIO: ${infoBeneficiario?.actividadEconomica}
DIRECCION COLONIA O BARRIO:  ${infoBeneficiario?.colonia}
PUNTO DE REFERENCIA:  ${infoBeneficiario?.puntoReferencia}
F_____________________`;

  return parrafo;
}
