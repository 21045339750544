<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <form-tag @ClienteEditEvent="submitHandler" name="clienteForm" event="clienteEditEvent" id="cliente-form">
                <div class="row mb-3 d-flex justify-content-center align-item-center">
                  <div class="col-3 d-flex align-items-center" style="height: 90%">
                    <button v-if="this.idSolicitud <= 0" type="button" @click="previousPage()"
                      class="btn btn-dark text-center ti-angle-left">
                      Volver
                    </button>
                    <router-link v-else class="btn btn-dark text-center ti-angle-left"
                      to="/mostrar-solicitudes-aceptadas">Volver</router-link>

                  </div>

                  <div class="col-5 text-center ">
                    <h4 v-if="validarGrupo" style="font-size: 20px" class="ms-2 mt-3 mb-4">
                      {{ noIntegregante }} Integrante
                    </h4>
                    <h4 v-else style="font-size: 20px" class="ms-2 mt-3 mb-4">
                      Nueva Solicitud de Crédito
                    </h4>
                  </div>
                  <div class="col-4 " style="height: 90%">
                    <input v-if="this.idSolicitud <= 0" type="submit" class="btn btn-dark" value="Siguiente Página" />
                    <div
                      class="btnOptionSolicitud col-12 d-flex flex-wrap justify-content-center gap-3 align-items-center"
                      v-else>
                      <button v-if="this.solicitud.estado === 'Aprobado'" @click="handleImprimir()" type="button"
                        class="btn btn-success">
                        Imprimir
                      </button>
                      <button v-if="this.solicitud.estado === 'Desembolsado'" @click="handleImprimir()" type="button"
                        class="btn btn-success">
                        Imprimir
                      </button>

                      <button v-if="this.solicitud.estado === 'Aprobado'" data-bs-toggle="modal"
                        data-bs-target="#desembolsarEstado" aria-expanded="true" aria-controls="desembolsarEstado"
                        type="button" class="btn btn-primary">
                        Desembolsar
                      </button>

                      <div class="d-flex justify-content-center gap-3 align-items-center">
                        <button type="button" v-if="this.solicitud.estado === 'Pendiente'" class="btn btn-warning"
                          disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Aprobado'" class="btn btn-success"
                          disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Rechazado'" class="btn btn-danger"
                          disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Desembolsado'" class="btn btn-dark"
                          disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                      </div>
                    </div>



                  </div>

                </div>


                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <!-- Aqui va datos Solicitante -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne">
                        <div class="col-6">Datos Solicitante</div>
                        <div id="validar-1" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <div class="row">
                          <div v-if="validarMiPyme && this.idSolicitud <= 0" class="col-4">
                            <p>Busca por DNI</p>
                            <input v-model="dni" @input="formatInput" placeholder="Ejemplo: 0801-1998-02195"
                              class="form-control" style="height: 20px" type="text" minlength="15" maxlength="15"
                              id="dni" required />
                            <router-link class="nav-link w-100" to="/solicitante/0">Crear Nuevo
                              Beneficiario</router-link>
                          </div>
                          <div v-else class="col-4 d-flex justify-content-center align-item-center"></div>
                          <div v-if="this.cliente" class="col-12">
                            <div class="row mt-3 mb-3">
                              <div class="col-sm-8">
                               <strong>Nombre: </strong>  {{ this.cliente.nombres }} {{ this.cliente.apellidos }}
                              </div>
                              <div class="col-sm-4"> <strong>DNI: </strong> {{ this.cliente.dni }}</div>
                            </div>
                            <div class="row mt-3 mb-3">
                              <div class="col-sm-4">
                                <strong>No Teléfono: </strong>{{ this.cliente.celular }}
                              </div>
                              <div class="col-sm-4"><strong>Email: </strong>{{ this.cliente.email }}</div>
                              <div class="col-sm-4">
                                <strong>No Teléfono Negocio: </strong> {{ this.cliente.telefono }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <!-- Aqui va condiciones Credito -->


                      </div>
                    </div>
                  </div>
                  <!-- Aqui va condiciones Credito -->

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwoMore">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwoMore" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwoMore">
                        <div class="col-6">
                          Condiciones del Crédito
                        </div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%">
                          {{ this.solicitud.producto }}
                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwoMore" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwoMore">
                      <div class="accordion-body">

                        <!-- Plan pagos -->
                        <div id="contenedor-plan">
                          <div class="header">
                            <h3>Solicitud de préstamo</h3>
                          </div>

                          <div name="frmPrestamo" id="frmPrestamo">
                            <div v-if="creditrabajo2()" class="control d-flex flex-column">
                              <label class="mb-2"><strong>Monto Credito:</strong> {{
                                this.formatoNumero(this.solicitud.monto) }} {{
                                  this.moneda }}</label>

                              <label for="interes"> <strong> Interés (anual):</strong> {{ this.solicitud.interes }}
                                % ({{ this.formatoNumero(this.montoInteres) }} {{ this.moneda }})</label>
                              <label for="interes"> <strong> Interés (mensual):</strong> {{ this.solicitud.interes / 12 }}
                                % </label>
                              <label for="interes"> <strong> Comisión por desembolso: </strong> {{
                                this.comisionDesembolso }} % ({{ this.formatoNumero(this.totalComisionDesembolso) }} {{
                                  this.moneda
                                }})</label>
                              <label for="interes"><strong>Comisión por asistencia técnica: </strong>{{
                                this.comisionAsistenciaTecnica }} % ({{
                                  this.formatoNumero(this.totalComisionAsistenciaTecnica) }} {{
                                  this.moneda }})</label>
                              <label for="interes"><strong>Tasa de seguridad: </strong> {{ this.tasaSeguridad }}
                                % ({{ this.formatoNumero(this.totalTasaSeguridad) }} {{ this.moneda }})</label>

                            </div>
                            <div class="control">
                              <label for="fecha">Fecha: </label>
                              <input type="date" required="true" class="form-control mt-2 mb-2"
                                v-model="solicitud.fechaPago" name="fecha" id="fecha" placeholder="fecha">
                            </div>
                            <div v-if="creditrabajo2()" class="control">

                              <label for="monto">Monto a Desembolsar: </label>
                              <!-- <input type="number"  class="form-control" v-model="solicitud.monto"
                                name="monto" id="monto" placeholder="monto" min="500.00" max="300000.00"> -->
                              <input type="text" required="true" class="form-control" v-model="montoDesembolsar"
                                name="montoDesembolsar" id="montoDesembolsar" placeholder="monto"
                                pattern="\d+(\.\d{1,2})?" min="500" max="300000" @blur="onBlur">

                            </div>
                            <div v-else class="control">

                              <label for="monto">Monto Aprobado: </label>
                              <!-- <input type="number"  class="form-control" v-model="solicitud.monto"
                                name="monto" id="monto" placeholder="monto" min="500.00" max="300000.00"> -->
                              <input type="text" required="true" class="form-control" v-model="solicitud.monto"
                                name="monto" id="monto" placeholder="monto" pattern="\d+(\.\d{1,2})?" min="500"
                                max="300000">
                            </div>

                            <div class="control">
                              <label for="periodo">Periodo de pago:</label>

                              <select v-if="creditrabajo2()" v-model="solicitud.frecuenciaPago" required="true"
                                class="form-control" name="periodo" id="periodo" disabled>
                                <option value="semanal">semanal</option>

                              </select>
                              <select v-else v-model="solicitud.frecuenciaPago" required="true" class="form-control"
                                name="periodo" id="periodo">
                                <option value="semanal">semanal</option>
                                <option value="quincenal">quincenal</option>
                                <option value="mensual">mensual</option>
                                <option value="al vencimiento">Al Vencimiento</option>
                                <option value="trimestral">trimestral</option>
                                <option value="semestral">semestral</option>
                                <option value="anual">anual</option>
                              </select>
                            </div>

                            <div v-if="!(creditrabajo2())" class="control">
                              <label for="interes">Interés (anual): </label>
                              <input type="number" class="form-control" v-model="solicitud.interes" required="true"
                                name="interes" id="interes" placeholder="interés" min="5" max="100" step=".01">
                            </div>

                            <!-- Plazo -->

                            <div v-if="creditrabajo2()" class="control">
                              <label for="plazo">Plazo: (Mensual) </label>
                              <input type="number" class="form-control" placeholder="Plazo Pago" required="true"
                                name="plazo" v-model="solicitud.plazoPago" id="plazo" min="1" max="6" step="1">
                              <p v-if="creditrabajo2() && (solicitud.plazoPago < 1 || solicitud.plazoPago > 6)"
                                class="text-danger">El plazo debe estar entre el rango de 1 a 6 meses</p>
                            </div>

                            <div
                              v-else-if="this.solicitud.frecuenciaPago == 'trimestral' || this.solicitud.frecuenciaPago == 'semestral' || this.solicitud.frecuenciaPago == 'anual'"
                              class="control">
                              <label for="periodo">Periodo de pago:</label>
                              <select v-model="solicitud.plazoPago" required="true" class="form-control" name="plazo"
                                id="plzao">
                                <option value="12">12 Meses</option>
                                <option value="24">24 Meses</option>
                                <option value="36">36 Meses</option>
                                <option value="48">48 Meses</option>
                                <option value="60">60 Meses</option>
                                <option value="72">72 Meses</option>
                              </select>
                            </div>
                            <div v-else class="control">
                              <label for="plazo">Plazo: (Mensual) </label>
                              <input type="number" class="form-control" placeholder="Plazo Pago" required="true"
                                name="plazo" v-model="solicitud.plazoPago" id="plazo" min="1" step="1">
                            </div>

                            <!-- Button trigger modal -->
                            <button type="button" class="btn btn-primary" @click="ejecutarPlanPagos()"
                              data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                              Ver Plan de pago
                            </button>

                          </div>
                        </div>

                        <!-- Modal -->

                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                          tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-scrollable modal-xl">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Plan de Pagos</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                  aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <div id="contenedorTabla">
                                  <div class="header">
                                    <h2>Tabla de amortizaciones</h2>
                                  </div>
                                  <table class="table table-striped table-hover">

                                    <thead class="table-dark">
                                      <tr>
                                        <th scope="col">No. Cuota</th>
                                        <th scope="col">Fecha de Pago</th>
                                        <th scope="col">Cuota Total</th>
                                        <th scope="col">Interés</th>
                                        <th scope="col">Capital</th>
                                        <th scope="col">Saldo</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tablaPlanPagos">

                                    </tbody>
                                  </table>
                                  <div id="amortizaciones">

                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingSummaryTable">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSummaryTable" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseSummaryTable">
                        <div class="col-6">
                          Resumen del Crédito
                        </div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%">

                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseSummaryTable" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingSummaryTable">
                      <div class="accordion-body">
                        <div class="container" id="content">
                          <hr>
                          <table class="datos-table">

                            <tr>
                              <td> <b>Nombre:</b> </td>
                              <td>{{ this.cliente?.nombres }} {{ this.cliente?.apellidos }}</td>
                              <td><b>ID Beneficiario:</b> </td>
                              <td>{{ this.cliente?.dni }}</td>
                            </tr>
                            <tr>
                              <td><b>Producto:</b> </td>
                              <td>{{ this.solicitud?.producto }}</td>
                              <td><b>Numero de préstamo:</b> </td>
                              <td>{{ this.solicitud?.id }}</td>
                            </tr>
                            <tr>
                              <td> <b> IFI: </b> </td>
                              <td>{{ this.cliente?.nombreBanco }}</td>
                              <td><b>Cuenta depósito:</b> </td>
                              <td>{{ this.cliente?.noCuentaBanco }}</td>
                            </tr>
                          </table>
                          <hr />
                          <table class="datos-prestamo-table custom-table">
                            <tr>
                              <td><b>Fecha Apertura:</b></td>
                              <td colspan="1">{{ new Date(this.solicitud?.fechaPago).toLocaleDateString('en-GB') }}</td>
                              <td><b>Monto Aprobado:</b></td>
                              <td>{{ (this.solicitud?.monto).toLocaleString('en-US') }}</td>

                              <td></td>
                            </tr>
                            <tr>
                              <td><b>Tasa Interes:</b></td>
                              <td>{{ this.solicitud?.interes }}%</td>
                              <td><b>Plazo (meses):</b></td>
                              <td>{{ this.solicitud?.plazoPago }}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><b>Forma de pago:</b></td>
                              <td>Mensual</td>
                              <td><b>Moneda:</b></td>
                              <td>Lempiras</td>
                              <td></td>
                            </tr>
                            <tr v-if="this.solicitud.producto === 'Credi Trabajo'">
                              <td><b> Total Interes: </b></td>
                              <td>{{ (this.solicitud?.monto * ((this.solicitud?.interes / 12) /
                                100) * this.solicitud.plazoPago).toLocaleString('en-US', minimumFractionDigits = 2,
                                  maximumFractionDigits = 2) }}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <!-- <tr>
                              <td colspan="1"><b>Comision por desembolso:</b></td>
                              <td>{{ this.comisionDesembolso }}%</td>
                              <td>{{ (this.totalComisionDesembolso).toLocaleString('en-US') }}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="1"><b>Comision por asistencia técnica:</b></td>
                              <td>{{ this.comisionAsistenciaTecnica }}%</td>
                              <td>{{ (this.totalComisionAsistenciaTecnica).toLocaleString('en-US') }}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="1"><b>Total Intereses Pagado:</b></td>
                              <td></td>
                              <td>{{ ((this.totalComisionDesembolso +
                                this.totalComisionAsistenciaTecnica).toFixed(2)).toLocaleString('en-US') }}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="1"><b>Tasa de Seguridad:</b></td>
                              <td>{{ this.tasaSeguridad }}%</td>
                              <td>{{ this.totalTasaSeguridad }}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="1"><b>Frecuencia de pagos:</b></td>
                              <td>{{ this.solicitud.frecuenciaPago }}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="1"><b>Monto neto a desembolsar al cliente:</b></td>
                              <td>{{ (this.montoNeto).toLocaleString('en-US') }}</td>
                              <td></td>
                              <td></td>
                            </tr> -->
                            <tr>
                              <td colspan="1"><b>Tipo de cálculo:</b></td>
                              <td>Cuota Nivelada</td>
                              <td><b>Periodo Base:</b></td>
                              <td>360</td>
                              <td></td>
                            </tr>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </form-tag>
              <div class="modal fade" id="desembolsarEstado" tabindex="-1" aria-labelledby="desembolsarEstado"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="desembolsarEstado">Desembolsar</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <DesembolsarSolicitud :idSolicitud="this.idSolicitud" :monto="this.solicitud.monto" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
    <!-- partial:../../partials/_footer.html -->
    <footer class="footer">
      <div class="d-sm-flex justify-content-center justify-content-sm-between">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright ©
          AHDECO 2023</span>

      </div>
    </footer>
    <!-- partial -->
  </div>
</template>

<script>
import Security from "../security";
import FormTag from "@/components/forms/Form-Tag.vue";
import { solicitudCompleta } from "../solicitud";
import router from "../../router/index.js";
import Swal from "sweetalert2";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DesembolsarSolicitud from '../forms/DesembolsarSolicitud.vue'
// import PlanPagos from '../plan-pagos.vue';



export default {
  name: "NuevaSolicitud",
  components: {
    "form-tag": FormTag,
    DesembolsarSolicitud: DesembolsarSolicitud,
    // "plan-pagos": PlanPagos,
  },
  data() {
    return {
      //DATA PARA COMISIONES PARA LA IMPRESION
      comisionDesembolso: 2.50,
      totalComisionDesembolso: 0.00,
      comisionAsistenciaTecnica: 3.50,
      totalComisionAsistenciaTecnica: 0.0,
      tasaSeguridad: 0.40,
      totalTasaSeguridad: 0.0,
      montoNeto: 0,
      planPagoToPrint: [],
      montoInteres: 0.0,
      idSolicitud: 0,
      validarAcordion: "",
      moneda: 'lps',
      solicitudCompleta,
      fechaNacimiento: "",
      edadsoli: "",
      estadoCivil: "",
      final: false,
      noReferencias: 1,
      dni: "",
      cliente: {},
      gruposSolidariosArray: [],
      solicitud: {
        ID: 0,
        clienteId: 0,
        producto: "",
        destinoCreditos: [],
        tiempoVivienda: "",
        materialVivienda: "",
        noPersonasVivivenda: "",
        noFamilias: "",
        noTrabajan: "",
        serviciosPublico: [],
        noDependientes: "",
        noBuscanEmpleo: "",
        planInversion: {
          req1: "",
          req2: "",
          req3: "",
          req4: "",
          req5: "",
          desc1: "",
          desc2: "",
          desc3: "",
          desc4: "",
          desc5: "",
          val1: 0,
          val2: 0,
          val3: 0,
          val4: 0,
          val5: 0,
        },
        referencias: {
          nom1: "",
          nom2: "",
          nom3: "",
          nom4: "",
          par1: "",
          par2: "",
          par3: "",
          par4: "",
          dir1: "",
          dir2: "",
          dir3: "",
          dir4: "",
          cel1: 0,
          cel2: 0,
          cel3: 0,
          cel4: 0,
        },
        sector: [],
        gruposEspeciales: [],
        idGrupoSolidario: 0,

        fechaPago: "",
        moneda: "lps",
        monto: '',
        frecuenciaPago: "",
        interes: '',
        tipoPlazo: "mensual",
        plazoPago: '',
        garantia: '',
        estado: ''
      },
    };
  },
  beforeMount() {

    this.idSolicitud = this.$route.params.solicitudId
    // Obtener GrupoSolidario por edit is id > 0
    if (this.idSolicitud > 0) {
      //editing a solicitante
      fetch(
        process.env.VUE_APP_API_URL +
        "/admin/solicitud/" +
        this.$route.params.solicitudId,
        Security.requestOptions("")
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit("error", data.message);
          } else {
            // this.Grupo = data.data;
            console.log('Datos Grupo Solidario:', data.data);
            let currentSol = data.data.find((sol) => sol.id == this.$route.params.solicitudId)
            this.solicitud = currentSol
            this.gruposSolidariosArray = data.data
            this.solicitud.fechaPago = currentSol.fechaPago
            this.solicitud.frecuenciaPago = currentSol.frecuenciaPago.toLowerCase()
            this.cliente = currentSol.cliente
            // solicitudCompleta.nombreGrupoSolidario = data.data.nombreGrupoSolidario
            // solicitudCompleta.integrantes = data.data.clientes
            // this.dni = data.data.dni;
            // this.cliente.estado = data.data.estado;
            // this.comisionAsistenciaTecnica = 3.50
            // this.comisionDesembolso = 2.50
            // this.tasaSeguridad = 0.40
            this.montoDesembolsar = this.solicitud.montoDesembolsar
            

            this.totalComisionDesembolso = this.solicitud.monto * (this.comisionDesembolso / 100)
            this.totalComisionAsistenciaTecnica = this.solicitud.monto * (this.comisionAsistenciaTecnica / 100)
            this.totalTasaSeguridad = this.solicitud.monto * (this.tasaSeguridad / 100)
            this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
          }
        });
    } else if (solicitudCompleta.producto == "") {
      router.push("/solicitud");
      location.reload();
      window.location.href = "/solicitud";
    }
    // if (this.validarMiPyme) {
    //   this.solicitudCompleta.integranteSolicitud+2
    // }

  },

  mounted() {
    Security.requireToken();


    this.totalComisionDesembolso = this.solicitud.monto * (this.comisionDesembolso / 100)
    this.totalComisionAsistenciaTecnica = this.solicitud.monto * (this.comisionAsistenciaTecnica / 100)
    this.totalTasaSeguridad = this.solicitud.monto * (this.tasaSeguridad / 100)
    this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
    // if (!this.final) {
    //   Security.secureReload();
    // }
    //Agregar Solicitante
    // if(this.solicitudCompleta.producto != 'MIPYME'){
    //   // this.cliente = solicitudCompleta.integranteSolicitud[solicitudCompleta.integranteSolicitud]

    //   // console.log(this.cliente);
    // }
    // console.log(this.solicitudCompleta.producto);
    // console.log(this.solicitudCompleta.integrantes[this.solicitudCompleta.integranteSolicitud]);
    this.cliente =
      this.solicitudCompleta.integrantes[
      this.solicitudCompleta.integranteSolicitud
      ];


  },
  methods: {
    formatoNumero(numero) {
      return new Intl.NumberFormat("en-US").format(numero)
    },
    creditrabajo2() {
      return this.solicitud.producto === 'Credi Trabajo 2'

    },
    dateFormat(param) {
      // const date = new Date(param)

      // const dia = (param)?.slice(8, 10)
      // const mes = date.toLocaleDateString('es-ES', { month: 'long' })
      // const anio = date.getFullYear()
      // const dateFormated = `${dia}/${mes.slice(0, 3)}/${anio}`
      // return dateFormated
      // Fecha en formato ISO 8601
      // Fecha en formato ISO 8601
      var fechaISO = param;


      // Crear un objeto Date con la fecha proporcionada
      var fecha = new Date(fechaISO);

      // Obtener el día del mes (agregando un cero inicial si es necesario)
      var dia = ('0' + fecha.getUTCDate()).slice(-2);

      // Obtener el mes (agregando un cero inicial si es necesario)
      var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

      // Obtener el año
      var anio = fecha.getUTCFullYear();

      // Formatear la fecha según tus necesidades
      var fechaFormateada = dia + '/' + mes + '/' + anio;

      console.log(fechaFormateada); // Salida: 01/12/2023

      return fechaFormateada;

    },
    onBlur() {
      //Funcion para calcular el monto del credito
      if (this.montoDesembolsar > 0) {
        if (this.solicitud.plazoPago > 0 && this.solicitud.plazoPago < 7) {

          this.totalComisionDesembolso = (4.5 / 100) * this.montoDesembolsar
          this.totalComisionAsistenciaTecnica = (4.0 / 100) * this.montoDesembolsar
          this.totalTasaSeguridad = (0.4 / 100) * this.montoDesembolsar
          this.montoInteres = (((this.montoDesembolsar * (5 / 100)) * this.solicitud.plazoPago) - (this.totalComisionAsistenciaTecnica + this.totalComisionDesembolso))
          // this.solicitud.monto = this.montoDesembolsar + this.montoInteres
          this.totalComisionDesembolso = parseInt(this.totalComisionDesembolso, 10).toFixed(2)
          this.totalComisionAsistenciaTecnica = parseInt(this.totalComisionAsistenciaTecnica, 10).toFixed(2)
          // Convertir los valores a enteros antes de sumarlos
          const montoDesembolsar = parseInt(this.montoDesembolsar, 10);
          const montoInteres = parseInt(this.montoInteres, 10);
          const totalComisionDesembolso = parseInt(this.totalComisionDesembolso, 10);
          const totalTasaSeguridad = parseInt(this.totalTasaSeguridad, 10);
          const totalComisionAsistenciaTecnica = parseInt(this.totalComisionAsistenciaTecnica, 10);

          // Asignar la suma a solicitud.monto
          this.solicitud.monto = (montoDesembolsar + montoInteres + totalComisionDesembolso + totalTasaSeguridad + totalComisionAsistenciaTecnica).toFixed(2);

          console.log("El campo de interés perdió el foco. Valor actual: " + this.totalComisionDesembolso);

        }
      }
      // Lógica adicional que deseas ejecutar cuando se sale del input
    },
    //Imprimir despues de aprobado
    handleImprimir() {
      const doc = new jsPDF()

      const text = 'PLAN DE PAGO';
      const textWidth = doc.getTextWidth(text);

      // Calcular la posición para centrar el texto
      const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      const y = 10;

      // Colocar el texto centrado
      doc.text(text, centerX, y);

      doc.setFont('courier')

      const tableData = [];
      const tableInfoPrestamo = [];
      document.querySelectorAll('.datos-table tr').forEach((row) => {
        const rowData = [];
        // Iterar sobre las celdas de cada fila
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });

        tableData.push(rowData);
      });

      document.querySelectorAll('.datos-prestamo-table tr').forEach((row) => {
        const rowData = [];
        // Iterar sobre las celdas de cada fila
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });

        tableInfoPrestamo.push(rowData);
      });
      this.ejecutarPlanPagos()

      // Crear la tabla en el PDF
      doc.autoTable({
        body: tableData,
        theme: 'striped', // Puedes cambiar el tema según tus preferencias
        styles: {
          halign: 'center',
        },
      });
      doc.autoTable({
        body: tableInfoPrestamo,
        theme: 'striped', // Puedes cambiar el tema según tus preferencias
        styles: {
          halign: 'left',
        },
      });
      doc.autoTable({
        head: [['No. Cuota', 'Fecha de Pago', 'Cuota Total', 'Interes', 'Capital', 'Saldo']],
        headStyles: { fillColor: [41, 128, 185] },
        body: this.planPagoToPrint.map(plan => [plan.noCuota, plan.fechaPago, plan.cuotaTotal, plan.interes, plan.capital, plan.saldo]),
        theme: 'grid',
        styles: {
          halign: 'center',
        },
      })

      // Guardar el PDF
      doc.save(`${(this.cliente.nombres).trim()}${this.solicitud?.fechaPago}Solicitud.pdf`);
    },



    //Generar Plan de Pagos
    ejecutarPlanPagos() {
      const tablaBody = document.getElementById('tablaPlanPagos')
      console.log(this.solicitud.fechaPago, this.solicitud.monto, this.solicitud.frecuenciaPago, this.solicitud.interes, this.solicitud.plazoPago)
      if (this.solicitud.fechaPago === '' || this.monto === '' || this.solicitud.frecuenciaPago === '' || this.solicitud.interes === '' || this.solicitud.plazoPago === '') {
        tablaBody.innerHTML = `
          <tr>
            <td colspan=6 class="text-center text-danger">
              <h3>
                ¡Faltan datos!</td>  
              </h3>
          </tr>
        `
      } else {
        this.generarPlanPago(this.stringToDate(this.solicitud.fechaPago), this.solicitud.monto, this.solicitud.frecuenciaPago, this.solicitud.interes, this.solicitud.plazoPago)
      }
    },

    generarPlanPago(
      fechaApertura,
      monto,
      periodoPago,
      interesAnual,
      plazo
    ) {
      let fechaInicial = fechaApertura;
      // Calcular la tasa de interés mensual
      let tasaAnual = interesAnual / 100;
      console.log("tasa anual:", tasaAnual);

      // Calcular el intervalo de tiempo entre pagos
      let duracionPago, periodo, noCuotas, interes, diasPeriodo, capital, saldo;

      // Imprimir la cabecera de la tabla
      console.log(
        "No. Cuota",
        "Fecha de Pago",
        "Cuota Total",
        "Interés",
        "Capital",
        "Saldo"
      );
      // Generar el plan de pago
      saldo = monto;
      console.log(
        0,
        fechaApertura.toISOString().slice(0, 10),
        0.0,
        0.0,
        0.0,
        saldo
      );

      switch (this.limpiarYMinusc(periodoPago)) {
        case "mensual":
          duracionPago = 30 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 30 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo;
          periodo = 12;
          diasPeriodo = 30.0;
          break;

        case "quincenal":
          duracionPago = 14 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 15 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo * 2;
          periodo = 24;
          diasPeriodo = 15.0;
          break;

        case "semanal":
          duracionPago = 7 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
          );
          if (this.esCrediTrabajo(this.solicitudCompleta.producto)) {
            noCuotas = plazo * 4;
          } else {
            noCuotas = plazo * (4.3333333333);
          }

          periodo = 52.1;
          diasPeriodo = 7.0;
          break;
        case "alvencimiento":
          duracionPago = 1;
          fechaApertura = new Date(
            fechaApertura.getTime() + (plazo * 30) * 24 * 60 * 60 * 1000
          );
          noCuotas = 1;
          periodo = (30 / (plazo * 30)) * 12;
          diasPeriodo = plazo * 30;
          break;

        case "trimestral":
          duracionPago = 90 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 90 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo / 3;
          periodo = 4;
          diasPeriodo = 90.0;
          break;
        case "semestral":
          duracionPago = 180 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 180 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo / 6;
          periodo = 2;
          diasPeriodo = 180.0;
          break;
        case "anual":
          duracionPago = 360 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 360 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo / 12;
          periodo = 1;
          diasPeriodo = 360.0;
          break;

        default:
          console.log("Periodo de pago no válido");
          return;
      }
      console.log(fechaApertura)
      // Generar el plan de pago
      let cuotaTotal = this.CalculaCuotaNivelada(
        monto,
        tasaAnual,
        plazo,
        periodo,
        diasPeriodo
      );
      this.planPagoToPrint = []
      const tablaBody = document.getElementById('tablaPlanPagos')
      tablaBody.innerHTML = ''
      tablaBody.innerHTML = `
        <tr>
          <th class="text-center align-middle" scope="row">0</td>
          <td class="text-center align-middle">${this.dateFormat(fechaInicial.toISOString().slice(0, 10))}</td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
        </tr>
      `
      console.log(fechaApertura)
      this.planPagoToPrint.push({
        noCuota: 0,
        fechaPago: fechaInicial.toISOString().slice(0, 10),
        cuotaTotal: '',
        interes: '',
        capital: '',
        saldo: saldo
      })
      if (this.solicitud.producto !== 'Credi Trabajo' && this.solicitud.producto !== 'Credi Trabajo 2') {

        for (let i = 1; i <= Math.round(noCuotas); i++) {
          interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
          capital = cuotaTotal - interes;
          saldo = this.convertirANoNegativo(saldo - capital);
          // Imprimir la fila de la tabla
          tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${this.dateFormat(fechaApertura.toISOString().slice(0, 10))}</td>
            <td class="text-center align-middle">${Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
          </tr>
          `
          this.planPagoToPrint.push({
            noCuota: i,
            fechaPago: fechaApertura.toISOString().slice(0, 10),
            cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            interes: Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            saldo: this.convertirANoNegativo(Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
          })
          fechaApertura = new Date(fechaApertura.getTime() + duracionPago);

        }
      } else {
        if(this.creditrabajo2){
          noCuotas = Math.round(noCuotas)
        }

        cuotaTotal = monto / noCuotas;
        for (let i = 1; i <= Math.round(noCuotas); i++) {
          interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
          capital = cuotaTotal;
          saldo = saldo - capital;
          // Imprimir la fila de la tabla
          tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${this.dateFormat(fechaApertura.toISOString().slice(0, 10))}</td>
            <td class="text-center align-middle">${cuotaTotal.toFixed(2)} ${this.moneda}</td>
            <td class="text-center align-middle"></td>
            <td class="text-center align-middle">${capital.toFixed(2)} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            </tr>
            `
          this.planPagoToPrint.push({
            noCuota: i,
            fechaPago: fechaApertura.toISOString().slice(0, 10),
            cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            interes: '',
            capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          })

          fechaApertura = new Date(fechaApertura.getTime() + duracionPago);
        }
      }

    },
    //Convertir string a minuscula y sin espacios
    limpiarYMinusc(string) {
      // Eliminar espacios en blanco y convertir a minúsculas
      return string.replace(/\s/g, '').toLowerCase();
    },
    //Convertir numero negativo a 0
    convertirANoNegativo(numero) {
      // Si el número es negativo, convertirlo a cero
      if (numero < 0) {
        return 0;
      } else {
        return numero;
      }
    },
    esCrediTrabajo(producto) {
      // Convertir producto a minúsculas para normalizar
      producto = producto.trim().toLowerCase();

      // Lista de valores normalizados
      const creditrabajo = [
        "creditrabajo",
        "credi trabajo",
        "credi trabajo ",
        "credi trabajo",
        "Credi Trabajo",
        "creditrabajo ",
        "creditrabajo ",
        "credi trabajo",
        "creditrabajo",
      ];

      // Verificar si el producto está en la lista de valores normalizados
      return creditrabajo.includes(producto);
    },
    // CalculaCuotaNivelada calcula la cuota nivelada de un préstamo
    CalculaCuotaNivelada(
      montoPrestamo,
      tasaInteres,
      periodos,
      periodo,
      diasPeriodo
    ) {
      if (periodo === 24) {
        periodos *= 2;
      } else if (periodo === 52.1) {
        periodos *= 4.33;
        periodos = Math.round(periodos);
      } else if (periodo === (30 / (periodos * 30)) * 12) {
        periodos = 1
      } else if (periodo === 4) {
        periodos /= 3;
        // periodos = Math.round(periodos);
      } else if (periodo === 2) {
        periodos /= 6;
        // periodos = Math.round(periodos);
      } else if (periodo === 1) {
        periodos /= 12;
        // periodos = Math.round(periodos);
      }


      let tasaMensual = (tasaInteres / 360) * diasPeriodo;
      tasaMensual = Math.round(tasaMensual * 10000000000) / 10000000000;

      let cuota =
        (montoPrestamo * tasaMensual) / (1 - Math.pow(1 + tasaMensual, -periodos));
      return cuota;
    },

    stringToDate(dateString) {
      // Cadena con formato mm/dd/yyyy
      // Diseño que coincide con el formato de la cadena
      let date = new Date(dateString.split("/").reverse().join("-"));
      return date;
    },

    //Enviar Datos
    submitHandler() {
      console.log(this.solicitud)
      //

      this.solicitudCompleta.integranteSolicitud++;

      if (this.solicitudCompleta.solicitudesObject.length >= this.solicitudCompleta.integranteSolicitud) {
        //Se aumenta el no de integrante que se encuentra por segunda vez
        console.log();

        this.solicitud = this.restaurarSolicitud()
        this.cliente = this.solicitudCompleta.integrantes[this.solicitudCompleta.integranteSolicitud];
        console.log('Ya se guardo');

      } else {

        //Se aumenta el no de integrante que se encuentra por segunda vez
        // this.solicitudCompleta.integranteSolicitud++;
        //Agregamos El producto a la solicitud
        this.solicitud.producto = this.solicitudCompleta.producto
        //Agregamos el Id del cliente a la solicitud
        this.solicitud.clienteId = this.cliente.id;
        //Agregamos el id del Grupo solidario a la solicitud 
        this.solicitud.idGrupoSolidario = this.solicitudCompleta.idGrupoSolidario
        //Convertimos en Enteros y en Float
        this.solicitud.monto = parseFloat(this.solicitud.monto, 10)
        this.solicitud.interes = parseInt(this.solicitud.interes, 10)

        //Agrego solicitud a Payload y la solicitud a solicitudes
        let payload = this.solicitud;
        this.solicitudCompleta.solicitudesObject.push(this.solicitud)
        payload = {
          ...payload,
          idUsuario: Security.getUser()[0].user.id,
        }

        ////////////////////////////
        //Si el integrante por el que va es igual total de integrantes del grupo solidario
        if (
          this.solicitudCompleta.integranteSolicitud ===
          this.solicitudCompleta.integrantes.length
        ) {
          this.solicitudCompleta.integranteSolicitud--;
          Swal.fire({
            title: '¿Estás Seguro de que deseas agregar la solicitud?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Creala!'
          }).then((result) => {
            if (result.isConfirmed) {

              //Agregar Solicitudes
              this.solicitudCompleta.integranteSolicitud = 0;


              console.log('Se imprimira el payload', payload);
              fetch(`${process.env.VUE_APP_API_URL}/admin/solicitudes/save`, Security.requestOptions(payload))
                .then((response) => response.json())
                .then((data) => {
                  if (data.error) {
                    this.$emit('error', data.message)
                    Swal.fire({
                      // position: 'top-end',
                      icon: "error",
                      title: data.message,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    //Agregar id de solicitud
                    solicitudCompleta.solicitudes.push(data.data.id)
                    //Info Correcta
                    Swal.fire({
                      // position: 'top-end',
                      icon: "success",
                      title: "Se ha Guardado Correctamente la Solicitud",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                })
                .catch((error) => {
                  this.$emit('error', error);
                })

              this.solicitudCompleta.integrantes = []
              this.solicitudCompleta.producto = ""
              this.solicitudCompleta.idGrupoSolidario = 0
              this.solicitudCompleta.dniIntegrantes = 0
              this.solicitudCompleta.nombreGrupoSolidario = ""
              this.solicitudCompleta.integranteSolicitud = 0
              this.solicitudCompleta.solicitudes = []
              this.solicitudCompleta.solicitudesObject = []



              Swal.fire({
                // position: 'top-end',
                icon: "success",
                title: "Se ha terminado de crear solicitud",
                showConfirmButton: false,
                timer: 1500,
              });
              //Reestablecer Página
              window.onbeforeunload = null; // Desactiva la confirmación de navegación
              this.final = true;
              router.push("/solicitud");
              location.reload();
              window.location.href = "/solicitud";
            }
          })

          /////////////////////////////////////
          //Else Caso contrario que el numero no sea igual al total de intergrantes
        } else {
          //Se guardan los datos del integrate por el que va segun el index que es integranteSolicitud
          this.cliente = this.solicitudCompleta.integrantes[this.solicitudCompleta.integranteSolicitud];
          console.log(payload)
          //Peticion Fetch
          Swal.fire({
            title: '¿Estás Seguro de que deseas modificar la solicitud?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Guardar Cambios!'
          }).then((result) => {
            if (result.isConfirmed) {
              fetch(`${process.env.VUE_APP_API_URL}/admin/solicitudes/save`, Security.requestOptions(payload))
                .then((response) => response.json())
                .then((data) => {
                  if (data.error) {
                    //Mensaje Alerta Error
                    Swal.fire({
                      icon: "error",
                      title: data.message,
                      showConfirmButton: false,
                      timer: 1500,
                    });

                  } else {

                    //Agregar id de solicitud
                    solicitudCompleta.solicitudes.push(data.data.id)

                    //Alerta de que guardo correctamente
                    Swal.fire({
                      icon: "success",
                      title: "Se ha Guardado Correctamente la Solicitud",
                      showConfirmButton: false,
                      timer: 1500,
                    });

                  }
                })
                .catch((error) => {
                  this.$emit('error', error);
                })

              //Se usa funcion que no quita la validación de Seguridad que se perderan los datos  
              this.quitarValidacion()
              this.solicitud = this.restaurarSolicitud()
              //Se resetea el Formulario
              document.getElementById("cliente-form").reset();
              location.reload()
            }
          })
        }
      }
    },


    //Recibir Datos de Otros componentes
    recibirDatosDelHijo(datos) {
      this.cliente = datos; // Manejar los datos recibidos del hijo
      //No funciona
    },


    //Navegar entre Páginas

    //NextPage: Funcion siguiente pagina NO FUNCIONAL POSIBLE BORRAR
    nextPage() {
      console.log('Usted se encuentra en la pagina', this.solicitudCompleta.integranteSolicitud);

      if (this.solicitudCompleta.solicitud[this.solicitudCompleta.integranteSolicitud]) {
        console.log();
      } else {
        this.submitHandler();
      }
      //Se llama la funcion Submit Handler para subir la solicitud



      //Este es cuando es uno que no sea mipyme
      if (
        this.solicitudCompleta.integrantes.length >
        this.solicitudCompleta.integranteSolicitud + 1
      ) {
        console.log('Se llama a la funcion');
        this.solicitudCompleta.integranteSolicitud++;

        //Guardar solicitud de integrante

        //Validar Solicitud
      }
    },

    previousPage() {
      console.log(this.solicitudCompleta.integranteSolicitud);

      if (this.solicitudCompleta.integranteSolicitud > 0) {
        //Ir para atras en las solicitudes

        this.solicitudCompleta.integranteSolicitud--;
        this.cliente =
          this.solicitudCompleta.integrantes[
          this.solicitudCompleta.integranteSolicitud
          ];
        this.solicitud =
          this.solicitudCompleta.solicitudesObject[
          this.solicitudCompleta.integranteSolicitud
          ]

        // console.log('El Id anterior:', this.solicitudCompleta.solicitudes[this.solicitudCompleta.integranteSolicitud].id);
        // router.push(`/solicitud/integrante/${this.solicitudCompleta.solicitudes[this.solicitudCompleta.integranteSolicitud].id}`);

      } else if (this.solicitudCompleta.producto !== "Escalonado") {
        router.push("/solicitud");
      } else {
        router.push("/solicitud/grupo-solidario/0");
      }
    },

    formatInput() {
      // Elimina todos los guiones existentes en el valor actual
      const cleanedValue = this.dni.replace(/-/g, "");

      // Aplica el formato con guiones
      let formattedValue = "";
      for (let i = 0; i < cleanedValue.length; i++) {
        if (i === 4 || i === 8) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      // Actualiza el valor del input
      this.dni = formattedValue;
    },
    async quitarValidacion() {
      await new Promise(resolve => setTimeout(resolve, 2000));
      console.log("Se llama a la uncion");
      const inputElement = document.querySelector('.form-control');

      // Elimina la clase CSS que agrega los estilos no deseados
      inputElement.classList.remove('was-validated');
      inputElement.classList.remove('is-invalid');

      // Sobrescribe los estilos en línea para eliminar los estilos no deseados
      inputElement.style.border = 'none';
      inputElement.style.paddingRight = 'initial';
      inputElement.style.backgroundImage = 'none';
      inputElement.style.backgroundRepeat = 'initial';
      inputElement.style.backgroundPosition = 'initial';
      inputElement.style.backgroundSize = 'initial';
    },

    restaurarSolicitud() {
      const solicitud = {
        ID: 0,
        clienteId: 0,
        producto: "",
        destinoCreditos: [],
        tiempoVivienda: "",
        materialVivienda: "",
        noPersonasVivivenda: "",
        noFamilias: "",
        noTrabajan: "",
        serviciosPublico: [],
        noDependientes: "",
        noBuscanEmpleo: "",
        planInversion: {
          req1: "",
          req2: "",
          req3: "",
          req4: "",
          req5: "",
          desc1: "",
          desc2: "",
          desc3: "",
          desc4: "",
          desc5: "",
          val1: 0,
          val2: 0,
          val3: 0,
          val4: 0,
          val5: 0,
        },
        referencias: {
          nom1: "",
          nom2: "",
          nom3: "",
          nom4: "",
          par1: "",
          par2: "",
          par3: "",
          par4: "",
          dir1: "",
          dir2: "",
          dir3: "",
          dir4: "",
          cel1: 0,
          cel2: 0,
          cel3: 0,
          cel4: 0,
        },
        sector: [],
        gruposEspeciales: [],
        idGrupoSolidario: 0,

        fechaPago: "",
        monto: 0,
        frecuenciaPago: "",
        interes: 12,
        tipoPlazo: "",
        plazoPago: 0,
        estado: 'pendiente'
      };

      return solicitud;
    },
    handleDesembolsar() {
      const payload = {
        id: parseInt(this.idSolicitud),
        estado: 'Desembolsado',
        idUsuario: Security.getUser()[0].user.id,
      }

      console.log(payload)
      Swal.fire({
        title: '¿Estás Seguro que desea desembolsar?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('confirmado')
          fetch(process.env.VUE_APP_API_URL +
            "/admin/solicitud/update-estado", Security.requestOptions(payload))
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                this.$emit('error', data.message)
                Swal.fire({
                  // position: 'top-end',
                  icon: "error",
                  title: data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                //Info Correcta
                Swal.fire({
                  icon: "success",
                  title: "Se ha Guardado Correctamente el estado Solicitud",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((error) => {
              this.$emit('error', error);
            })

          location.reload()
        }
      })
    },
  },
  computed: {
    noIntegregante() {
      switch (this.solicitudCompleta.integranteSolicitud) {
        case 0:
          return "Primer";
        case 1:
          return "Segundo";
        case 2:
          return "Tercer";
        case 3:
          return "Cuarto";
        case 4:
          return "Quinto";
        default:
          return "Número fuera de rango";
      }
    },
    validarGrupo() {
      if (this.solicitudCompleta.producto === `Escalonado`) {
        return true;
      } else return false;
    },
    validarMiPyme() {
      if (this.solicitudCompleta.producto !== `Escalonado`) {
        return true;
      } else return false;
    },

  },
  watch: {
    // comisionDesembolso: function (comisionDesembolso) {
    //   this.totalComisionDesembolso = this.solicitud.monto * (comisionDesembolso / 100)
    //   this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
    // },
    // comisionAsistenciaTecnica: function (comisionAsistenciaTecnica) {
    //   this.totalComisionAsistenciaTecnica = this.solicitud.monto * (comisionAsistenciaTecnica / 100)
    //   this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
    // },
    // tasaSeguridad: function (tasaSeguridad) {
    //   this.totalTasaSeguridad = this.solicitud.monto * (tasaSeguridad / 100)
    //   this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
    // },
    dni(dni) {
      if (Array.from(dni).length == 15) {
        fetch(
          `${process.env.VUE_APP_API_URL}/admin/clientes/dni/${dni}`,
          Security.requestOptions("")
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              console.log(data.message);
              this.$emit("error", data.message);
            } else {
              console.log("Info obtenida", data);
              this.cliente = data.data;
              this.solicitudCompleta.integrantes.push(data.data)
              this.$emit("datos-hijo-al-padre", data.data);
            }
          })
          .catch((error) => {
            this.$emit("error", error);
          });
      } else {
        this.cliente = "";
        this.solicitudCompleta.integrantes.pop()
        // this.mensajeDni = ""
        // dni = document.getElementById('dni');
        // dni.classList.remove('is-invalid')
        // dni.classList.add('was-validated')
      }
    },

    'solicitud.estado': function (estado) {
      if (estado) {
        const formulario = document.getElementById('cliente-form');
        const inputs = formulario.querySelectorAll('input')
        const selects = formulario.querySelectorAll('select')
        const inputsComision = formulario.querySelectorAll('.comision')
        inputs.forEach((input) => {
          input.disabled = true;
        })
        inputsComision.forEach(input => {
          input.disabled = false;
        })
        selects.forEach(select => {
          select.disabled = true;
        })
        document.getElementById('fecha').disabled = false
        if (estado === 'Desembolsado') {
          document.getElementById('fecha').disabled = true
          console.log('Desembolsada ', this.idSolicitud)
          fetch(process.env.VUE_APP_API_URL + `/admin/fecha-desembolso/${this.idSolicitud}`, Security.requestOptions({}))
            .then((response) => response.json())
            .then((response) => {
              if (response.error) {
                this.$emit('error', response.message);
                console.error(response.message)
              } else {
                console.log('Credito:', response)

                // let date = new Date(response.data)



                // ...

                this.fechaPago = response.data.slice(0, 10);
                this.solicitud.fechaPago = response.data.slice(0, 10);

                console.log('response', this.fechaPago)
              }
            })
            .catch(error => {
              this.$emit('error', error)
            })
        }
      }
    },
    'solicitud.plazoPago': function (plazo) {
      console.log(plazo);
      this.onBlur()
      switch (plazo) {
        case 1:
          this.solicitud.interes = 12
          this.comisionDesembolso = 4.27
          this.comisionAsistenciaTecnica = 3.80
          this.tasaSeguridad = 0.40
          break;
        case 2:
          this.solicitud.interes = 8.16
          this.comisionDesembolso = 4.08
          this.comisionAsistenciaTecnica = 3.62
          this.tasaSeguridad = 0.40
          break;
        case 3:
          this.solicitud.interes = 22.56
          this.comisionDesembolso = 3.9
          this.comisionAsistenciaTecnica = 3.47
          this.tasaSeguridad = 0.40
          break;
        case 4:
          this.solicitud.interes = 28.68
          this.comisionDesembolso = 3.74
          this.comisionAsistenciaTecnica = 3.32
          this.tasaSeguridad = 0.40
          break;
        case 5:
          this.solicitud.interes = 31.56
          this.comisionDesembolso = 3.59
          this.comisionAsistenciaTecnica = 3.19
          this.tasaSeguridad = 0.40
          break;
        case 6:
          this.solicitud.interes = 33
          this.comisionDesembolso = 3.9
          this.comisionAsistenciaTecnica = 3.47
          this.tasaSeguridad = 0.40
          break;
        default:
          this.solicitud.interes = 12
          this.comisionDesembolso = 4.27
          this.comisionAsistenciaTecnica = 3.80
          this.tasaSeguridad = 0.40
          break;
      }



    },
  }

};
</script>




<!-- Estilos tabla de amortizacion -->



<!-- Estilos formularios  -->
<style scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: royalblue;
}

.custom-table {}


.header,
thead {
  border-bottom: 1px solid #F0F0F0;
  background-color: #F7F7F7;
  padding: 20px 40px;
}

.header h2 {
  margin: 0;
}

#frmPrestamo {
  padding: 30px 40px;
}

#frmPrestamo .control,
#amortizaciones .control,
.radios {
  margin-bottom: 10px;
  padding-bottom: 20px;
  position: relative;
}

#frmPrestamo .control label {
  margin-bottom: 5px;
}

#frmPrestamo .control input,
#frmPrestamo .control select {
  border: 2px solid #F0F0F0;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

#frmPrestamo .control input:focus {
  outline: 0;
  border-color: royalblue;
}

#frmPrestamo button {
  background: rgba(65, 105, 225, 90%);
  border: 2px solid royalblue;
  border-radius: 4px;
  color: #FFF;
  display: block;
  font-family: inherit;
  font-size: medium;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}

.radios {
  padding: 10px 20px;
}

.radioContenedor {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  margin: 10px;
}

.radioContenedor input {
  display: none;
}

.card {


  border-radius: 12px;
  /* border-left-color: #4285f4; */
  border-top: 5px solid #ff910088;
  border-left: 5px solid #4286f467;
}

.radioContenedor .circle {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  position: absolute;
  display: inline-block;
  background-color: rgba(65, 105, 225, 90%);
  border-bottom: 2px solid royalblue;
}

.radioContenedor:hover .circle {
  background-color: royalblue;
}

.radioContenedor input:checked+.circle {
  background-color: royalblue;
}

.radioContenedor input:checked+.circle:after {
  content: '';
  height: 10px;
  width: 10px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>