<template>
    
            
            <input
                :type="type"
                :name="name"
                :placeholder="placeholder"
                :required="false"
                :min="min"
                :max="max"
                :value="modelValue"
                :autocomplete="name + '-new'"
                @input="$emit('update:modelValue', $event.target.value)"
                class="form-control">
            <div class="form-text">{{ help }}</div>
        
</template>

<script>
export default {
    name: 'Text-Input',
    props: {
        name: String,
        type: String,    
        placeholder: String,
        required: String,
        min: String,
        max: String,
        modelValue: String,
        help: String,
    },
}
</script>

<style>

</style>