<template>
    <div class="container">
        <div class="content">
            <h1>Registro de Actividades</h1>
            <div class="filtro">
                <!-- Filtro por recientes -->
                <button @click="filtrarPorRecientes" class="btn btn-success">Recientes</button>

                <!-- Filtro por tipo de movimiento -->
                <select v-model="filtroTipoMovimiento" class="form-select">
                    <option value="">Todos</option>
                    <option v-for="tipoMovimiento in tiposMovimiento" :key="tipoMovimiento" :value="tipoMovimiento">
                        {{ tipoMovimiento }}
                    </option>
                </select>
            </div>

            <div class="activity-control">
                <ul class="ulActivity-Contro">
                    <li v-for="registro in registrosFiltrados" :key="registro.id" class="activity-item">
                        <div>
                            <strong>{{ registro.descripcion }}</strong>
                        </div>
                        <div class="movimiento">
                            <small>
                                <strong>Tipo de Movimiento:</strong> {{ registro.tipoMovimiento }}
                            </small>
                        </div>
                        <div>
                            {{ registro.user?.first_name }} {{ registro.user?.last_name }} - {{ registro.created_at }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="footer_btn">
                <div>
                    <button class="btn prev " id="0" @click="handleIntervaloChange"><i class="ti-angle-left menu-icon"></i>
                        Anterior</button>
                    <button class="btn next" id="1" @click="handleIntervaloChange">Siguiente <i
                            class="ti-angle-right menu-icon"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Security from '../security';

export default {
    data() {
        return {
            intervalo: [0, 15],
            registros:
                [],
            filtroTipoMovimiento: "",
        };
    },

    computed: {
        tiposMovimiento() {
            // Obtener los tipos de movimiento únicos de los registros
            return [...new Set(this.registros?.map((registro) => registro.tipoMovimiento))];
        },
        registrosFiltrados() {
            // Aplicar los filtros seleccionados
            let registrosFiltrados = this.registros;

            if (this.filtroTipoMovimiento) {
                registrosFiltrados = registrosFiltrados.filter(
                    (registro) => registro.tipoMovimiento === this.filtroTipoMovimiento
                );
            }

            // Ordenar por fecha de creación descendente (recientes primero)
            registrosFiltrados?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            return registrosFiltrados;
        },
    },
    beforeMount() {
        if (this.registros) {
            let payload = {
                intervalo: this.intervalo,
            }
            console.log(payload)
            fetch(
                process.env.VUE_APP_API_URL +
                "/admin/registro-actividades/all",
                Security.requestOptions(payload)
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        this.$emit("error", data.message);
                    } else {
                        // this.Grupo = data.data;
                        console.log('Datos:', data.data.actividades);
                        this.registros = data.data.actividades;
                    }
                });
        }
    },
    mounted() {
        // Obtener los registros de la API
        // axios.get("/api/registros").then((response) => {
        //     this.registros = response.data;
        // });

        if (this.intervalo[0] === 0) {
            const prev = document.querySelector('.prev');
            prev?.classList.add('disabled');
        } else {
            const prev = document.querySelector('.prev');
            prev?.classList.remove('disabled');
        }
        if (this.intervalo[1] - 1 > this.registros?.length) {
            const next = document.querySelector('.next');
            next?.classList.add('disabled');
        } else if (this.intervalo[1] === this.registros?.length) {
            const next = document.querySelector('.next');
            next?.classList.remove('disabled');
        }
    },
    methods: {
        filtrarPorRecientes() {
            // Aplicar filtro por recientes
            this.filtroTipoMovimiento = "";
        },
        handleIntervaloChange(e) {
            console.log(this.intervalo)
            // Actualizar el intervalo de registros
            let intervalo = e.target.id
            if (intervalo === '1') {
                this.intervalo[0] += 15;
                this.intervalo[1] += 15;
            } else if (intervalo === '0') {
                this.intervalo[0] -= 15;
                this.intervalo[1] -= 15;
            }
            console.log(this.intervalo)
            let payload = {
                intervalo: this.intervalo,
            }
            fetch(
                process.env.VUE_APP_API_URL +
                "/admin/registro-actividades/all",
                Security.requestOptions(payload)
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        this.$emit("error", data.message);
                    } else {
                        // this.Grupo = data.data;
                        this.registros = data.data.actividades;
                    }
                });
        },
    },
    watch: {
        intervalo: function () {
            console.log('desde el watch')
            let payload = {
                intervalo: this.intervalo,
            }
            console.log(payload)
            fetch(
                process.env.VUE_APP_API_URL +
                "/admin/registro-actividades/all",
                Security.requestOptions(payload)
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        this.$emit("error", data.message);
                    } else {
                        // this.Grupo = data.data;
                        console.log('Datos:', data.data.actividades);
                        this.registros = data.data.actividades;
                    }
                });
        },
        registros: function () {
            if (this.intervalo[0] === 0) {
                console.log('entro')
                const prev = document.querySelector('.prev');
                prev?.classList.add('disabled');
            } else {
                console.log('entro')
                const prev = document.querySelector('.prev');
                prev?.classList.remove('disabled');
            }
            if (this.intervalo[1] - 1 > this.registros?.length) {
                console.log('entro')
                console.log(this.intervalo[1], this.registros?.length)
                const next = document.querySelector('.next');
                next?.classList.add('disabled');
            } else if (this.intervalo[1] === this.registros?.length) {
                console.log('entro')
                const next = document.querySelector('.next');
                next?.classList.remove('disabled');
            }
            console.log(this.registros?.length, this.intervalo[1])
        },
    }

};
</script>

<style scoped>
.btn {
    height: 40px;
    margin: 0px !important;
}

.filtro {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 50px;

    >h1 {
        font-family: Arial, sans-serif;
        font-size: 1.5rem;
        color: #1F2328;
        font-weight: bold;
    }
}

.ulActivity-Control {
    list-style: none;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 0px !important;
    width: 100%;

}

.activity-control {
    font-family: Arial, sans-serif;
    padding: 0px;
    border: 1.2px solid #D0D7DE;
    border-radius: 10px;
    width: 95%;
}


.activity-item {
    padding: 10px;
    border-bottom: 1px solid #D0D7DE;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.activity-item:hover {
    background-color: #F5F5F5;
}

.activity-item div {
    color: #656DA0;
    font-size: 0.85rem;
}

.activity-item strong {
    font-weight: bold;
    color: #1F2328;
}

.movimiento {
    place-self: flex-end;
    align-self: flex-end;
}

.footer_btn div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.prev {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
}


.next {
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
}

.next:hover {
    background-color: #fcfcfc;
    border: 1px solid #c2e1ff;
    color: #0b6ce3;
}

.prev:hover {
    background-color: #fcfcfc;
    border: 1px solid #c2e1ff;
    border-radius: 10px 0px 0px 10px;
    color: #0b6ce3;
}

.disabled {
    background-color: #fcfcfc !important;
    border: 1px solid #e6e6e6 !important;
    color: #676767 !important;
}

.hidden {
    display: none !important;
}
</style>
