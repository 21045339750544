<template>
    <div class="container p-4 horizontal-scrollable">
        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="">Gestión de usuarios</h1>
                    <router-link class="btn btn-primary" to="/ahdecoAdminSettings/0" style="color: white;">Nuevo Usuario</router-link>
                </div>
                <hr>

                <table v-if="this.ready" class="table table-compact table-striped">
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Correo</th>
                            <th>Activo</th>
                            <th>Estado</th>                            
                        </tr>

                    </thead>
                    <tbody>
                        <tr v-for="u in this.users" v-bind:key="u.id">
                            <td>
                                <router-link :to="`/ahdecoAdminSettings/${u.id}`">{{ u.last_name}}, {{ u.first_name }}</router-link>
                            </td>
                            <td>
                                {{ u.email }}
                            </td>

                            <td v-if="u.active === 1">
                                <span class="badge bg-success">Active</span>
                            </td>
                            <td v-else>
                                <span class="badge bg-danger">Inactive</span>
                            </td>

                            <td v-if="u.token.id > 0">
                                <a href="javascript:void(0);">
                                <span class="badge bg-success" @click="logUserOut(u.id)">Logged in</span>
                                </a>
                            </td>
                            <td v-else>
                                <span class="badge bg-danger">Not logged in</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p v-else> Loading... </p>

            </div>
        </div>
    </div>
</template>

<script>
import Security from "../security";
import notie from 'notie'
import { store } from "../store";


export default {
    data(){
        return {
            users: [],
            ready: true
        }
    },
    beforeMount(){
        Security.requireToken();

        fetch(process.env.VUE_APP_API_URL + "/admin/users", Security.requestOptions(""))
        .then((response) => response.json())
        .then((response) => {
            if (response.error) {
                // console.log(response.message, response.error);
                notie.alert({
                    type: "error",
                    text: response.message,
                })
            } else {
                this.users = response.data.users;
                this.ready = true;    
            }
        })
        .catch((error)=>{
            // console.log(error);
            notie.alert({
                type: "error",
                text: error,
                store,
            })
        });
    },
    methods: {
        logUserOut(id){
            if(id !== store.user.id){
                notie.confirm({
                    text: "Are you sure you want to log this user out?",
                    submitText: "Log Out",
                    submitCallback: () => {
                        console.log("Would log out user id", id);
                        fetch(process.env.VUE_APP_API_URL +"/admin/log-user-out/" + id, Security.requestOptions(""))
                        .then((response) => response.json())
                        .then((data) => {
                            if(data.error){
                                this.$emit('error', data.message);
                            } else {
                                this.$emit('success', data.message);
                                this.$emit('forceUpdate');
                            }
                        })
                    }
                })
            } else {
                this.$emit('error', "You can't log yourself out!");
            }
        }
    }
}
</script>

<style scoped>
  .horizontal-scrollable {
    overflow-x: auto;
    white-space: nowrap;
  }
  .badge{
    border-radius: 5px;
  }

  .btn{
    display: flex;
    align-items: center;
    margin: 10px;
    height: 80%;
  }
</style>