<template>
  <div v-if="this.ready == false" class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto carta">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="../../public/images/logo2.png" alt="logo" style="width:88%">
              </div>
              <h6 class="font-weight-light text-center">Inicia Sesión para continuar.</h6>

              <form-tag-login @my-event="submitHandler" name="myform" event="my-event" class="pt-3">
                <div class="form-group">
                  <text-input v-model="email" type="email" name="email" placeholder="Email" required="true">
                  </text-input>
                </div>
                <div class="form-group">
                  <text-input v-model="password" type="password" name="password" placeholder="Contraseña" required="true">
                  </text-input>
                </div>
                <div class="mt-3 text-center">
                  <!-- <a class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">SIGN IN</a> -->
                  <input type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    value="Iniciar Sesión">
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                  <!-- <div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input">
                      Keep me signed in
                    </label>
                  </div> -->
                  <!-- <a href="#" class="auth-link text-black">Forgot password?</a> -->
                </div>
              </form-tag-login>

            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>



  <div v-else class="container-scroller">
    <!-- Aqui va Nav -->
    <header-app />

    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <!-- partial:partials/_sidebar.html -->

      <NavbarLate />

      <!-- NuevoSolicitante -->
      <router-view v-slot="{ Component }" :key="componentKey"
        @forceUpdate="forceUpdate">
        <component :is="Component"></component>
      </router-view>

      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<script>

import { store } from './store.js'
import router from './../router/index.js'
import Security from './security.js'
import TextInput from './forms/Text-Input.vue'
// import notie from "notie"
import FormTagLogin from './forms/FormTagLogin.vue'
import DashboardApp from './../components/Body-App.vue';
import HeaderApp from './../components/Header-App.vue'
import NavbarLate from './../components/Navbar-Lateral.vue'
import Swal from "sweetalert2";

const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
}

export default {


  name: 'LoginApp',
  data() {
    return {
      email: "",
      password: "",
      store,
      componentKey: 0,
      ready: false,
    }
  },
  components: {
    TextInput,
    FormTagLogin,
    DashboardApp,
    HeaderApp,
    NavbarLate,

  },
  beforeMount() {
    // Check Existing token
    Security.checkToken
    // check for a cookie
    let data = getCookie("_site_data");

    if (data !== "") {

      // Security.requireToken();


      let cookieData = JSON.parse(data);

      //update store
      store.token = cookieData.token.token;
      store.user = {
        id: cookieData.user.id,
        first_name: cookieData.user.first_name,
        last_name: cookieData.user.last_name,
        email: cookieData.user.emailz,
      }
      console.log(`This is the name en APP ${cookieData.user.first_name}`);


      if (Security.checkTokenValid) {

        this.ready = true;
      } else this.ready = false;



    }
  },
  methods: {
    forceUpdate() {
      this.componentKey += 1;
    },
    // async submitHandler() {
    //   const payload = {
    //     email: this.email,
    //     password: this.password,
    //   }

    //   try {
    //     const response = await fetch(process.env.VUE_APP_API_URL + "/users/login", Security.requestOptions(payload));
    //     const responseData = await response.json();

    //     if (responseData.error) {
    //       console.log("Error", responseData.message);
    //       notie.alert({
    //         type: 'error',
    //         text: responseData.message + '🙁'
    //       });
    //     } else {
    //       console.log("Se obtuvo");
    //       console.log(responseData.data.user);
    //       console.log(responseData.data.token);
    //       store.token = responseData.data.token.token;

    //       store.user = {
    //         id: responseData.data.user.id,
    //         first_name: responseData.data.user.first_name,
    //         last_name: responseData.data.user.last_name,
    //         email: responseData.data.user.email,
    //       };


    //       console.log('Se comienza guarda Cookie');
    //       //save info to cookie
    //       let date = new Date();
    //       let expDays = 1;
    //       date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    //       const expires = "expires=" + date.toUTCString();

    //       // set the cookie 

    //       document.cookie = "_site_data="
    //         + JSON.stringify(responseData.data)
    //         + "; "
    //         + expires
    //         + "; path =/; SameSite=strict; Secure;"

    //       console.log('Se Finaliza guardar Cookie');

    //       this.ready = true;
    //       router.push("/");
    //       // location.reload();
    //     }
    //   } catch (error) {
    //     console.error("Error en la solicitud:", error);
    //   }
    // }
    submitHandler() {
            const payload = {
                email: this.email,
                password: this.password,
            }

            fetch(process.env.VUE_APP_API_URL + "/users/login", Security.requestOptions(payload))
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    
                  Swal.fire({
                // position: 'top-end',
                icon: "error",
                title: "Correo o Contraseña Incorrecta",
                showConfirmButton: false,
                timer: 1500,
              });

                } else {
                    store.token = response.data.token.token;

                    store.user = {
                        id: response.data.user.id,
                        first_name: response.data.user.first_name,
                        last_name: response.data.user.last_name,
                        email: response.data.user.email,
                        roles: response.data.user.roles,
                    }
                    store.roles = response.data.user.roles;
                    console.log(response.data.user.roles);
                    console.log(store.roles)
                    // save info to cookie
                    // let date = new Date();
                    // let expDays = 1;
                    // date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
                    // const expires = "expires=" + date.toUTCString();

                    // // set the cookie
                    // document.cookie = "_site_data="
                    // + JSON.stringify(response.data)
                    // + "; "
                    // + expires
                    // + "; path=/; SameSite=strict; Secure;"
                    // this.ready = true;
                    
                    // Establecer una cookie con nombre "_site_data" y valor "Juan" con una fecha de expiración de 5 minutos
                  const date = new Date();
                  date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000)); // 5 minutos en milisegundos
                  const expires = "expires=" + date.toUTCString();
                  document.cookie = `_site_data=${JSON.stringify(response.data)}; ` + expires + "; path=/";
                  document.cookie = `roles=${JSON.stringify(response.data.user.roles)}; ` + expires + "; path=/";
                  document.cookie = `sucursal=${JSON.stringify(response.data.user.sucursal)}; ` + expires + "; path=/";
                  router.push("/");
                  location.reload();
                }
            })
        }
  }

  // methods: {
  //   submitHandler() {
  //     const payload = {
  //       email: this.email,
  //       password: this.password,
  //     }


  //     fetch(process.env.VUE_APP_API_URL + "/users/login", Security.requestOptions(payload))
  //       .then((response) => response.json())
  //       .then((response) => {
  //         if (response.error) {
  //           console.log("Error", response.message);
  //           notie.alert({
  //             type: 'error',
  //             text: response.message + '🙁'
  //           })
  //         } else {
  //           console.log("Se obtuvo");
  //           store.token = response.data.token.token;

  //           store.user = {
  //             id: response.data.user.id,
  //             first_name: response.data.user.first_name,
  //             last_name: response.data.user.last_name,
  //             email: response.data.user.email,
  //           }


  //           //save info to cookie
  //           let date = new Date();
  //           let expDays = 1;
  //           date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  //           const expires = "expires=" + date.toUTCString();

  //           // set the cookie 

  //           document.cookie = "_site_data="
  //             + JSON.stringify(response.data)
  //             + "; "
  //             + expires
  //             + "; path =/; SameSite=strict; Secure;"


  //           this.ready = true

  //           router.push("/");
  //           // location.reload();

  //         }
  //       })


  //   },

  // }
}
</script>

<style scoped>
.carta {
  width: 400px;
}

.auth .auth-form-light {
  background: #ffffffd0;
  border-radius: 12px;
}

.content-wrapper {
  /* background: none; */
  /* background-image:url('./../assets/fondo.jpg'); */
  padding: 1.5rem 2.5rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;

}


.auth form .form-group .form-control {
  background: transparent;
  border-radius: 15px;
  font-size: .9375rem;
}
</style>