<template>
  <div class="container-scroller">
    <!-- Aqui va Nav -->
    <header-app />

    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <!-- partial:partials/_sidebar.html -->

      <NavbarLate />

      <!-- NuevoSolicitante -->
      <router-view v-slot="{ Component }" :key="componentKey" @success="success" @error="error" @warning="warning"
        @forceUpdate="forceUpdate">
        <component :is="Component"></component>
      </router-view>

      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
  <!-- container-scroller -->
</template>


<script>
import { store } from './store.js'
// import router from './../router/index.js'
// import Security from './security'
import notie from 'notie'
import HeaderApp from './Header-App.vue'
import NavbarLate from './Navbar-Lateral.vue'

export default {
  name: 'BodyApp',
  components: {
    HeaderApp,
    NavbarLate,
  },
  data() {
    return {
      store,
      componentKey: 0,
    }
  },
  mounted() {
    // Security.requireToken();
    // notie.alert({
    //         type: 'success',
    //         text: 'Changes',

    //         })
  },
  methods: {
    success(msg) {
      notie.alert({
        type: 'success',
        text: msg,
      })
    },
    error(msg) {
      notie.alert({
        type: 'error',
        text: msg,
      })
    },
    warning(msg) {
      notie.alert({
        type: 'warning',
        text: msg,
      })
    },
    forceUpdate() {
      this.componentKey += 1;
    },
  }
}
</script>