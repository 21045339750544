<template>
    <form 
        @submit.prevent="submit"
        :ref="name"
        :event="event"
        autocomplete="off" 
        :method="method" 
        :action="action" 
        :id="id"
        class="needs-validation" 
        novalidate>
        <slot></slot>
        
    </form>
</template>

<script>
export default {
    name: 'FormTag',
    props: ["method", "action", "name", 'event', 'id',],
    data(){
        return{
            validar: true,
        }
    },
    methods: {
        submit() {
            let myForm = this.$refs[this.$props.name]
            
            if (this.name === 'clienteForm') {
                if (myForm.elements.namedItem("dni")) {
                    this.comprobarAcordion('validar-1',['dni'])
                }else this.comprobarAcordion('validar-1',[])                
                this.comprobarAcordion('validar-2',['fecha','monto','periodo','interes','plazo'])
                //this.comprobarAcordion('validar-3',['emprenderCheckbox','maquinariaCheckbox','reabastecerCheckbox','otrasCheckbox'])
                this.comprobarAcordion('validar-3',['sucursal'])
                // this.comprobarAcordion('validar-4',['req1','desc1','val1','req2','desc2','val2','req3','desc3','val3','req4','desc4','val4','req5','desc5','val5'])
                this.comprobarAcordion('validar-5',['nom1','par1','dir1','cel1','nom2','par2','dir2','cel2','nom3','par3','dir3','cel3','nom4','par4','dir4','cel4'])
                this.comprobarAcordion('validar-6',['tiempoVivienda','materialVivienda','energiaElectrica','aguaPotable','aguasNegras','pozoSeptico', 'telefonoFijo', 'personasVivienda', 'familias','trabajan','dependientes','buscanEmpleo',])
                // this.comprobarAcordion('validar-7',[])
                this.comprobarAcordion('validar-8',['garantia'])
            }


            if (myForm.checkValidity()) {
                // console.log("My event name", this.$props['event']);
                // console.log("Name", this.$props.name);
                this.$emit(this.$props['event']); 
                this.borrar('validar-1')
                this.borrar('validar-2')
                this.borrar('validar-3')
                this.borrar('validar-4')
                this.borrar('validar-5')
                this.borrar('validar-6')
                this.borrar('validar-7')      
                this.borrar('validar-8')      
                myForm.classList.remove("was-validated");                 
            }
            myForm.classList.add("was-validated");
        },
        comprobarAcordion(vali, ele){
            let myForm = this.$refs[this.$props.name]
            let acord = document.getElementById(vali)

            this.validar = true

            ele.forEach(element => {

                if (!myForm.elements.namedItem(element).checkValidity()) {
                    this.validar = false
                }
            });

            if(!this.validar && acord){
                // <p style="color:red; margin-bottom:0px; margin-top:3.5px; margin-right:5px">Faltan Campos por llenar</p>
                acord.innerHTML = `  
                <img
                        src = "https://cdn.pixabay.com/photo/2021/06/24/17/57/cancel-sign-6361985_1280.png"
                        class="bounce"
                        alt="check"
                        style="width: 30px"
                    />
                
                `
            }else{
                acord.innerHTML = `
                <img
                        src = "https://upload.wikimedia.org/wikipedia/commons/c/c6/Sign-check-icon.png"
                        class="bounce"
                        alt="check"
                        style="width: 30px"
                    />
                
                `
            }
        },
        borrar(vali){
            let acord = document.getElementById(vali)       
                acord ? acord.innerHTML = `` : "" 
        }
    },
}
</script>