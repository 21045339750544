<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h3>Solicitud Nueva</h3>

              <div class="form-group col-12 col-lg-7 mb-3">
                <label class="col-sm-9 col-form-label"
                  >Selecciona el Producto</label
                >
                <div class="col-sm-9 d-flex align-items-center">
                  <select
                    v-model="solicitudCompleta.producto"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="Escalonado">Escalonado</option>
                    <!-- <option value="MIPYME">MIPYME</option>
                    <option value="Crédito Agrícola">Crédito Agrícola</option>
                    <option value="Cadenas Productivas">CADENAS PRODUCTIVAS</option>
                    <option value="Cajas Rurales">CAJAS RURALES , COOPERATIVAS O EMPRESAS DEL SECTOR SOCIAL DE LA ECONOMIA</option>
                    <option value="Credito Salud">CRÉDITO SALUD</option>
                    <option value="Credimur">CREDIMUR</option>
                    <option value="Prestamo Navideño">PRESTAMO NAVIDEÑO</option>
                    <option value="Credi Trabajo">CREDI TRABAJO</option> -->
                    <option value="Credi Trabajo 2">CREDI TRABAJO 2</option>
                    <!-- <option value="Credi Impulsa">CREDI IMPULSA</option> -->
                  </select>
                </div>
              </div>

              <div v-if="solicitudCompleta.producto != ``">
                <!-- <router-link v-if="solicitudCompleta.producto === `Escalonado` " class="btn btn-dark text-center ti-angle-right " to="/solicitud/grupo-solidario/0"
                >Siguiente</router-link> -->
                <router-link  class="btn btn-dark text-center ti-angle-right " to="/solicitud/integrante/0"
                  >Siguiente</router-link>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
// import TextInputForms from './forms/Text-Input-Forms.vue';
import { solicitudCompleta } from '../solicitud.js'

export default {
  name: "CrearSolicitud",
  data() {
    return {
      solicitudCompleta,
    };
  },
  components: {
    // TextInputForms,
  },
  methods: {},
};
</script>

<style scoped>
.btn {
  color: white;
}
.card {
  padding: 20px;
  border-radius: 12px;
  /* border-left-color: #4285f4; */
  border-top: 5px solid #ff910088;
  border-left: 5px solid #4286f467;
}

.form-control {
  border-radius: 10px;
  height: 70%;
}

.form-control:focus {
  border-color: #ff9100;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* Sombra */
}

.col-form-label {
  margin: 0px !important;
}

.form-group {
  margin-bottom: 0px;
}

.form-group {
  margin-block: 8px;
}

.col-form-label {
  padding-left: 10px;
  padding-right: 5px;
}

.card-description {
  margin-block: 15px;
}

.form-control {
  color: #4a4a4a !important;
}

.btnn {
  /* background-color: aqua; */
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.btnn p {
  margin-bottom: 0px;
  color: white;
}

.plan-inversión {
  /* width: 50%; */
  border-collapse: collapse;
  margin-bottom: 20px;
}

.plan-inversión,
th,
td {
  border: 1px solid black;
  text-align: center;
}

th,
td {
  padding: 10px;
  vertical-align: top;
  /* Alinea el contenido en la parte superior de las celdas */
}

.input-plan {
  width: 100%;
  box-sizing: border-box;
  border: none;
  /* Elimina los bordes de los inputs */
  outline: none;
  /* Elimina el contorno al hacer clic en los inputs */
}

.table-container {
  width: 70%;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  vertical-align: top;
}

.custom-input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.integrante {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  padding: 10px;

  -webkit-box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 12px 13px -11px rgba(0, 0, 0, 0.75);

  border-radius: 32px 124px 32px 124px;
  -moz-border-radius: 32px 124px 32px 124px;
  -webkit-border-radius: 32px 124px 32px 124px;
  border: 0px solid #000000;
}
</style>
