<template>

    <div class="container m-auto horizontal-scrollable">
        <div class="row">
            <h2 class="mt-3">Solicitudes Aceptadas</h2>
        </div>
        <br>
        <div class=" mb-1  d-flex align-items-md-end gap-5 justify-content-center">
            <div class="w-25">
                <label for="estadoSolicitud" class="flex-grow-1 ms-3">Mostrar por:</label>
                <select v-model="filtradoMostrar" required class=" form-select p-2 flex-grow-1" name="estadoSolicitud"
                    id="estadoSolicitud" placeholder="Todos">
                    <option value="Todos">Todos</option>
                    <option value="Aprobado">Aprobado</option>
                    <option value="Desembolsado">Desembolsado</option>
                </select>
            </div>
            <button @click="handleImprimir()" type="button" class="btn btn-success">
                Imprimir
            </button>
        </div>

        <div class="row text-center mt-3">
            <table class="table table-striped table-hover" id="myTable">
                <thead>
                    <tr>
                        <th class="text-center">ID</th>
                        <th class="text-center">Solicitante</th>
                        <th class="text-center">Monto</th>
                        <th class="text-center">Estado</th>
                        <th class="text-center">Acciones</th>

                    </tr>

                </thead>
                <tbody v-if="this.ready" id="tablaMostrar">
                    <tr v-for="(solicitud, index) in this.solicitudes" :key="solicitud.id">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ solicitud?.clienteNombres }}</td>
                        <td class="text-center">{{ solicitud?.monto }}</td>
                        <td v-if="solicitud?.estado === 'Pendiente'" class="text-center text-warning">{{
                            solicitud?.estado
                        }}
                        </td>
                        <td v-else-if="solicitud?.estado === 'Aprobado'" class="text-center text-success">{{
                            solicitud?.estado }}</td>
                        <td v-else-if="solicitud?.estado === 'Rechazado'" class="text-center text-danger">{{
                            solicitud?.estado }}</td>
                        <td v-if="solicitud?.estado === 'Desembolsado'" class="text-center text-dark">{{
                            solicitud?.estado
                        }}
                        </td>
                        <td class="text-center">
                            <a :href="`/solicitud-aceptada/${solicitud.id}`" class="btn btn-am btn-inverse-primary">Ver
                                más</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
import Security from '../security';
// import { DataTable } from "simple-datatables"
import DataTable from 'datatables.net-dt';
import ExcelJS from "exceljs";

export default {
    name: 'MostrarSolicitudes',
    data() {
        return {
            solicitudes: [],
            data: [],
            dateFormated: '',
            ready: false,
            filtradoMostrar: 'Todos'
        }
    },
    updated() {
        this.initializeDataTable()
    },
    beforeMount() {

        // const myTable = document.querySelector("#myTable");
        // const dataTable = new DataTable(myTable);
        // dataTable
        this.fetchData()
        // this.initializeDataTable
    },
    watch: {

        filtradoMostrar(val) {
            console.log('Filtrado', val);
            if (val === 'Todos') {
                this.solicitudes = this.data;
                console.log('Solicitudes', this.data[0]);
            } else if (val === 'Aprobado') {
                this.solicitudes = this.data.filter(solicitud => solicitud.estado === 'Aprobado');
                console.log('Solicitudes', this.data.filter(solicitud => solicitud.estado === 'Aprobado')[0]);
            } else if (val === 'Desembolsado') {
                this.solicitudes = this.data.filter(solicitud => solicitud.estado === 'Desembolsado');
                console.log('Solicitudes', this.data.filter(solicitud => solicitud.estado === 'Desembolsado')[0]);
            } else {
                this.solicitudes = this.data;
                console.log('Solicitudes', this.data[0]);
            }
            this.initializeDataTable()
        }
    },
    methods: {
        handleImprimir() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Data");
            worksheet.columns = [
                { header: "Nombre Beneficiario", key: "clienteNombres", width: 30 },
                { header: "ID", key: "clienteId", width: 20 },
                { header: "Monto", key: "monto", width: 10 },
                { header: "Cuenta Bancaria", key: "", width: 20 },
                { header: "Frecuencia Pago", key: "frecuenciaPago", width: 20 },
                { header: "Plazo", key: "plazoPago", width: 10 },
                { header: "Fecha de Caducidad", key: "", width: 20 },
                { header: "Estado", key: "estado", width: 20 },
            ];
            worksheet.getRow(1).font = { bold: true };
            worksheet.addRows(this.solicitudes);
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "solicitudes.xlsx");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        },
        fetchData() {
            // Realiza la solicitud Fetch para obtener los datos
            console.log('Inicio');
            const payload = {}
            fetch(process.env.VUE_APP_API_URL + "/admin/solicitudes/finanzas", Security.requestOptions(payload))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        this.$emit('error', response.message);
                        alert(response.message)
                    } else {
                        // console.log('Solicitudes', response.data.solicitudes)
                        this.data = response.data.solicitudes
                        this.solicitudes = response.data.solicitudes
                        this.ready = true;
                        // Llama a la función initializeDataTable() después de un retraso de 2 segundos (2000 milisegundos)
                        // setTimeout(this.initializeDataTable, 20000);      
                        this.filtradoMostrar = 'Aprobado'
                        //this.initializeDataTable()
                    }
                })
                .catch(error => {
                    this.$emit('error', error)
                })
        },
        initializeDataTable() {
            console.log('Despues Iniciar');
            const myTable = document.querySelector("#myTable");
            if (myTable) {
                new DataTable(myTable);
            }
        },
        mostrarSolicitante(id) {
            console.log(id);

        },
        formatearFecha(fechaOriginal) {
            // Función para formatear solo la fecha en el nuevo formato
            const fecha = new Date(fechaOriginal);
            const opciones = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return fecha.toLocaleDateString('es-ES', opciones);
        },
        dateFormat(param) {
            const date = new Date(param)

            const dia = (param)?.slice(8, 10)
            const mes = date.toLocaleDateString('es-ES', { month: 'long' })
            const anio = date.getFullYear()
            const dateFormated = `${dia}/${mes.slice(0, 3)}/${anio}`
            return dateFormated
        },

    }

}
</script>
<style scoped>
/* The heart of the matter */

.horizontal-scrollable>.row {
    overflow-x: auto;
    white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
    display: inline-block;
    float: none;
}

/* Decorations */

.col-xs-4 {
    color: white;
    font-size: 24px;
    padding-bottom: 20px;
    padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
    background: green;
}

.col-xs-4:nth-child(2n+2) {
    background: black;
}

.btn-am {
    height: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0px;
}
</style>