import { reactive } from 'vue'

export let solicitudCompleta = reactive({
    idGrupoSolidario: 0,
    producto: "",
    integrantes: [],
    dniIntegrantes: [],
    nombreGrupoSolidario: "",
    integranteSolicitud: 0,
    solicitudes: [],
    solicitudesObject: [],
})