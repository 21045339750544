<template>
    <div class="contenedor">
      <a @click.prevent="downloadPdf" href="#"><i class="fa-solid fa-file-pdf"></i></a>
      <img :src="imgPath + '/recibo/' + pago.slug + '.jpg'" alt="Recibo" ref="image" style="display: none;">
    </div>
  </template>
  
  <script>
  import jsPDF from 'jspdf';
  
  export default {
    props: {
      imgPath: {
        type: String,
        required: true
      },
      pago: {
        type: Object,
        required: true
      }
    },
    methods: {
      async downloadPdf() {
        const imageUrl = `${this.imgPath}/recibo/${this.pago.slug}.jpg`;
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          
          // Check if the blob is a PDF by reading the first few bytes
          const isPdf = await this.checkIfPdf(blob);
          
          if (isPdf) {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${this.pago.slug}.pdf`;
            link.click();
            URL.revokeObjectURL(url);
          } else {
            // Convert image to PDF
            const imgData = await this.getImageData(imageUrl);
            const pdf = new jsPDF();
            const img = new Image();
            img.src = imgData;
            img.onload = () => {
              const width = pdf.internal.pageSize.getWidth();
              const height = (img.height * width) / img.width; // Maintain aspect ratio
              pdf.addImage(img, 'JPEG', 0, 0, width, height);
              pdf.save(`${this.pago.slug}.pdf`);
            };
          }
        } catch (error) {
          console.error('Error processing the file', error);
        }
      },
      checkIfPdf(blob) {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const arr = new Uint8Array(reader.result).subarray(0, 4);
            let header = '';
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            // Check if the file header matches the PDF signature
            const isPdf = header === '25504446';
            resolve(isPdf);
          };
          reader.readAsArrayBuffer(blob);
        });
      },
      getImageData(url) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = 'Anonymous';
          img.src = url;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/jpeg'));
          };
          img.onerror = reject;
        });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales */
  /* .contenedor {
    text-align: center;
  } */
  a{
    text-decoration: none;
    color: black;
    font-size: 22px;
  }
  </style>
  