import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportacionPagares = (solicitud, fechaVencimiento, comisionDesembolso, comisionAsistenciaTecnica) => {
  let altura =48;
  const margenIzquierdo = 30; // Margen izquierdo personalizado
  const anchoParrafo = 150; // Ancho máximo del párrafo

    const diaVencimiento = fechaVencimiento.toString().slice(8,10)
    const añoVencimiento = fechaVencimiento.toString().slice(0,2)
    const mesVencimiento = fechaVencimiento.toString().slice(5,7)
    console.log(diaVencimiento, añoVencimiento, mesVencimiento, ' PROBANDO LA FECHA')

  
  if (solicitud.producto.toString().trim().toLowerCase() === "credi trabajo 2") {
    console.log("TRUE");

    // let interes = (solicitud.monto) * ((solicitud.interes/100)/12) * solicitud.plazoPago
    // const data = JSON.stringify(solicitud)
    const doc = new jsPDF("p", "");
    doc.lineHeightProportion = 2;

      const img = new Image();
    doc.addImage(
      (img.src = require("@/assets/header.png")),
      "png",
      0,
      0,
      doc.internal.pageSize.width,
      30
    );
    const footer = `Col. Rubén Darío, Casa 0592 Tegucigalpa, Honduras
  RTN:08269016867779
  Email: info@ahdecohn.org
  Telefono: 2242-5780
  `;

    

// Titulo Pagare
// let year = new Date().getFullYear().toString().slice(-2)
const widthParaph = 180;

doc.setFont("helvetica", "bold");
   // Titulos
   doc.setFontSize(14);

   const title = "PAGARÉ SIN PROTESTO";
   const titleWidth = doc.getTextWidth(title); // Obtener el ancho del texto del título
   const centerX = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular la posición X para centrar
   const yTitle = 30; // Posición Y del título

   doc.text(title, centerX, yTitle); // Dibujar el título

   // Calcular la longitud del subrayado
   const subrayadoLongitud = titleWidth; // Usar el ancho del título para la longitud del subrayado
   doc.line(centerX, yTitle + 2, centerX + subrayadoLongitud, yTitle + 2); // Dibujar el subrayado
   doc.setFontSize(11);
   const title2 = `POR L. ${formatoNumero(
     solicitud.monto
   )}`;
   const titleWidth2 = doc.getTextWidth(title2); // Obtener el ancho del texto del segundo título
   const centerX2 = (doc.internal.pageSize.width - titleWidth2) / 2; // Calcular la posición X para centrar
   const yTitle2 = 38; // Posición Y del segundo título

   doc.text(title2, centerX2, yTitle2); // Dibujar el segundo título

    //  **************************
    // Configuración de fuente y tamaño para el primer párrafo
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setLineHeightFactor(1.7);

    const parr2 = `Yo ${
      solicitud.cliente.nombres
    } ${
      solicitud.cliente.apellidos != undefined
        ? solicitud.cliente.apellidos
        : ""
    } mayor de edad, ${(solicitud.cliente.estadoCivil === 'soltero')?"soltero(a)":(solicitud.cliente.estadoCivil !=undefined)? solicitud.cliente.estadoCivil: "soltero(a)"}, de Profesión COMERCIANTE, hondureño(a), con Documento Nacional de Identificación (DNI) No. ${solicitud.cliente.dni} con Registro Tributario Nacional (RTN) No. ${solicitud.cliente.rtn} y con domicilio en ${solicitud.cliente.colonia} ${solicitud.cliente.puntoReferencia}, en la Ciudad de ________________, Municipio del ${solicitud.cliente.municipio} del Departamento de ${solicitud.cliente.departamento}, con número de teléfono ${(solicitud.cliente.celular)?solicitud.cliente.celular:((solicitud.cliente.telefono)?solicitud.cliente.telefono:"") } por medio de este documento, DECLARO que DEBO Y PAGARÉ INCONDICIONALMENTE y SIN PROTESTO exclusivamente en Lempiras o en moneda de curso legal en la República
de Honduras,  la cantidad de ${numeroALetras(solicitud.monto).toString()} exactos (L.${formatoNumero(solicitud.monto)}) a la ASOCIACION HONDUREÑA PARA EL DESARROLLO  INTEGRAL COMUNITARIO (AHDECO) , en las filiales, sucursales o agencias de la Institución Financiera que corresponda. En caso de comisión por Desembolso reconocerá ${numeroALetrasPorcentaje(comisionDesembolso)}(${comisionDesembolso}%). Brindar asistencia técnica y formación de competencias para la puesta en marcha o fortalecer un emprendimiento o negocio que será de los ${numeroALetrasPorcentaje(comisionAsistenciaTecnica)} (${comisionAsistenciaTecnica}%). Comisión por cobro de interés será de ${numeroALetrasPorcentaje(solicitud.interes / 12)} (${solicitud.interes / 12}%). En caso de mora que se origine por la falta de pago del capital o interés convenidos, pagaré un recargo del uno por ciento mensual (1%) sobre los saldos vencidos y cualquier cargo adicional asociado a la recuperación por la vía legal del crédito, no ocasionando prórroga del plazo y sin necesidad de requerimiento alguno. Para la ejecución y cumplimiento de la presente obligación, hago RENUNCIA DEL DOMICILIO, sometiéndome expresamente al de la ASOCIACION HONDUREÑA PARA EL DESARROLLO INTEGRAL COMUNITARIO (AHDECO), en el presente título valor se designa el día (___) del mes _______________ del año 20_____, como fecha de vencimiento del mismo; quedando incorporadas en este documento todas las disposiciones aplicables del Código de Comercio vigentes.    

En fe, de lo cual firmo el presente documento en la ciudad de ____________ a los __________ (_____) días del mes de ____________ del año 20_____.                           


(F)___________________
Nombre: 
Identidad:  
Dirección: 
Teléfono: 
Email:

    `;

    doc.text(parr2, margenIzquierdo, altura, {
      maxWidth: anchoParrafo,
      align: "justify",
    });
    // const widthParaph = 180;
    altura = espacioParrafos(doc, anchoParrafo, parr2, altura, 5);

    

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(footer, 108, 279, {
      maxWidth: widthParaph,
      align: "center",
      lineHeight: 2.5,
      fontWeight: "bold",
    });
    doc.setFontSize(12);

    
    doc.save(`PAGARE_${solicitud.producto}_${solicitud.cliente.nombres}.pdf`);
  } else {
    alert('Contrato No Disponible')
  }
};

function espacioParrafos(doc, anchoParrafo, parrafo, yparr, espacio) {
  // Obtener las dimensiones del texto
  let dim = doc.getTextDimensions(parrafo, {
    maxWidth: anchoParrafo,
  });

  let altura = dim.h + yparr + espacio;

  return altura;
}

function formatoNumero(numero) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numero);
}


const numeroALetras = (n) =>{
  //***** CONFIGURACIÓN ****
  // UDS - Pesos MX - Pesos colombianos etc. determina la etiqueta de la moneda
  const moneda = "LEMPIRAS"; 
  // puedes usar "con" o "y" Chiquipesos - centavos etc.
  const cents  = ["con", "Centavos"]; 
  // 1 = Todo minúsculas, 2 = Todo Mayúsculas, 3 = Title case moneda Mayúscula, 
  // 4 = Todo minúculas y moneda Mayúscula, 0 = Camel Case 
  const format = 2
  
  // Variables
  const temp = [moneda , 'Mil', 'Millones', 'Mil'];		
  const tem = Number.parseFloat(n).toFixed(2).split('.');
  const x = Number.parseInt(tem[0]);
  const y = Number.parseInt(tem[1]);
  let salidaEntero = [];
  let salidaCentavos = ''
  
  const centenas = (num) =>{
      const centena = Math.floor(num / 100);
      const decena = num - (Math.floor(centena * 100));				
      // console.log("centena:",centena,"decena", decena);
      switch(centena){
          case 0: return '' + decenas(decena);
          case 1: 
              if (decena === 0){
                  return 'Cien';					
              } else{
                  return 'Ciento ' + decenas(decena);
              }
          case 2: return 'Doscientos ' + decenas(decena);				
          case 3: return 'Trecientos ' + decenas(decena);				
          case 4: return 'Cuatrocientos ' + decenas(decena);					
          case 5: return 'Quinientos ' + decenas(decena);					
          case 6: return 'Seiscientos ' + decenas(decena);					
          case 7: return 'Setecientos ' + decenas(decena);					
          case 8: return 'Ochocientos ' + decenas(decena);					
          case 9: return 'Novecientos ' + decenas(decena);					
      }
  }

  const decenas = (num) =>{
      let decena = Math.floor(num /10);
      let unidad = num - (decena * 10);
      // console.log("decenas:",decena,"unidades:", unidad);
      switch(decena) {
          case 0: return '' + unidades(unidad);
          case 1: 
              switch(unidad){
                  case 0: return 'Diez' 
                  case 1: return 'Once' 
                  case 2: return 'Doce' 
                  case 3: return 'Trece' 
                  case 4: return 'Catorce' 
                  case 5: return 'Quince'
                  default: return "Dieci" + unidades(unidad);
              }
          case 2: 
              switch(unidad){
                  case 0: return 'Veinte';
                  default: return 'Veinti' + unidades(unidad);
              }
          case 3: return (unidad>0) ? `Treinta y ${unidades(unidad)}`:'Treinta';
          case 4: return (unidad>0) ? `Cuarenta y ${unidades(unidad)}`:'Cuarenta';
          case 5: return (unidad>0) ? `Cincuenta y ${unidades(unidad)}`:'Cincuenta';
          case 6: return (unidad>0) ? `Sesenta y ${unidades(unidad)}`:'Sesenta';
          case 7: return (unidad>0) ? `Setenta y ${unidades(unidad)}`:'Setenta';
          case 8: return (unidad>0) ? `Ochenta y ${unidades(unidad)}`:'Ochenta';
          case 9: return (unidad>0) ? `Noventa y ${unidades(unidad)}`:'Noventa';
      }

  }

  const unidades = (num) =>{
      // console.log("unidades:",num);
      switch(num) {
          case 0: return '';
          case 1: return 'Un';
          case 2: return 'Dos';
          case 3: return 'Tres';
          case 4: return 'Cuatro';
          case 5: return 'Cinco';
          case 6: return 'Seis';
          case 7: return 'Siete';
          case 8: return 'Ocho';
          case 9: return 'Nueve';
      }

  }

  const separadorMiles = (num) =>{
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = num.toString().split('.');
      arr[0] = arr[0].replace(exp,rep);
      // return arr[1] ? arr.join('.'): arr[0];			
      return arr[0];			
  }
  
  const procesadorEnteros = (num) =>{
      let separado = separadorMiles(num).split(',')				
      // console.log(separadorMiles(num));
      separado.reverse().forEach((el, index) =>{
          // console.log(el);
          // console.log(centenas(Number.parseInt(el)));
          // console.log(temp[index])
          if (el == 1 && temp[index] === "Millones"){
              salidaEntero.push("Millón")
          } else if (el == '000' && temp[index] === "Mil"){
              // no hace nada
          } else {
              salidaEntero.push(temp[index])
          } 				
          salidaEntero.push(centenas(Number.parseInt(el)))
      })
      // console.log(salidaEntero);
  }

  const procesadorDecimales = (num) =>{	
      const centavos = centenas(Number.parseInt(num));
      if (centavos.length > 0 ){
          // console.log("centavos:",centavos);
          salidaCentavos = ` ${cents[0]} ${centavos} ${cents[1]}`;
      }				
  }

  const salida = () =>{
      const stringSalida = (salidaEntero.reverse().join(' ') + salidaCentavos).replace(/\s+/g, ' ');
      switch(format){					
          case 1: return stringSalida.toLowerCase();
          case 2: return stringSalida.toUpperCase();
          case 3: return stringSalida.charAt(0).toUpperCase() + stringSalida.substring(1).toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          case 4: return stringSalida.toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          // case 4: return stringSalida.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
          default: return stringSalida;
      }
  }
  
  procesadorEnteros(x)
  procesadorDecimales(y)
  return salida()
}
const numeroALetrasPorcentaje = (n) =>{
  //***** CONFIGURACIÓN ****
  // UDS - Pesos MX - Pesos colombianos etc. determina la etiqueta de la moneda
  const moneda = ""; 
  // puedes usar "con" o "y" Chiquipesos - centavos etc.
  const cents  = ["punto", "por ciento"]; 
  // 1 = Todo minúsculas, 2 = Todo Mayúsculas, 3 = Title case moneda Mayúscula, 
  // 4 = Todo minúculas y moneda Mayúscula, 0 = Camel Case 
  const format = 1
  
  // Variables
  const temp = [moneda , 'Mil', 'Millones', 'Mil'];		
  const tem = Number.parseFloat(n).toFixed(2).split('.');
  const x = Number.parseInt(tem[0]);
  const y = Number.parseInt(tem[1]);
  let salidaEntero = [];
  let salidaCentavos = ''
  
  const centenas = (num) =>{
      const centena = Math.floor(num / 100);
      const decena = num - (Math.floor(centena * 100));				
      // console.log("centena:",centena,"decena", decena);
      switch(centena){
          case 0: return '' + decenas(decena);
          case 1: 
              if (decena === 0){
                  return 'Cien';					
              } else{
                  return 'Ciento ' + decenas(decena);
              }
          case 2: return 'Doscientos ' + decenas(decena);				
          case 3: return 'Trecientos ' + decenas(decena);				
          case 4: return 'Cuatrocientos ' + decenas(decena);					
          case 5: return 'Quinientos ' + decenas(decena);					
          case 6: return 'Seiscientos ' + decenas(decena);					
          case 7: return 'Setecientos ' + decenas(decena);					
          case 8: return 'Ochocientos ' + decenas(decena);					
          case 9: return 'Novecientos ' + decenas(decena);					
      }
  }

  const decenas = (num) =>{
      let decena = Math.floor(num /10);
      let unidad = num - (decena * 10);
      // console.log("decenas:",decena,"unidades:", unidad);
      switch(decena) {
          case 0: return '' + unidades(unidad);
          case 1: 
              switch(unidad){
                  case 0: return 'Diez' 
                  case 1: return 'Once' 
                  case 2: return 'Doce' 
                  case 3: return 'Trece' 
                  case 4: return 'Catorce' 
                  case 5: return 'Quince'
                  default: return "Dieci" + unidades(unidad);
              }
          case 2: 
              switch(unidad){
                  case 0: return 'Veinte';
                  default: return 'Veinti' + unidades(unidad);
              }
          case 3: return (unidad>0) ? `Treinta y ${unidades(unidad)}`:'Treinta';
          case 4: return (unidad>0) ? `Cuarenta y ${unidades(unidad)}`:'Cuarenta';
          case 5: return (unidad>0) ? `Cincuenta y ${unidades(unidad)}`:'Cincuenta';
          case 6: return (unidad>0) ? `Sesenta y ${unidades(unidad)}`:'Sesenta';
          case 7: return (unidad>0) ? `Setenta y ${unidades(unidad)}`:'Setenta';
          case 8: return (unidad>0) ? `Ochenta y ${unidades(unidad)}`:'Ochenta';
          case 9: return (unidad>0) ? `Noventa y ${unidades(unidad)}`:'Noventa';
      }

  }

  const unidades = (num) =>{
      // console.log("unidades:",num);
      switch(num) {
          case 0: return '';
          case 1: return 'Uno';
          case 2: return 'Dos';
          case 3: return 'Tres';
          case 4: return 'Cuatro';
          case 5: return 'Cinco';
          case 6: return 'Seis';
          case 7: return 'Siete';
          case 8: return 'Ocho';
          case 9: return 'Nueve';
      }

  }

  const separadorMiles = (num) =>{
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = num.toString().split('.');
      arr[0] = arr[0].replace(exp,rep);
      // return arr[1] ? arr.join('.'): arr[0];			
      return arr[0];			
  }
  
  const procesadorEnteros = (num) =>{
      let separado = separadorMiles(num).split(',')				
      // console.log(separadorMiles(num));
      separado.reverse().forEach((el, index) =>{
          // console.log(el);
          // console.log(centenas(Number.parseInt(el)));
          // console.log(temp[index])
          if (el == 1 && temp[index] === "Millones"){
              salidaEntero.push("Millón")
          } else if (el == '000' && temp[index] === "Mil"){
              // no hace nada
          } else {
              salidaEntero.push(temp[index])
          } 				
          salidaEntero.push(centenas(Number.parseInt(el)))
      })
      // console.log(salidaEntero);
  }

  const procesadorDecimales = (num) =>{	
      const centavos = centenas(Number.parseInt(num));
      if (centavos.length > 0 ){
          // console.log("centavos:",centavos);
          salidaCentavos = ` ${cents[0]} ${centavos} ${cents[1]}`;
      }				
  }

  const salida = () =>{
      const stringSalida = (salidaEntero.reverse().join(' ') + salidaCentavos).replace(/\s+/g, ' ');
      switch(format){					
          case 1: return stringSalida.toLowerCase();
          case 2: return stringSalida.toUpperCase();
          case 3: return stringSalida.charAt(0).toUpperCase() + stringSalida.substring(1).toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          case 4: return stringSalida.toLowerCase().replace(moneda.toLocaleLowerCase(),moneda.toUpperCase());
          // case 4: return stringSalida.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
          default: return stringSalida;
      }
  }
  
  procesadorEnteros(x)
  procesadorDecimales(y)
  return salida()
}

