<template>
  <div class="container mt-5 horizontal-scrollable">
        <div class="row">
            <h2>Grupos Solidarios</h2>
        </div>
        <div class="row text-center mt-3">
            <table class="table table-striped table-hover" id="myTable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Producto</th>
                        <th>Creado</th>
                        <th>Editar</th>

                    </tr>

                </thead>
                <tbody v-if="this.ready">
                    <tr v-for="(grupo) in this.grupos" :key="grupo.id">
                        <td>{{ grupo.id }}</td>
                        <td>{{ grupo.nombreGrupoSolidario }}</td>
                        <td>{{ grupo.producto }}</td>
                        <td>{{ grupo.created_at }}</td>
                        <td><a :href="`/solicitud/grupo-solidario/${grupo.id}`" class="btn btn-am btn-inverse-primary">Ver más</a></td>
                    </tr>
                </tbody>
                <!-- <tbody>
                    <tr>
                        <td>1</td>
                        <td>Abdres</td>
                        <td>molina</td>
                        <td>08012001001</td>
                        <td>dfsaf@</td>
                        <td>adsfsf</td>
                        <td>2</td>
                        <td>edit</td>
                    </tr>
                </tbody> -->
            </table>
        </div>
    </div>
</template>

<script>
import Security from '../security';
import { DataTable } from "simple-datatables"


export default {
    name: 'GruposSolidarios-App',
    data() {
        return {
            grupos: [],
            ready: false,
        }
    },
    updated() {
        this.initializeDataTable()
    },
    beforeMount() {

        // const myTable = document.querySelector("#myTable");
        // const dataTable = new DataTable(myTable);
        // dataTable
        this.fetchData()
        // this.initializeDataTable
    },
    methods: {
        fetchData() {
            // Realiza la solicitud Fetch para obtener los datos
            console.log('Inicio');
            const payload = {}
            fetch(process.env.VUE_APP_API_URL + "/admin/grupo-solidario/all", Security.requestOptions(payload))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        this.$emit('error', response.message);
                        alert(response.message)
                    } else {
                        console.log('Response', response.data.gruposSolidarios)
                        this.grupos = response.data.gruposSolidarios
                        this.grupos.created_at = this.formatearFecha(this.grupos.created_at)
                        this.ready = true;
                        // Llama a la función initializeDataTable() después de un retraso de 2 segundos (2000 milisegundos)
                        // setTimeout(this.initializeDataTable, 20000);                    
                    }
                })
                .catch(error => {
                    this.$emit('error', error)
                })
        },
        initializeDataTable() {
            console.log('Despues Iniciar');
            const myTable = document.querySelector("#myTable");
            if (myTable) {
                new DataTable(myTable);
            }
        },
        mostrarSolicitante(id){
            console.log(id);

        },
        formatearFecha(fechaOriginal) {
            // Función para formatear solo la fecha en el nuevo formato
            const fecha = new Date(fechaOriginal);
            const opciones = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return fecha.toLocaleDateString('es-ES', opciones);
        },
    },

}
</script>
<style scoped>
/* The heart of the matter */

.horizontal-scrollable>.row {
    overflow-x: auto;
    white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
    display: inline-block;
    float: none;
}

/* Decorations */

.col-xs-4 {
    color: white;
    font-size: 24px;
    padding-bottom: 20px;
    padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
    background: green;
}

.col-xs-4:nth-child(2n+2) {
    background: black;
}
.btn-am{
    height: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0px;
}

</style>