<template>
  <div class="geoContainer">
    <form @submit.prevent="handleSubmit">
      <div class="searchControl">
        <div>
          <label for="location">Buscar ubicación:</label>
          <div class="inputSearch">
            <input v-model="location" type="text" id="location" class="form-control" name="location"
              placeholder="Escribe una dirección">
            <button type="button" @click="searchLocation" class="btn btn-primary">Buscar</button>
          </div>
        </div>
        <div>
          <label for="googleLink">Buscar por enlace de Google</label>
          <div class="inputSearch">
            <input v-model="googleLocation" type="text" id="googleLink" class="form-control" name="googleLink"
              placeholder="Enlace de Google">
            <button type="button" @click="searchByGoogleMapsLink" class="btn btn-secondary">Buscar</button>
          </div>
        </div>
      </div>
      <label for="coordinates" hidden>Coordenadas:</label>
      <input v-model="coordinates" type="text" id="coordinates" name="coordinates" readonly hidden>
      <div class="map">
        <div ref="map" class="map-container" @click="handleMapClick"></div>
      </div>
      <div class="btnGeoControl">
        <button @click="redirectToGoogleMaps" type="button" class="btn btn-google">Enlace Google</button>
        <button type="submit" id="route" class="btn btn-twitter" @click="handleRouting">Mostrar Ruta</button>
        <button type="submit" class="btn btn-success" id="submit" @click="handleSubmit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-dialog';
import { geoMaps } from '../geoMaps';

export default {
  data() {
    return {
      geoMaps,
      location: '',
      googleLocation: '',
      lat: 0,
      lng: 0,
      imgPath: process.env.VUE_APP_IMAGE_URL,
      coordinates: '14.082760183389116, -87.228199839592',
      Currentcoordinates: '',
      map: null,
      marker: null,
      currentLocationMarker: null,
      savedLocationMarker: null,
      routingControl: null,
      googleMapsLink: '',
      starIcon: L.divIcon({
        className: 'custom-marker',
        html: '<img src="/src/assets/img/ahdecoLocation.png" class="currentLocIcon" alt="">',
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        color: '#8788ff',
      }),
    };
  },
  beforeMount() {
    this.coordinates = this.geoMaps.coordenates.length > 0 ? this.geoMaps.coordenates : '';
  },
  mounted() {
    this.coordinates = this.geoMaps.coordenates;

    // this.getCurrentLocation();
    this.getActualLocation();
    this.initMap();

    window.addEventListener('resize', () => {
      this.map.invalidateSize();
    });
    this.googleMapsLink = `https://maps.google.com/?q=${this.coordinates.trim()}`;
    // this.pruebas();
  },
  methods: {
    initMap() {
      if (this.map) {
        this.map.remove();
      }
      const customIcon = L.icon({
        iconUrl: `${this.imgPath}/ahdecoLocation.svg`,
        iconSize: [30, 40],
        iconAnchor: [15, 30],
      });

      // Configuración del mapa
      const coordenadas = this.coordinates === '' ? [this.lat, this.lng] : Object.values(this.obtenerCoordenadas(this.coordinates));

      this.map = L.map(this.$refs.map).setView(this.coordinates === '' ? [this.lat, this.lng] : Object.values(this.obtenerCoordenadas(this.coordinates)), 25);
      this.map.invalidateSize();

      // Capa de azulejos de OpenStreetMap
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contriutors',

      }).addTo(this.map);

      // Obtén las coordenadas
      //const Currentcoordinates = this.Currentcoordinates === '' ? [0, 0] : Object.values(this.obtenerCoordenadas(this.Currentcoordinates));
      // Crea el marcador
      this.marker = L.marker(coordenadas, { draggable: true }).addTo(this.map);

      this.currentLocationMarker = L.marker([this.lat, this.lng], { draggable: true, color: '#8788ff', fillColor: 'red', icon: customIcon }).addTo(this.map);

      // Configura el diálogo
      // Manejar el evento 'mouseover' del marcador
      this.marker.on('mouseover', () => {
        this.marker.bindPopup('Ubicacion del Cliente').openPopup();
      });

      // Manejar el evento 'mouseout' del marcador
      this.marker.on('mouseout', () => {
        this.marker.closePopup();
      });

      // Manejar el evento 'dragend' del marcador
      this.marker.on('dragend', event => {
        const { lat, lng } = event.target.getLatLng();
        this.coordinates = `${lat}, ${lng}`;
      });

      this.map.on('click', event => {
        const { lat, lng } = event.latlng;

        this.marker.setLatLng([lat, lng]).setOpacity(1);
        this.coordinates = `${lat}, ${lng}`;
        this.lat = lat;
        this.lng = lng;
        this.RoutingControl = null;
        this.routingControl !== null ? this.map?.removeControl(this.routingControl) : null;
      });
    },
    handleRouting() {
      if (this.coordinates !== '') {
        if (this.routingControl) {
          this.map?.removeControl(this.routingControl);
        }
        const waypoints = [
          L.latLng(this.currentLocationMarker.getLatLng()),
          L.latLng(this.marker.getLatLng()),
        ];
        this.routingControl = L.Routing.control({
          waypoints,

          routeWhileDragging: false,
          showAlternatives: true,
          lineOptions: {
            styles: [{ color: '#8788ff', weight: 5 }],
          },
          altLineOptions: {
            styles: [{ color: '#ff0000', weight: 5 }], // Cambia el color para las alternativas
          },
          createMarker: () => null,
        }).addTo(this.map);
        // Espera un momento hasta que se rendericen las instrucciones
        setTimeout(() => {
          // Oculta las instrucciones paso a paso
          const routeInstructions = document.querySelectorAll('.leaflet-routing-instructions');
          routeInstructions.forEach(instruction => {
            instruction.style.display = 'none';
          });
        }, 10000);
        // this.marker.setOpacity(0);
        // this.currentLocationMarker.setOpacity(0);
      }
    },
    obtenerCoordenadas(coordenadasString) {
      const [latitud, longitud] = coordenadasString?.split(',').map(parseFloat);

      if (isNaN(latitud) || isNaN(longitud)) {
        // Manejar el caso en el que las coordenadas no sean números válidos
        console.error('Las coordenadas no son válidas.');
        return null;
      }

      return { latitud, longitud };
    },

    async searchLocation() {
      if (this.location) {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${encodeURIComponent(this.location)}&countrycodes=HN`
          );
          const data = await response.json();

          if (data && data.length > 0) {
            const result = data[0];
            const lat = parseFloat(result.lat);
            const lon = parseFloat(result.lon);

            // Centrar el mapa en la ubicación encontrada
            this.map.setView([lat, lon], 15);

            // Mover el marcador a la ubicación encontrada
            this.marker.setLatLng([lat, lon]);

            // Actualizar las coordenadas en el input
            this.coordinates = `${lat}, ${lon}`;
            this.lat = lat;
            this.lng = lon;
          } else {
            alert('No se encontró la ubicación en Honduras.');
          }
        } catch (error) {
          console.error('Error al realizar la búsqueda de ubicación:', error);
          alert('Error al buscar la ubicación. Por favor, inténtalo de nuevo.');
        }
      } else {
        alert('Ingresa una dirección para buscar.');
      }
    },

    searchByGoogleMapsLink() {
      if (this.googleLocation) {
        const regex = /@(-?\d+\.?\d*),(-?\d+\.?\d*),\d+\.?\d*z/;
        const match = this.googleLocation.match(/q=([-+]?\d+\.\d+),([-+]?\d+\.\d+)/) || this.googleLocation.match(regex);

        if (match) {
          const lat = parseFloat(match[1]);
          const lon = parseFloat(match[2]);

          // Centrar el mapa en la ubicación encontrada
          this.map.setView([lat, lon], 15);

          // Mover el marcador a la ubicación encontrada
          this.marker.setLatLng([lat, lon]);

          // Actualizar las coordenadas en el input
          this.coordinates = `${lat}, ${lon}`;
          this.lat = lat;
          this.lng = lon;
        } else {
          alert('El enlace no es válido.');
        }
      } else {
        alert('Ingresa un enlace de Google Maps para buscar.');
      }
    },

    getActualLocation() {
      const customIcon = L.icon({
        iconUrl: `${this.imgPath}/ahdecoLocation.svg`,
        iconSize: [30, 40],
        iconAnchor: [15, 30],
      });
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          const { latitude, longitude } = position.coords;
          this.map.setView([latitude, longitude], 15);
          this.currentLocationMarker.setLatLng([latitude, longitude]);
          this.currentLocationMarker.setIcon(customIcon);
          // this.coordinates = `${latitude}, ${longitude}`;
        });
      } else {
        alert('Tu navegador no soporta la geolocalización.');
      }
    },



    handleSubmit(e) {
      if (e.target.id === 'submit') {
        const modal = document.querySelector(".ownModal");
        modal.classList.remove("ownModalActive");

        this.getActualLocation();
        this.geoMaps.coordenates = this.coordinates;
      }
      // Aquí puedes enviar las coordenadas al servidor y almacenarlas en la base de datos
    },

    redirectToGoogleMaps() {
      window.open(this.googleMapsLink, '_blank');
    },
  },
  watch: {
    coordinates() {
      this.googleMapsLink = `https://maps.google.com/?q=${this
        .lat},${this.lng}`;
    },
    'geoMaps.coordenates': function () {
      this.coordinates = this.geoMaps.coordenates ? this.geoMaps.coordenates : '';
      this.initMap();
      this.getActualLocation()
      this.handleRouting();
    },
  },

};
</script>

<style scoped>
.form-control {
  max-width: 200px;
  height: 35px;
}
</style>

<style>
.geoContainer {
  width: 100%;
  height: 100%;

  >form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
  }
}

.leaflet-routing-alternatives-container {
  display: none;

}

.currentLocIcon {
  color: #d2a912;
  font-size: 1.8rem;

}

.map-form {
  max-width: 600px;
  margin: 0 auto;
}

.search-container {
  display: flex;
}

.search-container input {
  flex: 1;
  margin-right: 10px;
}

.map {
  flex: 5;
  width: 100%;
  height: 100%;

  >.map-container {
    height: 100%;
    animation: widthMac 0.2ms ease-in-out;
  }
}

@keyframes widthMac {
  0% {
    top: 0;
    left: 0;
  }

  100% {
    top: 100px;
    left: 100px;
  }

}

.btnGeoControl {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  gap: 20px
}

.searchControl div {
  flex: 1;
}

.searchControl {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  >div {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    > .inputSearch {
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1px;
      > input {
        margin: 0;
        height: 35px;
      }
      >button {
        width: 30%;
        padding: 5px;
        height: 35px;
        margin: 0;
        
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .searchControl {
    width: 100%;
    > div {

      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1px;
      flex-direction: column;

      > .inputSearch {
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1px;
        > input {
          margin: 0;
          height: 30px;
        }
        >button {
          width: 30%;
          padding: 5px;
          height: 30px;
          margin: 0;
          
        }
      }
    }}
  
}

@media screen and (max-width: 768px) {
  form {
    gap: 5px;
  }
  .btnGeoControl {
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }

  .searchControl div {
    flex: 1;
  }

  .searchControl {
    font-size: 0.8rem;
    flex-direction: column;
    gap: 5px;
    >div {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1px;
      flex-direction: column;

      > .inputSearch {
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1px;
        > input {
          margin: 0;
          height: 30px;
        }
        >button {
          width: 30%;
          padding: 5px;
          height: 30px;
          margin: 0;
          
        }
      }
    }
  }

  .btnGeoControl {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5px;
    > button {
      width: 130px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
    }
  }

}

button {
  margin-top: 10px;
}
</style>