<template>
    <form @submit.prevent="submit" :ref="name" :event="event" autocomplete="off" :method="method" :action="action"
        class="needs-validation" novalidate>
        <slot></slot>
    </form>
</template>

<script>
export default {
    name: 'FormTag',
    props: ["method", "action", "name", "event"],
    data() {
        return {
            validar: true,
        }
    },
    methods: {
        submit() {
            let myForm = this.$refs[this.$props.name];
            if (this.name === 'cliente') {
                this.comprobarAcordion('validar-1', ['nombres', 'apellidos', 'dni','rtn', 'lugar-nacimiento', 'sexo', 'estado-civil', 'fecha-nacimiento', 'email', 'celular', 'nombre-banco', 'no-cuenta-banco'])
                this.comprobarAcordion('validar-2', ['departamento', 'municipio', 'colonia', 'calle', 'avenida', 'bloque', 'no-casa', 'punto-referencia', 'tipoVivienda', 'punto-referencia', 'refStatus'])
                this.comprobarAcordion('validar-3', ['destino', 'tipo-cliente', 'actividad-economica', 'tiempo-negocio', 'tipoLocal', 'ingresoBrutoNegocio', 'cuotaAlquiler', 'telefono', 'colonia-negocio', 'calle-negocio', 'avenida-negocio', 'bloque-negocio', 'no-casa-negocio'])
            }

            if (myForm.checkValidity()) {
                console.log("My event name", this.$props['event']);
                
                console.log("Name", this.$props.name);
                this.$emit(this.$props['event']);
                this.borrar('validar-1')
                this.borrar('validar-2')
                this.borrar('validar-3')
            }
            myForm.classList.add("was-validated");

        },
        comprobarAcordion(val, fields) {
            let myForm = this.$refs[this.$props.name];
            let acord = document.getElementById(val)
            this.validar = true

            fields.forEach(element => {

                if (!myForm.elements.namedItem(element)?.checkValidity()) {
                    this.validar = false
                }
                if (myForm.elements.namedItem(element)?.name === 'refStatus' && myForm.elements.namedItem(element)?.value === '') {
                    document.getElementById("refStatus").classList.add("is-invalidRef");
                    this.validar = false
                }
            });

            if (!this.validar) {
                // <p style="color:red; margin-bottom:0px; margin-top:3.5px; margin-right:5px">Faltan Campos por llenar</p>
                acord.innerHTML = `  
                <img
                    src = "https://cdn.pixabay.com/photo/2021/06/24/17/57/cancel-sign-6361985_1280.png"
                    class="bounce"
                    alt="check"
                    style="width: 30px"
                />

                `
            } else {
                acord.innerHTML = `
                <img
                    src = "https://upload.wikimedia.org/wikipedia/commons/c/c6/Sign-check-icon.png"
                    class="bounce"
                    alt="check"
                    style="width: 30px"
                />

`
            }

        },
        borrar(val) {
            const acord = document.getElementById(val);
            acord.innerHTML = ``
        }

    },
}
</script>