// store.js
import { reactive } from 'vue'

export const store = reactive({
  token: "",
  user: {},
  roles: [],
  sucursal: [],
  ready: false,
})

