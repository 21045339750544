<template>
    <div class="modal-body">
        <div class="">
            <h5>Monto Aprobado: {{ this.monto }}</h5>
            <label for="estadoSolicitud">Monto a desembolsar: </label>
            <input v-model="this.montoDesembolso" type="number" required class="form-control" name="montoDesembolso" id="montoDesembolso">
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" @click="handleSubmit()">Desembolsar</button>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Security from '../security'

export default {
    name: 'EstadoSolicitud',
    props: {
        idSolicitud: String,
        monto: Float32Array
    },
    data() {
        return {
            estadoSolicitud: "Desembolsado",
            montoDesembolso: 0
        }
    },
    methods: {
        handleSubmit() {
            if ( this.idSolicitud !== '' && this.montoDesembolso !== '') {

                const payload = {
                    idSolicitud: parseInt(this.idSolicitud),
                    estado: this.estadoSolicitud,
                    montoDesembolsado: this.montoDesembolso,
                    idUsuario: Security.getUser()[0].user.id,

                }

                console.log(payload)
                Swal.fire({
                    title: '¿Estás Seguro que desea desembolsar?',
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('confirmado')
                      
                        fetch(
                        process.env.VUE_APP_API_URL +
                        "/admin/credito/save" ,
                        Security.requestOptions(payload)
                        )
                        .then((response) => response.json())
                        .then((data) => {
                        if (data.error) {
                            Swal.fire({
                                        // position: 'top-end',
                                        icon: "error",
                                        title: data.message,
                                        showConfirmButton: false,
                                        timer: 1500,
                            });
                        } else {
                           console.log(data.data);
                        }
                        });

                        location.reload()
                    }
                })
            } else{
                console.log('no se cumple')
            }
        }
    }
}
</script>

<style scoped></style>